import React, { useState } from 'react';
import { RootState } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Col, Input, Row,
} from 'reactstrap';
import { faMagnifyingGlass, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  getClientUserListReq,
} from '../../../../../store/campusXcel/actions';
import { successAlert, errorAlert } from '../../../../../utils/alert';
import {
  APIHeader, UserType,
} from '../../../../../utils/constants';
import { getTokenFromLocalStorage } from '../../../../../utils/service/localstorage-service';
import { TabProps } from '../onboard.type';
import { EvalKey } from '../../../../../services/sadmin/list-client/list-client.types';
import { AllCampusUserList } from '../campus/all-campus-user';
import { AllClientUserResult, ListClientUserResultData } from '../../../../../services/sadmin/list-client-user/list-client-user.types';

export const ListClientUser: React.FC<TabProps> = ({ ordTp, tabTp, clientTp }) => {
  const dispatch = useDispatch();
  const tokenData = getTokenFromLocalStorage();
  // const [loaderClient, setLoaderClient] = useState(false);
  const [loadingMore, setLoadingMore] = React.useState(false);
  const [showSuccessAlert, setSuccessShowAlert] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [searchText, setSearchText] = React.useState('');
  const [evalKeyText, setEvalKeyText] = React.useState('');
  const [evalTpText, setEvalTpText] = React.useState('');
  const [isSearchQuery, setIsSearchQuery] = React.useState('');
  const [recordsPerPage] = React.useState<number>(10);
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [totalRecords, setTotalRecords] = React.useState<number>(0);
  const [pagedData, setPagedData] = React.useState<Record<number, any[]>>({});
  const clientListUserResponse = useSelector((state: RootState) => state.campusXcel.getClientUserList);
  const [, setDeleteRecord] = React.useState<EvalKey>({
    ClntTp: '',
    ClntId: '',
  });
  const [, setEditUserRecord] = React.useState<ListClientUserResultData>({
    UserTp: '',
    UserCd: '',
    fNme: '',
    lNme: '',
    dlCd: '',
    ph: '',
    isAdm: false,
    eId: '',
    isAcs: false,
    lgnSts: '',
    gnd: '',
    cCd: '',
  });
  const [, setAllCientUserData] = React.useState<AllClientUserResult>({
    tCnt: 0,
    fCnt: 0,
    usrData: [],
    lastEvalKey: {
      UserTp: '',
      UserCd: '',
    },
  });
  React.useEffect(() => {
    if (ordTp === 'campus' && tabTp === 'listCandidates' && clientTp === 'CMPS') {
      setLoadingMore(true);
      dispatch(getClientUserListReq({
        requestType: APIHeader.REQ_GET_CLIENT_USER,
        uTp: UserType.CXADM,
        limit: recordsPerPage, // ApiLIMIT.LIMIT,
        evalKey: '',
        evalTp: '',
        isFilter: 'NO',
        isSearch: 'NO',
        token: tokenData,
        clntTp: clientTp,
        isMeta: false,
        clntSts: 'ACTIVE',
      }));
    }
  }, [ordTp, tabTp, clientTp]);

  const fetchMoreData = () => {
    // setLoaderClient(true);
    setLoadingMore(true);
    if (isSearchQuery !== '') {
      dispatch(getClientUserListReq({
        requestType: APIHeader.REQ_GET_CLIENT_USER,
        uTp: UserType.CXADM,
        limit: recordsPerPage,
        evalKey: evalKeyText,
        evalTp: evalTpText,
        isFilter: 'NO',
        searchBy: searchText,
        isSearch: 'YES',
        token: tokenData,
        clntTp: clientTp,
        isMeta: false,
        clntSts: 'ACTIVE',
      }));
    } else {
      setLoadingMore(true);
      dispatch(
        getClientUserListReq({
          requestType: APIHeader.REQ_GET_CLIENT_USER,
          uTp: UserType.CXADM,
          limit: recordsPerPage, // ApiLIMIT.LIMIT,
          evalKey: evalKeyText,
          evalTp: evalTpText,
          isFilter: 'NO',
          isSearch: 'NO',
          token: tokenData,
          clntTp: clientTp,
          isMeta: false,
          clntSts: 'ACTIVE',
        }),
      );
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    if (!pagedData[page]) {
      fetchMoreData();
    }
  };

  const handleSearchChange = (e:any) => {
    setSearchText(e.target.value);
  };
  const handleRefreshClient = () => {
    setCurrentPage(0);
    setTotalRecords(0);
    setPagedData([]);
    setLoadingMore(true);
    dispatch(getClientUserListReq({
      requestType: APIHeader.REQ_GET_CLIENT_USER,
      uTp: UserType.CXADM,
      limit: recordsPerPage,
      evalKey: '',
      evalTp: '',
      isFilter: 'NO',
      isSearch: 'NO',
      token: tokenData,
      clntTp: clientTp,
      isMeta: false,
      clntSts: 'ACTIVE',
    }));
  };

  const handleSearchProjects = () => {
    // setLoaderClient(true);
    setCurrentPage(0);
    setTotalRecords(0);
    setPagedData([]);
    setLoadingMore(true);
    setIsSearchQuery(searchText);
    dispatch(getClientUserListReq({
      requestType: APIHeader.REQ_GET_CLIENT_USER,
      uTp: UserType.CXADM,
      limit: recordsPerPage,
      evalKey: '',
      evalTp: '',
      isFilter: 'NO',
      searchBy: searchText,
      isSearch: 'YES',
      token: tokenData,
      clntTp: clientTp,
      isMeta: false,
      clntSts: 'ACTIVE',
    }));
  };

  React.useEffect(() => {
    if (clientListUserResponse.isError && clientListUserResponse.message !== '') {
      // setLoaderClient(false);
      setLoadingMore(false);
      setAlertMsg(clientListUserResponse.message);
      setErrorShowAlert(true);
    }
  }, [clientListUserResponse.isError, clientListUserResponse.message]);

  React.useEffect(() => {
    if (!clientListUserResponse.isError && clientListUserResponse.message === 'executed') {
      // setLoaderClient(false);
      setLoadingMore(false);

      if (searchText !== '') {
        setPagedData({ [currentPage]: clientListUserResponse.data.usrData });
      } else if (
        clientListUserResponse.data
        && Object.keys(clientListUserResponse.data).length > 0
        && Array.isArray(clientListUserResponse.data.usrData)
      ) {
        const newPageData = clientListUserResponse.data.usrData;
        setPagedData((prev) => ({
          ...prev,
          [currentPage]: newPageData,
        }));
        setAllCientUserData({
          tCnt: clientListUserResponse.data.tCnt,
          fCnt: clientListUserResponse.data.fCnt,
          usrData: clientListUserResponse.data.usrData,
          lastEvalKey: clientListUserResponse.data.lastEvalKey,
        });
        if (clientListUserResponse.data.lastEvalKey !== null) {
          setEvalKeyText(clientListUserResponse.data.lastEvalKey.UserCd);
          setEvalTpText(clientListUserResponse.data.lastEvalKey.UserTp);
        }
        setTotalRecords(clientListUserResponse.data.tCnt);
      }
    }
  }, [clientListUserResponse]);

  return (
    <div>
      {/* <div className="mb-3 align-center">
        List of {clientTp === 'CMPS' ? 'Campus' : null}
      </div> */}
      <Row className="align-start">
        {/* { loaderClient ? <LoaderData /> : null} */}
        {showSuccessAlert ? (
          successAlert(false, alertMsg, showSuccessAlert, setSuccessShowAlert)
        ) : null}
        {showErrorsAlert ? (
          errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
        ) : null}
        <Col lg="12" xs="12">
          {loadingMore ? (
            <div className="loader-container-none align-center pt-5">
              <img src="/images/spinner.png" alt="Loading" className="spinner-img loader-image-size" />
              <span className="mx-2 text-lite">Data is loading... <br />Please wait....</span>
            </div>
          ) : (
            <Row className="align-start mx-1">
              <Col xs="12" lg="5">
                <div className="align-end">
                  <div className="input-wrapper">
                    <Input
                      type="text"
                      placeholder="Search..."
                      value={searchText}
                      onChange={handleSearchChange}
                    />
                    <Button type="button" onClick={handleSearchProjects}>
                      <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </Button>
                  </div>
                  <Button className="onboard-refresh-button" onClick={handleRefreshClient}>
                    <FontAwesomeIcon icon={faRefresh} />
                  </Button>
                </div>
              </Col>
              <Col xs="12">
                <div className="pt-1">
                  {clientTp === 'CMPS' && pagedData[currentPage]
                    ? (
                      <AllCampusUserList
                        pagedData={pagedData[currentPage]}
                        currentPage={currentPage}
                        handlePageChange={handlePageChange}
                        recordsPerPage={recordsPerPage}
                        totalRecords={totalRecords}
                        setEditRecord={setEditUserRecord}
                        setDeleteRecord={setDeleteRecord}
                      />
                    ) : null}
                </div>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ListClientUser;
