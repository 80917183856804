import React from 'react';
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import './ats.scss';
import Offcanvas from '../../../utils/OffCanvas/OffcanvasComponent';
import { ContactForm } from '../contact-form/contact-form';

export const SalesAts: React.FC = () => {
  const [contactDetailsSales, setContactDetailsSales] = React.useState(false);
  const [contactUTp, setContactUTp] = React.useState('');
  const [showLogin, setShowLogin] = React.useState(false);
  const handleShowLogin = () => setShowLogin((prevState) => !prevState);
  const toggleContact = (uTp:string) => {
    setContactUTp(uTp);
    setContactDetailsSales(!contactDetailsSales);
  };
  return (
    <div>
      <ButtonDropdown
        isOpen={showLogin}
        toggle={() => handleShowLogin()}
        // className="card-iphone-button"
      >
        <DropdownToggle caret className="card-iphone-button">
          TALK TO SALES
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => toggleContact('RCRT')} className="my-2">
            I am Recruiter
          </DropdownItem>
          <DropdownItem onClick={() => toggleContact('CORP')} className="my-2">
            We are Corporate
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
      <Offcanvas
        isOpen={contactDetailsSales}
        toggle={() => toggleContact('')}
      >
        <ContactForm userFrom={contactUTp} />
      </Offcanvas>
    </div>
  );
};
export default SalesAts;
