import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {
  Row, Col, Input, Button, InputGroup, NavLink, Form,
  CardImg,
  Label,
  Modal,
  ModalBody,
} from 'reactstrap';
import './register.scss';
import { RootState } from 'typesafe-actions';
import React, { useState } from 'react';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  clearRegisterXCLNPostReq, registerXCLNReq,
} from '../../../../store/campusXcel/actions';
import { APIHeader, UserType } from '../../../../utils/constants';
import { errorAlert, successAlert } from '../../../../utils/alert';
import { LoaderData } from '../../../../utils/loader';
import { RegisterXCLNInput } from '../../../../services/staffing/xcelian/register-xcelian/register.types';
import { DialCountrInfo } from '../../../../utils/MetaData/country';

export const RegisterXcelian: React.FC = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const [showSuccessAlert, setSuccessShowAlert] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [invalidField, setInvalidField] = useState(false);
  const [registerLoader, setRegisterLoader] = useState(false);
  const [isValidEmail, setIsValidEmail] = React.useState(false);
  const [isValidPhone, setIsValidPhone] = React.useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const registerResponse = useSelector((state: RootState) => state.campusXcel.registerXcelian);
  const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
  const phoneRegex = /^\d{10}$/;
  const [registerXCLNData, setRegisterXCLNData] = useState<RegisterXCLNInput>({
    fNme: '',
    lNme: '',
    dlCd: '+91',
    gnd: 'UNK',
    ph: '',
    phTp: 'SMSPHN',
    eId: '',
    isAgreeTrm: false,
    whchXcl: [],
  });

  const handleTermUse = () => {
    setModalConfirm(!modalConfirm);
  };

  React.useEffect(() => {
    if (registerResponse.error && registerResponse.message !== '') {
      dispatch(clearRegisterXCLNPostReq());
      setRegisterLoader(false);
      setErrorShowAlert(true);
      setAlertMsg(registerResponse.message);
    }
  }, [registerResponse.message, registerResponse.error]);

  React.useEffect(() => {
    if (!registerResponse.error && registerResponse.data !== '') {
      dispatch(clearRegisterXCLNPostReq());
      setRegisterLoader(false);
      setInvalidField(false);
      setIsValidEmail(false);
      setIsValidPhone(false);
      setRegisterXCLNData({
        fNme: '',
        lNme: '',
        dlCd: '+91',
        gnd: 'UNK',
        ph: '',
        phTp: 'SMSPHN',
        eId: '',
        isAgreeTrm: false,
        whchXcl: [],
      });
      if (registerResponse.data === 'Mail sent successfully') {
        setAlertMsg('Registration has been successfully completed. Kindly review your email for additional information.');
      }
      setSuccessShowAlert(true);
    }
  }, [registerResponse.data, registerResponse.error]);

  const loginPage = () => {
    history('/xcelian/login');
    window.scrollTo(0, 0);
  };

  const dialNameoptions = DialCountrInfo.map((state) => ({
    label: `${state.dlVal}(${state.cntCd})`,
    value: state.dlVal,
  }));
  const selectedDialNameOptions = dialNameoptions.find(
    (option) => option.value === registerXCLNData.dlCd,
  );
  const handleSelectDialData = (e:any) => {
    setRegisterXCLNData({ ...registerXCLNData, dlCd: e.value });
  };

  const handleCheckChange = (e: any) => {
    if (e.target.checked === true) {
      setRegisterXCLNData({ ...registerXCLNData, phTp: 'SMRTPHN' });
    } else {
      setRegisterXCLNData({ ...registerXCLNData, phTp: 'SMSPHN' });
    }
  };
  const handleTermChange = (e: any) => {
    setRegisterXCLNData({ ...registerXCLNData, isAgreeTrm: e.target.checked });
  };

  const handleGenderRegister = (gender_data: string) => {
    if (gender_data === 'M') {
      setRegisterXCLNData({ ...registerXCLNData, gnd: 'M' });
      // setActiveTab('M');
    } else if (gender_data === 'F') {
      setRegisterXCLNData({ ...registerXCLNData, gnd: 'F' });
      // setActiveTab('F');
    } else if (gender_data === 'T') {
      setRegisterXCLNData({ ...registerXCLNData, gnd: 'T' });
      // setActiveTab('T');
    }
  };
  const handleFieldChangeRegister = (e:any) => {
    const fieldName = e.target.name;
    setRegisterXCLNData({ ...registerXCLNData, [fieldName]: e.target.value });
  };

  const handleSubmit = (e:any) => {
    e.preventDefault();
    const validEmail = emailRegex.test(registerXCLNData.eId);
    const validPhone = phoneRegex.test(registerXCLNData.ph);
    if (validEmail && validPhone) {
      setRegisterLoader(true);
      dispatch(registerXCLNReq({
        inputBody: registerXCLNData,
        requestType: APIHeader.REQ_ADD_XCLN,
        uTp: UserType.PUBLIC,
        token: '',
      }));
    } else if (!validEmail) {
      setIsValidEmail(true);
    } else if (!validPhone) {
      setIsValidPhone(true);
    }
  };

  return (
    <div className="xcelian-register-bg">
      { registerLoader ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(true, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {invalidField ? (
        errorAlert(false, 'All Fields Are Mandatory !!!', invalidField, setInvalidField)
      ) : null}
      {isValidEmail ? (
        errorAlert(false, 'Please enter valid Email Id !!!', isValidEmail, setIsValidEmail)
      ) : null}
      {isValidPhone ? (
        errorAlert(false, 'Please enter valid Phone Number !!!', isValidPhone, setIsValidPhone)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <NavLink href="/jobs" to="/jobs" className="nav-logo">
        <CardImg
          style={{
            height: '45px', width: 'auto',
          }}
          src="/campusXcel.png"
          alt="campusXcelLogo"
        />
      </NavLink>
      <div className="my-1">
        <div className="align-center mx-3">
          <span className="form-header-text">
            Register as Xcelian
          </span>
        </div>
        <Row className="align-center margin-mob-top pt-3">
          <Col lg={{ size: 5, order: 1 }} xs={{ size: 11, order: 3 }}>
            <div className="mx-4">
              <CardImg
                src="/images/xcelian/xcelian-register.png"
                alt="menu-logo"
                className="mx-1"
              />
            </div>
          </Col>
          <Col lg={{ size: 1, order: 2 }} xs={{ size: 11, order: 2 }} />
          <Col lg={{ size: 4, order: 3 }} xs={{ size: 11, order: 1 }}>
            <Form onSubmit={handleSubmit}>
              <div className="pt-2 register-card">
                <Row className="align-center">
                  <Col lg="6" className="pt-4">
                    <Input
                      placeholder="First Name"
                      type="text"
                      required
                      name="fNme"
                      value={registerXCLNData.fNme}
                      disabled={registerLoader}
                      onChange={handleFieldChangeRegister}
                      className="campus-input"
                    />
                  </Col>
                  <Col lg="5" className="pt-4">
                    <Input
                      placeholder="Last Name"
                      type="text"
                      required
                      name="lNme"
                      value={registerXCLNData.lNme}
                      disabled={registerLoader}
                      onChange={handleFieldChangeRegister}
                      className="campus-input"
                    />
                  </Col>
                  <Col lg="11" className="pt-4">
                    <Input
                      placeholder="Email Id"
                      type="text"
                      required
                      name="eId"
                      value={registerXCLNData.eId}
                      disabled={registerLoader}
                      onChange={handleFieldChangeRegister}
                      className="campus-input"
                    />
                    <span className="text-lite-grey ml-1 pt-1">
                      Email for all communications
                    </span>
                  </Col>
                  <Col lg="11" className="pt-4">
                    <InputGroup>
                      <Select
                        value={registerXCLNData.dlCd === '' ? null : selectedDialNameOptions}
                        defaultInputValue="+91"
                        options={dialNameoptions}
                        onChange={handleSelectDialData}
                        styles={{
                          control: (base: any) => ({
                            ...base,
                            '&:hover': { borderColor: '#8B0000' },
                            border: '1px solid #DDE0E4',
                            minHeight: '50px',
                            borderRadius: '50px',
                            padding: 1,
                            fontSize: '15px',
                            fontWeight: 400,
                            color: '#575656',
                            boxShadow: 'none',
                            backgroundColor: '#fff',
                            '&:focus': {
                              borderColor: '#E1EDF8',
                              boxShadow: 'none',
                              color: '#575656',
                              background: '#fff',
                            },
                          }),
                          option: (provided: any, state: { isSelected: any; }) => ({
                            ...provided,
                            color: state.isSelected ? '#383838' : '#212121',
                            padding: 15,
                            textAlign: 'left',
                            // border: '1px solid #F9CB9C',
                            background: state.isSelected ? '#FAFCFB' : '#fff',
                            '&:hover': {
                              background: '#FAFCFB',
                            },
                          }),
                        }}
                        noOptionsMessage={() => 'No Dial Code Found'}
                      />
                      <Input
                        placeholder="Phone Number"
                        type="number"
                        required
                        name="ph"
                        value={registerXCLNData.ph}
                        disabled={registerLoader}
                        onChange={handleFieldChangeRegister}
                        className="campus-input mx-1"
                      />
                    </InputGroup>
                    <span className="text-lite-grey pt-1 ml-1">If opting for<span className="text-whatsapp mx-1">WhatsApp</span>services please provide same number</span>
                  </Col>
                  <Col lg="11" className="pt-4">
                    <Row className="bg-gender-register">
                      <Col lg="4" xs="4">
                        <Input
                          type="radio"
                          name="gender"
                          className="campus-radio-box cursor-pointer"
                          onClick={() => handleGenderRegister('M')}
                        />
                        <Label check className="form-check-text mx-1">Male</Label>
                      </Col>
                      <Col lg="4" xs="4">
                        <Input
                          type="radio"
                          name="gender"
                          className="campus-radio-box cursor-pointer"
                          onClick={() => handleGenderRegister('F')}
                        />
                        <Label check className="form-check-text mx-1">Female</Label>
                      </Col>
                      <Col lg="4" xs="4">
                        <Input
                          type="radio"
                          name="gender"
                          className="campus-radio-box cursor-pointer"
                          onClick={() => handleGenderRegister('T')}
                        />
                        <Label check className="form-check-text mx-1">Others</Label>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg="11" className="pt-4">
                    <Label check>
                      <Input
                        type="checkbox"
                        disabled={registerLoader}
                        onChange={handleCheckChange}
                        className="campus-check-box ml cursor-pointer"
                      />{' '}
                      <span className="form-check-text">Send me important updates on my </span><span className="text-whatsapp"><FontAwesomeIcon icon={faWhatsapp} className="campus-icon-green mx-1" /> WhatsApp</span>
                    </Label>
                  </Col>
                  <Col lg="11" className="pt-4">
                    <Label check>
                      <Input
                        type="checkbox"
                        disabled={registerLoader}
                        onChange={handleTermChange}
                        className="campus-check-box ml pt-2 cursor-pointer"
                      />{' '}
                      <span className="text-lite-grey">I have read the <Button className="button-transparent" onClick={handleTermUse}>terms &amp; use</Button> and agree.</span>
                    </Label>
                  </Col>
                  <Col lg="11" className="pt-4">
                    <div className="text-right-login-campus-register align-end my-1">
                      <Button className="campus-button mx-4" disabled={!registerXCLNData.isAgreeTrm}>
                        Register
                      </Button>
                    </div>
                  </Col>
                </Row>
                <Row />
              </div>
            </Form>
            <div className="align-center pt-2">
              <span className="mx-3 text-lite-grey">Already signed up ? <Button className="button-transparent" onClick={loginPage}>Login </Button></span>
            </div>
            <Modal isOpen={modalConfirm}>
              <ModalBody>
                <div>
                  All the terms Data Visible here
                </div>
                <div className="align-end pt-3">
                  <Button className="campus-button mx-1" onClick={handleTermUse}>
                    cancel
                  </Button>
                </div>
              </ModalBody>
            </Modal>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default RegisterXcelian;
