import { DelPostResponse, DelPostResults } from './del-post.service.types';

export const DelPostTransform: (response: DelPostResponse) => DelPostResults = (delPostResults) => {
  const { data } = delPostResults;
  const result: DelPostResults = {
    fileName: '',
  };
  result.fileName = data;
  return result;
};
