import React, { useState } from 'react';
import { RootState } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Col, Form, Row,
} from 'reactstrap';
import {
  addClientUserReq,
  clearAddClientUserReq,
} from '../../../../../store/campusXcel/actions';
import { successAlert, errorAlert } from '../../../../../utils/alert';
import {
  APIHeader, UserType,
} from '../../../../../utils/constants';
import LoaderData from '../../../../../utils/loader';
import { getTokenFromLocalStorage } from '../../../../../utils/service/localstorage-service';
import { TabProps } from '../onboard.type';
import { AddCampusUser } from '../campus/add-campus-user-form';
import { ClientUserAddDataInput } from '../../../../../services/sadmin/add-client-user/add-new-client-user.types';

export const AddClientUserUser: React.FC<TabProps> = ({ ordTp, tabTp, clientTp }) => {
  const dispatch = useDispatch();
  const tokenData = getTokenFromLocalStorage();
  const [isValidEmail, setIsValidEmail] = React.useState(false);
  const [isValidPhone, setIsValidPhone] = React.useState(false);
  const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
  const phoneRegex = /^\d{10}$/;
  const [loaderClientUser, setLoaderClientUser] = useState(false);
  const [showSuccessAlert, setSuccessShowAlert] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const clientAddUserResponse = useSelector((state: RootState) => state.campusXcel.addClientUser);
  const [clientUser, setClientUser] = useState<ClientUserAddDataInput>({
    ClntTp: '',
    ClntId: '',
    fNme: '',
    lNme: '',
    dlCd: '+91',
    ph: '',
    eId: '',
    gnd: 'UNK',
    isAcs: false,
    isAdm: false,
    lgnSts: '',
  });

  const handleSubmitClientUser = (e: any) => {
    e.preventDefault();
    const validEmail = emailRegex.test(clientUser.eId);
    const validPhone = phoneRegex.test(clientUser.ph);
    if (validEmail && validPhone) {
      setLoaderClientUser(true);
      dispatch(addClientUserReq({
        inputBody: clientUser,
        requestType: APIHeader.REQ_ONBOARD_CLIENT_USER,
        uTp: UserType.CXADM,
        token: tokenData,
      }));
    } else if (!validEmail) {
      setIsValidEmail(true);
    } else if (!validPhone) {
      setIsValidPhone(true);
    }
  };

  React.useEffect(() => {
    if (clientAddUserResponse.error && clientAddUserResponse.message !== '') {
      setLoaderClientUser(false);
      setAlertMsg(clientAddUserResponse.message);
      setErrorShowAlert(true);
      dispatch(clearAddClientUserReq());
    }
  }, [clientAddUserResponse.error, clientAddUserResponse.message]);

  React.useEffect(() => {
    if (!clientAddUserResponse.error && clientAddUserResponse.message !== '') {
      setLoaderClientUser(false);
      Promise.resolve(setAlertMsg(clientAddUserResponse.message))
        .then(() => { setSuccessShowAlert(true); })
        .then(() => {
          setClientUser({
            ClntTp: '',
            ClntId: '',
            fNme: '',
            lNme: '',
            dlCd: '+91',
            ph: '',
            eId: '',
            gnd: 'UNK',
            isAcs: false,
            isAdm: false,
            lgnSts: '',
          });
        })
        .then(() => {
          dispatch(clearAddClientUserReq());
        });
    }
  }, [clientAddUserResponse.error, clientAddUserResponse.message]);

  return (
    <div>
      {/* <div className="mb-3 align-center">
        Onboard {clientUserTp === 'CMPS' ? 'Campus' : null}
      </div> */}
      <Row className="align-start">
        {isValidEmail ? (
          errorAlert(false, 'Please enter valid Email Id !!!', isValidEmail, setIsValidEmail)
        ) : null}
        {isValidPhone ? (
          errorAlert(false, 'Please enter valid Phone Number !!!', isValidPhone, setIsValidPhone)
        ) : null}
        { loaderClientUser ? <LoaderData /> : null}
        {showSuccessAlert ? (
          successAlert(false, alertMsg, showSuccessAlert, setSuccessShowAlert)
        ) : null}
        {showErrorsAlert ? (
          errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
        ) : null}
        <Col lg="9" xs="12">
          <div className="onboard-chip-box">
            <Form onSubmit={handleSubmitClientUser}>
              <Row className="align-center">
                <Col xs="12">
                  {clientTp === 'CMPS'
                    ? <AddCampusUser ordTp={ordTp} clientTp={clientTp} tabTp={tabTp} campusUsr={clientUser} setCampusUsr={setClientUser} /> : null}
                </Col>
                <Col xs="12">
                  <div className="align-center my-3">
                    <Button className="campus-button-all" disabled={clientUser.ClntTp === '' && clientUser.ClntId === ''}>
                      SAVE
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AddClientUserUser;
