import React from 'react';
import {
  CardImg,
  Col, Nav, NavItem, NavLink, Row, TabContent, TabPane,
} from 'reactstrap';
import './onboard.scss';
import { CampusComponent } from './campus/campus.component';

export const OnboardComponent: React.FC = () => {
  const [activeTab, setActiveTab] = React.useState('recruit');

  return (
    <Row className="onboard-main-margin-top">
      <Col xs="12">
        <Nav tabs className="campus-onboard-tab">
          <div className="pt-1">
            <div className="campus-column-onboard-tab-left">
              <NavItem className="cursor-pointer">
                <NavLink className="campus-onboard-nav-tabs onboard-horz-comp" active={activeTab === 'recruit'} onClick={() => setActiveTab('recruit')}>
                  <div className="align-center">
                    <CardImg src="/images/icons/goal.png" className="campus-tab-onboard-icon-img" alt="Icon 1" />
                  </div>
                  <div className="align-center">
                    <span className="campus-tab-text mx-1">Recruiters</span>
                  </div>
                </NavLink>
              </NavItem>
            </div>
            <div className="campus-column-onboard-tab-right">
              <NavItem className="cursor-pointer">
                <NavLink className="campus-onboard-nav-tabs onboard-horz-comp" active={activeTab === 'campus'} onClick={() => setActiveTab('campus')}>
                  <div className="align-center">
                    <CardImg src="/images/icons/campus.png" className="campus-tab-onboard-icon-img" alt="Icon 2" />
                  </div>
                  <div className="align-center">
                    <span className="campus-tab-text">Campus</span>
                  </div>
                </NavLink>
              </NavItem>
            </div>
            <div className="campus-column-onboard-tab-right">
              <NavItem className="cursor-pointer">
                <NavLink className="campus-onboard-nav-tabs onboard-horz-comp" active={activeTab === 'corp'} onClick={() => setActiveTab('corp')}>
                  <div className="align-center">
                    <CardImg src="/images/icons/corp.png" className="campus-tab-onboard-icon-img" alt="Icon 3" />
                  </div>
                  <div className="align-center">
                    <span className="campus-tab-text">Corporates</span>
                  </div>
                </NavLink>
              </NavItem>
            </div>
          </div>
        </Nav>
      </Col>
      <Col xs="12">
        <TabContent activeTab={activeTab}>
          <TabPane tabId="recruit">
            Recruiters
          </TabPane>
          <TabPane tabId="corp">
            Corporates
          </TabPane>
          <TabPane tabId="campus">
            <div className="pt-1 mx-2">
              <div className="text-line">
                Campus Info
              </div>
            </div>
            <CampusComponent ordTp={activeTab} />
          </TabPane>
        </TabContent>
      </Col>
      <div className="pt-5" />
    </Row>
  );
};

export default OnboardComponent;
