import React, { useState } from 'react';
import { RootState } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Col, Input, Row,
} from 'reactstrap';
import { faMagnifyingGlass, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  clearDelClientReq,
  delClientReq,
  getClientListReq,
} from '../../../../../store/campusXcel/actions';
import { successAlert, errorAlert } from '../../../../../utils/alert';
import {
  APIHeader, UserType,
} from '../../../../../utils/constants';
import '../onboard.scss';
import { getTokenFromLocalStorage } from '../../../../../utils/service/localstorage-service';
import { TabProps } from '../onboard.type';
import AllCampusList from '../campus/all-campus';
import { AllClientsResult, DelClientData, ListClientResultData } from '../../../../../services/sadmin/list-client/list-client.types';
import Modal from '../../../../../utils/Modal/Modal';

export const ListClient: React.FC<TabProps> = ({ ordTp, tabTp, clientTp }) => {
  const dispatch = useDispatch();
  const tokenData = getTokenFromLocalStorage();
  // const [loaderClient, setLoaderClient] = useState(false);
  const [loadingMore, setLoadingMore] = React.useState(false);
  const [showSuccessAlert, setSuccessShowAlert] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [searchText, setSearchText] = React.useState('');
  const [evalKeyText, setEvalKeyText] = React.useState('');
  const [evalTpText, setEvalTpText] = React.useState('');
  const [isSearchQuery, setIsSearchQuery] = React.useState('');
  const [recordsPerPage] = React.useState<number>(10);
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [totalRecords, setTotalRecords] = React.useState<number>(0);
  const [pagedData, setPagedData] = React.useState<Record<number, any[]>>({});
  const [modalDelClient, setModalDelClient] = React.useState(false);
  const clientListResponse = useSelector((state: RootState) => state.campusXcel.getClientList);
  const delClientRes = useSelector((state: RootState) => state.campusXcel.delClient);
  const [deleteRecord, setDeleteRecord] = React.useState<DelClientData>({
    ClntTp: '',
    ClntId: '',
    nme: '',
  });
  const [, setEditRecord] = React.useState<ListClientResultData>({
    ClntTp: '',
    ClntId: '',
    cTp: '',
    nme: '',
    cntEId: '',
    dlCd1: '',
    cntPh1: '',
    citySt: '',
    dlCd2: '',
    cntPh2: '',
    univ: '',
    txId: '',
    web: '',
    oNme: '',
  });
  const [, setAllCientData] = React.useState<AllClientsResult>({
    tCnt: 0,
    fCnt: 0,
    clntData: [],
    lastEvalKey: {
      ClntTp: '',
      ClntId: '',
    },
  });

  React.useEffect(() => {
    if (deleteRecord.ClntTp !== '' && deleteRecord.ClntId !== '') {
      setModalDelClient(true);
    }
  }, [deleteRecord.ClntTp, deleteRecord.ClntId]);

  React.useEffect(() => {
    if (ordTp === 'campus' && tabTp === 'listCampus' && clientTp === 'CMPS') {
      setLoadingMore(true);
      dispatch(getClientListReq({
        requestType: APIHeader.REQ_ALL_CLIENT,
        uTp: UserType.CXADM,
        limit: recordsPerPage, // ApiLIMIT.LIMIT,
        evalKey: '',
        evalTp: '',
        isFilter: 'NO',
        isSearch: 'NO',
        token: tokenData,
        clntTp: clientTp,
        isMeta: false,
        clntSts: 'ACTIVE',
      }));
    }
  }, [ordTp, tabTp, clientTp]);

  const fetchMoreData = () => {
    // setLoaderClient(true);
    setLoadingMore(true);
    if (isSearchQuery !== '') {
      dispatch(getClientListReq({
        requestType: APIHeader.REQ_ALL_CLIENT,
        uTp: UserType.CXADM,
        limit: recordsPerPage,
        evalKey: evalKeyText,
        evalTp: evalTpText,
        isFilter: 'NO',
        searchBy: searchText,
        isSearch: 'YES',
        token: tokenData,
        clntTp: clientTp,
        isMeta: false,
        clntSts: 'ACTIVE',
      }));
    } else {
      setLoadingMore(true);
      dispatch(
        getClientListReq({
          requestType: APIHeader.REQ_ALL_CLIENT,
          uTp: UserType.CXADM,
          limit: recordsPerPage, // ApiLIMIT.LIMIT,
          evalKey: evalKeyText,
          evalTp: evalTpText,
          isFilter: 'NO',
          isSearch: 'NO',
          token: tokenData,
          clntTp: clientTp,
          isMeta: false,
          clntSts: 'ACTIVE',
        }),
      );
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    if (!pagedData[page]) {
      fetchMoreData();
    }
  };

  const handleSearchChange = (e:any) => {
    setSearchText(e.target.value);
  };
  const handleRefreshClient = () => {
    setCurrentPage(0);
    setTotalRecords(0);
    setPagedData([]);
    setLoadingMore(true);
    dispatch(getClientListReq({
      requestType: APIHeader.REQ_ALL_CLIENT,
      uTp: UserType.CXADM,
      limit: recordsPerPage,
      evalKey: '',
      evalTp: '',
      isFilter: 'NO',
      isSearch: 'NO',
      token: tokenData,
      clntTp: clientTp,
      isMeta: false,
      clntSts: 'ACTIVE',
    }));
  };

  const handleSearchProjects = () => {
    // setLoaderClient(true);
    setCurrentPage(0);
    setTotalRecords(0);
    setPagedData([]);
    setLoadingMore(true);
    setIsSearchQuery(searchText);
    dispatch(getClientListReq({
      requestType: APIHeader.REQ_ALL_CLIENT,
      uTp: UserType.CXADM,
      limit: recordsPerPage,
      evalKey: '',
      evalTp: '',
      isFilter: 'NO',
      searchBy: searchText,
      isSearch: 'YES',
      token: tokenData,
      clntTp: clientTp,
      isMeta: false,
      clntSts: 'ACTIVE',
    }));
  };

  React.useEffect(() => {
    if (clientListResponse.isError && clientListResponse.message !== '') {
      // setLoaderClient(false);
      setLoadingMore(false);
      setAlertMsg(clientListResponse.message);
      setErrorShowAlert(true);
    }
  }, [clientListResponse.isError, clientListResponse.message]);

  React.useEffect(() => {
    if (!clientListResponse.isError && clientListResponse.message === 'executed') {
      // setLoaderClient(false);
      setLoadingMore(false);

      if (searchText !== '') {
        setPagedData({ [currentPage]: clientListResponse.data.clntData });
      } else if (
        clientListResponse.data
        && Object.keys(clientListResponse.data).length > 0
        && Array.isArray(clientListResponse.data.clntData)
      ) {
        const newPageData = clientListResponse.data.clntData;
        setPagedData((prev) => ({
          ...prev,
          [currentPage]: newPageData,
        }));
        setAllCientData({
          tCnt: clientListResponse.data.tCnt,
          fCnt: clientListResponse.data.fCnt,
          clntData: clientListResponse.data.clntData,
          lastEvalKey: clientListResponse.data.lastEvalKey,
        });
        if (clientListResponse.data.lastEvalKey !== null) {
          setEvalKeyText(clientListResponse.data.lastEvalKey.ClntId);
          setEvalTpText(clientListResponse.data.lastEvalKey.ClntTp);
        }
        setTotalRecords(clientListResponse.data.tCnt);
      }
    }
  }, [clientListResponse]);

  const handleSubmitDelClient = (status: string) => {
    if (status === 'approve') {
      setLoadingMore(true);
      Promise.resolve(
        dispatch(delClientReq({
          requestType: APIHeader.REQ_DELETE_CLIENT,
          uTp: UserType.CXADM,
          token: tokenData,
          clntTp: deleteRecord.ClntTp,
          clntId: deleteRecord.ClntId,
        })),
      );
    } else if (status === 'cancel') {
      setModalDelClient(false);
    }
    setModalDelClient(false);
  };

  React.useEffect(() => {
    if (delClientRes.error && delClientRes.message !== '') {
      setLoadingMore(false);
      dispatch(clearDelClientReq());
    }
  }, [delClientRes.error, delClientRes.message]);

  React.useEffect(() => {
    if (!delClientRes.error && delClientRes.message === 'executed') {
      dispatch(clearDelClientReq());
      setAllCientData((prevData) => ({
        ...prevData,
        tCnt: 0,
        fCnt: 0,
        clntData: [],
        lastEvalKey: {
          ClntTp: '',
          ClntId: '',
        },
      }));
      dispatch(getClientListReq({
        requestType: APIHeader.REQ_ALL_CLIENT,
        uTp: UserType.CXADM,
        limit: recordsPerPage,
        evalKey: '',
        evalTp: '',
        isFilter: 'NO',
        isSearch: 'NO',
        token: tokenData,
        clntTp: clientTp,
        isMeta: false,
        clntSts: 'ACTIVE',
      }));
      setModalDelClient(false);
      setLoadingMore(false);
    }
  }, [delClientRes.error, delClientRes.message]);

  return (
    <div>
      {/* <div className="mb-3 align-center">
        List of {clientTp === 'CMPS' ? 'Campus' : null}
      </div> */}
      <Row className="align-center">
        {/* { loaderClient ? <LoaderData /> : null} */}
        {showSuccessAlert ? (
          successAlert(false, alertMsg, showSuccessAlert, setSuccessShowAlert)
        ) : null}
        {showErrorsAlert ? (
          errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
        ) : null}
        <Col lg="12" xs="12">
          {loadingMore ? (
            <div className="loader-container-none align-center1 pt-5">
              <img src="/images/spinner.png" alt="Loading" className="spinner-img loader-image-size" />
              <span className="mx-2 text-lite">Data is loading... <br />Please wait....</span>
            </div>
          ) : (
            <Row className="align-start mx-1">
              <Col xs="12" lg="5">
                <div className="align-end">
                  <div className="input-wrapper">
                    <Input
                      type="text"
                      placeholder="Search..."
                      value={searchText}
                      onChange={handleSearchChange}
                    />
                    <Button type="button" onClick={handleSearchProjects}>
                      <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </Button>
                  </div>
                  <Button className="onboard-refresh-button" onClick={handleRefreshClient}>
                    <FontAwesomeIcon icon={faRefresh} />
                  </Button>
                </div>
              </Col>
              <Col xs="12">
                <div className="pt-1">
                  {clientTp === 'CMPS' && pagedData[currentPage]
                    ? (
                      <AllCampusList
                        pagedData={pagedData[currentPage]}
                        currentPage={currentPage}
                        handlePageChange={handlePageChange}
                        recordsPerPage={recordsPerPage}
                        totalRecords={totalRecords}
                        setEditRecord={setEditRecord}
                        setDeleteRecord={setDeleteRecord}
                      />
                    ) : null}
                </div>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      <Modal
        show={modalDelClient || false}
        onClose={() => (false)}
        disCancel
        targetId="delClientData"
        title=""
        size="MD"
      >
        <div className="align-center pt-3">
          <span className="text-lite">Do you really want to revoke access?</span>
        </div>
        <div className="my-2 align-center">
          <span className="card-toggle-text">{deleteRecord.nme}</span>
        </div>
        <div className="align-center my-3 mx-2">
          <Button className="button-icon-know-more mx-1" onClick={() => handleSubmitDelClient('approve')}>
            REVOKE
          </Button>
          <Button className="campus-button-all mx-1" onClick={() => handleSubmitDelClient('cancel')}>
            CANCEL
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default ListClient;
