import { combineEpics, Epic } from 'redux-observable';
import {
  isActionOf, RootAction, RootState, Services,
} from 'typesafe-actions';
import { from } from 'rxjs';
import {
  filter, map, mergeMap, catchError,
} from 'rxjs/operators';
import {
  addClientReq,
  addClientUserReq,
  addCommentReq,
  addFollowReq,
  addLikeReq,
  apiError,
  batchReq,
  candStsReq,
  contactUsPostReq,
  delClientReq,
  delOrgProj,
  delPostPublish,
  getAllJobs,
  getAllPJobs,
  getAPLPJobs,
  getBatchListReq,
  getCandidatesListReq,
  getCert,
  getClientListReq,
  getClientUserListReq,
  getCommentListReq,
  getFileURLReq,
  getForgotPassword,
  getHobbies,
  getJobPositonById,
  getJobStatus,
  getListOrgProj,
  getLoginToken,
  getMentorAccess,
  getMentorStatusListReq,
  getObjective,
  getOrgProject,
  getPostListReq,
  getPostPublish,
  getPreviewResume,
  getProfile,
  getResultCert,
  getResultHobbies,
  getResumeDataObj,
  getResumeProfile,
  getResumeVideo,
  getS3CxMetaReq,
  getS3FilesReq,
  getSVDPJobs,
  getUpdateS3CxMetaReq,
  getUserLikesListReq,
  getVideo,
  passKeyReq,
  postDBReq,
  registerAbtMeMNTRReq,
  registerCMPSReq,
  registerEmailMNTRReq,
  registerEmployMNTRReq,
  registerPrsnlMNTRReq,
  registerXCLNReq,
  updateAddClientReq,
  updateAddClientUserReq,
  updateAllJobs,
  updateAllPJobs,
  updateAPLPJobs,
  updateBatchListReq,
  updateBatchReq,
  updateCandidatesListReq,
  updateCandStsReq,
  updateCert,
  updateClientListReq,
  updateClientUserListReq,
  updateCommentListReq,
  updateCommentReq,
  updateContactUsPostReq,
  updateDelClientReq,
  updateDelOrgProjReq,
  updateDelPostPublish,
  updateFileURLReq,
  updateFollowReq,
  updateForgotPassword,
  updateGetS3FilesReq,
  updateHobbies,
  updateJobPositonById,
  updateJobStatusReq,
  updateLikeReq,
  updateListOrgProj,
  updateMentorAccess,
  updateMentorStatusListReq,
  updateObjective,
  updateOrgProject,
  updatePassKeyReq,
  updatePostDBReq,
  updatePostListReq,
  updatePostPublish,
  updatePreviewResume,
  updateProfile,
  updateRegisterAbtMeMNTRReq,
  updateRegisterCMPSPostReq,
  updateRegisterEmailMNTRReq,
  updateRegisterEmployMNTRReq,
  updateRegisterPrsnlMNTRReq,
  updateRegisterXCLNPostReq,
  updateResultCert,
  updateResultHobbies,
  updateResumeDataObj,
  updateResumeProfile,
  updateResumeVideo,
  updateS3CxMetaReq,
  updateSVDPJobs,
  updateToken,
  updateUpdateS3CxMetaReq,
  updateUploadS3FilesReq,
  updateUserLikesListReq,
  updateUserProfilePutReq,
  updateVideo,
  uploadS3FilesReq,
  userProfilePutReq,
} from './actions';
import { GetCertResults } from '../../services/campus/certifications/get-cert/get-cert.service.types';
import { CertPutResults } from '../../services/campus/certifications/update-cert/update-cert.service.types';
import { BatchAddResults } from '../../services/campus/college-admin/cmps-batches/add-batch/add-batch.service.types';
import { ListBatchResults } from '../../services/campus/college-admin/cmps-batches/list-batch/list-batch.service.types';
import { CommentResults } from '../../services/campus/dashboard/comments/add-comment/comment.service.types';
import { ListCommentResults } from '../../services/campus/dashboard/comments/get-all-comments/list-comment.service.types';
import { FollowResults } from '../../services/campus/dashboard/follow/add-follow/follow.service.types';
import { LikeResults } from '../../services/campus/dashboard/likes/add-like/like.service.types';
import { ListUserLikesResults } from '../../services/campus/dashboard/likes/get-all-likes/list-user-likes.service.types';
import { PostDBResults } from '../../services/campus/dashboard/post/add-post-db/post-db.service.types';
// import { CreatePostResults } from '../../services/campus/dashboard/post/create-post-college/create-post.service.types';
import { DelPostResults } from '../../services/campus/dashboard/post/del-post-file-s3/del-post.service.types';
import { ListPostResults } from '../../services/campus/dashboard/post/get-all-posts/list-post.service.types';
import { GetHobbiesResults } from '../../services/campus/hobbies/get-hobbies/get-hobbies.service.types';
import { HobbiesPutResults } from '../../services/campus/hobbies/update-hobbies/update-hobbies.service.types';
import { RegisterCMPSResults } from '../../services/campus/register-campus/register.service.types';
import { ResumeProfileResults } from '../../services/campus/resume-data/get-cand-profile/get-profile.service.types';
import { ResumeObjResults } from '../../services/campus/resume-data/objective/get-obj/get-obj.service.types';
import { ObjectiveResults } from '../../services/campus/resume-data/objective/update-obj/objective.service.types';
import { DelOrgProjResults } from '../../services/campus/resume-data/org-project/delete-org-proj/del-org-proj.service.types';
import { ListOrgProjResults } from '../../services/campus/resume-data/org-project/get-all-org-obj/list-org-proj.service.types';
import { OrgProjResults } from '../../services/campus/resume-data/org-project/update-org-obj/org-proj.service.types';
import { ResumePreviewResults } from '../../services/campus/resume-data/preview/get-preview.service.types';
import { ForgotPasswordResults } from '../../services/common/forgot-password/forgot-password.service.types';
import { GenTokenResults } from '../../services/common/gen-token/gen-token.service.types';
import { FileURLResults } from '../../services/common/get-file-url/get-file-url.service.types';
import { ProfileResults } from '../../services/common/get-profile/get-profile.service.types';
import { PassKeyPutResults } from '../../services/common/update-passkey/update-passkey.service.types';
import { UserProfilePutResults } from '../../services/common/update-profile/update-profile.service.types';
import { JobPositionByIdResults } from '../../services/staffing/job-board/job-position-by-id/job-position-by-id.service.types';
import { ListJobsResults } from '../../services/staffing/job-board/list-jobs/list-jobs.service.types';
import { ListPJobsResults } from '../../services/staffing/job-board/list-personalized-jobs/list-p-jobs.service.types';
import { JobStatusResults } from '../../services/staffing/job-board/update-job-status/job-status.service.types';
import { RegisterXCLNResults } from '../../services/staffing/xcelian/register-xcelian/register.service.types';
import { MentorAccessUpdateResults } from '../../services/upskilling/mentors/mentor-access-update/mentor-update.service.types';
import { ListMentorResults } from '../../services/upskilling/mentors/mentor-list/list-mentor.service.types';
import { RegisterAbtMeMNTRSResults } from '../../services/upskilling/register-mentors/About/register.service.types';
import { RegisterPrsnlMNTRSResults } from '../../services/upskilling/register-mentors/Personal/register.service.types';
import { RegisterEmployMNTRSResults } from '../../services/upskilling/register-mentors/Prof-Exp/register.service.types';
import { RegisterEmailMNTRSResults } from '../../services/upskilling/register-mentors/ValidateEmail/register.service.types';
import { UploadS3FilesResults } from '../../services/common/upload-s3-files/upload-s3-files.service.types';
import { CreatePostResults } from '../../services/campus/dashboard/post/create-post-college/create-post.service.types';
import { GetS3FilesResults } from '../../services/common/get-s3-files/get-s3files.service.types';
import { UpdateS3CxMetaResults } from '../../services/aIntelligence/update-cx-meta/update-s3-cx-meta.service.types';
import { GetS3CxMetaResults } from '../../services/aIntelligence/get-all-cx-meta/get-s3-cx-meta.service.types';
import { ContactUsResults } from '../../services/sadmin/contact-us/contact-us.service.types';
import { ListClientResults } from '../../services/sadmin/list-client/list-client.service.types';
import { ClientAddResults } from '../../services/sadmin/add-clients/add-new-clients.service.types';
import { ClientUserAddResults } from '../../services/sadmin/add-client-user/add-new-client-user.service.types';
import { ListClientUserResults } from '../../services/sadmin/list-client-user/list-client-user.service.types';
import { DELClientResults } from '../../services/sadmin/delete-client/del-client.service.types';
import { ListCandidatesResults } from '../../services/campus/college-admin/acad-students/list-candidates/list-candidates.service.types';
import { CandPutStsResults } from '../../services/campus/college-admin/acad-students/update-candidate-sts/update-cand-sts.service.types';
import { ResumeVideoResults } from '../../services/campus/resume-data/video-profile/get-video-url/get-video.service.types';
import { VideoResults } from '../../services/campus/resume-data/video-profile/update-video/video.service.types';

export const contactUsPOSTEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(contactUsPostReq)),
  mergeMap((action) => from(campusXcelService.contactUsService(action.payload)).pipe(
    map((response: ContactUsResults) => updateContactUsPostReq(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'CampusXcel',
      errorMessage: error.message,
    })),
  )),
);
export const getClientListEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getClientListReq)),
  mergeMap((action) => from(campusXcelService.clientListService(action.payload)).pipe(
    map((response: ListClientResults) => updateClientListReq(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'Client Meta',
      errorMessage: error.message,
    })),
  )),
);

export const registerCandidateEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(registerCMPSReq)),
  mergeMap((action) => from(campusXcelService.registerCMPSService(action.payload)).pipe(
    map((response: RegisterCMPSResults) => updateRegisterCMPSPostReq(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'CampusConnect',
      errorMessage: error.message,
    })),
  )),
);
export const generateTokenEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getLoginToken)),
  mergeMap((action) => from(campusXcelService.genTokenService(action.payload)).pipe(
    map((response: GenTokenResults) => updateToken(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'Login',
      errorMessage: error.message,
    })),
  )),
);
export const getProfileEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getProfile)),
  mergeMap((action) => from(campusXcelService.profileService(action.payload)).pipe(
    map((response: ProfileResults) => updateProfile(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'Profile',
      errorMessage: error.message,
    })),
  )),
);
export const getForgotPasswordEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getForgotPassword)),
  mergeMap((action) => from(campusXcelService.forgotPasswordService(action.payload)).pipe(
    map((response: ForgotPasswordResults) => updateForgotPassword(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'ForgotPassword',
      errorMessage: error.message,
    })),
  )),
);
export const getFileURLEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getFileURLReq)),
  mergeMap((action) => from(campusXcelService.fileURLService(action.payload)).pipe(
    map((response: FileURLResults) => updateFileURLReq(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'File URL Update',
      errorMessage: error.message,
    })),
  )),
);

export const userProfilePutEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(userProfilePutReq)),
  mergeMap((action) => from(campusXcelService.userProfilePutService(action.payload)).pipe(
    map((response: UserProfilePutResults) => updateUserProfilePutReq(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'User Profile Update',
      errorMessage: error.message,
    })),
  )),
);

export const uploadS3FilesEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(uploadS3FilesReq)),
  mergeMap((action) => from(campusXcelService.uploadS3FilesService(action.payload)).pipe(
    map((response: UploadS3FilesResults) => updateUploadS3FilesReq(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'Upload S3 Files',
      errorMessage: error.message,
    })),
  )),
);
export const getS3FilesEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getS3FilesReq)),
  mergeMap((action) => from(campusXcelService.getS3FilesService(action.payload)).pipe(
    map((response: GetS3FilesResults) => updateGetS3FilesReq(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'Get S3 Files',
      errorMessage: error.message,
    })),
  )),
);
export const passKeyEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { campusXcelService },
) => action$.pipe(
  filter(isActionOf(passKeyReq)),
  mergeMap((action) => from(campusXcelService.passKeyService(action.payload)).pipe(
    map((response: PassKeyPutResults) => updatePassKeyReq(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'Pass Key Update',
      errorMessage: error.message,
    })),
  )),
);

export const getResumeProfileEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getResumeProfile)),
  mergeMap((action) => from(campusXcelService.resumeProfileService(action.payload)).pipe(
    map((response: ResumeProfileResults) => updateResumeProfile(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'Resume Profile',
      errorMessage: error.message,
    })),
  )),
);
export const updateObjectiveEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getObjective)),
  mergeMap((action) => from(campusXcelService.objectiveService(action.payload)).pipe(
    map((response: ObjectiveResults) => updateObjective(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'Update Objective',
      errorMessage: error.message,
    })),
  )),
);

export const getResumeObjEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getResumeDataObj)),
  mergeMap((action) => from(campusXcelService.getResumeObjService(action.payload)).pipe(
    map((response: ResumeObjResults) => updateResumeDataObj(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'Get Objective Data',
      errorMessage: error.message,
    })),
  )),
);

export const updateJobStatusEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getJobStatus)),
  mergeMap((action) => from(campusXcelService.jobStatusService(action.payload)).pipe(
    map((response: JobStatusResults) => updateJobStatusReq(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'Job Status Update',
      errorMessage: error.message,
    })),
  )),
);

export const listJobsEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { campusXcelService },
) => action$.pipe(
  filter(isActionOf(getAllJobs)),
  mergeMap((action) => from(campusXcelService.listJobsService(action.payload)).pipe(
    map((response: ListJobsResults) => updateAllJobs(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'JobBoard',
      errorMessage: error.message,
    })),
  )),
);

export const listPJobsEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getAllPJobs)),
  mergeMap((action) => from(campusXcelService.listPJobsService(action.payload)).pipe(
    map((response: ListPJobsResults) => updateAllPJobs(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'Personal JobBoard',
      errorMessage: error.message,
    })),
  )),
);

export const listAPLPJobsEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getAPLPJobs)),
  mergeMap((action) => from(campusXcelService.listAPLPJobsService(action.payload)).pipe(
    map((response: ListPJobsResults) => updateAPLPJobs(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'Personal Applied JobBoard',
      errorMessage: error.message,
    })),
  )),
);

export const listSVDPJobsEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getSVDPJobs)),
  mergeMap((action) => from(campusXcelService.listSVDPJobsService(action.payload)).pipe(
    map((response: ListPJobsResults) => updateSVDPJobs(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'Personal Saved JobBoard',
      errorMessage: error.message,
    })),
  )),
);

export const jobsByIdEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { campusXcelService },
) => action$.pipe(
  filter(isActionOf(getJobPositonById)),
  mergeMap((action) => from(campusXcelService.jobPositionByIdsService(action.payload)).pipe(
    map((response: JobPositionByIdResults) => updateJobPositonById(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'JobBoard',
      errorMessage: error.message,
    })),
  )),
);

export const updateOrgProjEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getOrgProject)),
  mergeMap((action) => from(campusXcelService.orgProjService(action.payload)).pipe(
    map((response: OrgProjResults) => updateOrgProject(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'Org Project Update',
      errorMessage: error.message,
    })),
  )),
);

export const listOrgProjEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getListOrgProj)),
  mergeMap((action) => from(campusXcelService.listOrgProjService(action.payload)).pipe(
    map((response: ListOrgProjResults) => updateListOrgProj(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'List Org Proj',
      errorMessage: error.message,
    })),
  )),
);

export const deleteOrgProjEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(delOrgProj)),
  mergeMap((action) => from(campusXcelService.deleteOrgProjService(action.payload)).pipe(
    map((response: DelOrgProjResults) => updateDelOrgProjReq(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'Delete Org Proj',
      errorMessage: error.message,
    })),
  )),
);
export const getResumePreviewEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getPreviewResume)),
  mergeMap((action) => from(campusXcelService.getResumePreviewService(action.payload)).pipe(
    map((response: ResumePreviewResults) => updatePreviewResume(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'Get All Resume Data',
      errorMessage: error.message,
    })),
  )),
);
// export const userProfileVideoEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { campusXcelService }) => action$.pipe(
//   filter(isActionOf(uploadVideoReq)),
//   mergeMap((action) => from(campusXcelService.userProfileVideoService(action.payload)).pipe(
//     map((response : UploadVideoResults) => updateUploadVideoReq(response)),
//     catchError(async (error: Error) => apiError({ failed: true, backend: 'User Profile Video Update', errorMessage: error.message })),
//   )),
// );
// export const getVideURLEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { campusXcelService }) => action$.pipe(
//   filter(isActionOf(getVideoURLReq)),
//   mergeMap((action) => from(campusXcelService.videoURLService(action.payload)).pipe(
//     map((response : VideoURLResults) => updateVideoURLReq(response)),
//     catchError(async (error: Error) => apiError({ failed: true, backend: 'Video URL Update', errorMessage: error.message })),
//   )),
// );
export const updateHobbiesEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getHobbies)),
  mergeMap((action) => from(campusXcelService.hobbiesService(action.payload)).pipe(
    map((response: HobbiesPutResults) => updateHobbies(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'Update Hobbies',
      errorMessage: error.message,
    })),
  )),
);
export const getHobbiesEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getResultHobbies)),
  mergeMap((action) => from(campusXcelService.getHobbiesService(action.payload)).pipe(
    map((response: GetHobbiesResults) => updateResultHobbies(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'Get Hobbies Data',
      errorMessage: error.message,
    })),
  )),
);
export const updateCertEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getCert)),
  mergeMap((action) => from(campusXcelService.certService(action.payload)).pipe(
    map((response: CertPutResults) => updateCert(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'Update Cert',
      errorMessage: error.message,
    })),
  )),
);
export const getCertEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { campusXcelService },
) => action$.pipe(
  filter(isActionOf(getResultCert)),
  mergeMap((action) => from(campusXcelService.getCertService(action.payload)).pipe(
    map((response: GetCertResults) => updateResultCert(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'Get Cert Data',
      errorMessage: error.message,
    })),
  )),
);
export const updatePostPublishEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getPostPublish)),
  mergeMap((action) => from(campusXcelService.postPublishService(action.payload)).pipe(
    map((response: CreatePostResults) => updatePostPublish(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'Update POSTS',
      errorMessage: error.message,
    })),
  )),
);
export const delPostPublishEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(delPostPublish)),
  mergeMap((action) => from(campusXcelService.delPostPublishService(action.payload)).pipe(
    map((response: DelPostResults) => updateDelPostPublish(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'Delete POSTS file from S3',
      errorMessage: error.message,
    })),
  )),
);
export const getPostListEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getPostListReq)),
  mergeMap((action) => from(campusXcelService.postListService(action.payload)).pipe(
    map((response: ListPostResults) => updatePostListReq(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'List Post Data',
      errorMessage: error.message,
    })),
  )),
);
export const addPostPublishDBEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(postDBReq)),
  mergeMap((action) => from(campusXcelService.postDBService(action.payload)).pipe(
    map((response: PostDBResults) => updatePostDBReq(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'Add New POSTS to DB',
      errorMessage: error.message,
    })),
  )),
);
export const addFollowEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(addFollowReq)),
  mergeMap((action) => from(campusXcelService.addFollowService(action.payload)).pipe(
    map((response: FollowResults) => updateFollowReq(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'Add Following',
      errorMessage: error.message,
    })),
  )),
);
export const addLikeEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { campusXcelService },
) => action$.pipe(
  filter(isActionOf(addLikeReq)),
  mergeMap((action) => from(campusXcelService.addLikeService(action.payload)).pipe(
    map((response: LikeResults) => updateLikeReq(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'Add Likes',
      errorMessage: error.message,
    })),
  )),
);
export const addCommentEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(addCommentReq)),
  mergeMap((action) => from(campusXcelService.addCommentService(action.payload)).pipe(
    map((response: CommentResults) => updateCommentReq(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'Add Comments',
      errorMessage: error.message,
    })),
  )),
);
export const getCommentListEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getCommentListReq)),
  mergeMap((action) => from(campusXcelService.commentListService(action.payload)).pipe(
    map((response: ListCommentResults) => updateCommentListReq(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'List Comment Data',
      errorMessage: error.message,
    })),
  )),
);
export const getUserLikesListEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getUserLikesListReq)),
  mergeMap((action) => from(campusXcelService.userLikesListService(action.payload)).pipe(
    map((response: ListUserLikesResults) => updateUserLikesListReq(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'List User Likes Data',
      errorMessage: error.message,
    })),
  )),
);
export const batchEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { campusXcelService },
) => action$.pipe(
  filter(isActionOf(batchReq)),
  mergeMap((action) => from(campusXcelService.batchService(action.payload)).pipe(
    map((response: BatchAddResults) => updateBatchReq(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'Batch Update',
      errorMessage: error.message,
    })),
  )),
);
export const getBatchListEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getBatchListReq)),
  mergeMap((action) => from(campusXcelService.batchListService(action.payload)).pipe(
    map((response: ListBatchResults) => updateBatchListReq(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'List Batch Data',
      errorMessage: error.message,
    })),
  )),
);
export const candUpdtEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { campusXcelService },
) => action$.pipe(
  filter(isActionOf(candStsReq)),
  mergeMap((action) => from(campusXcelService.candUpdtStsService(action.payload)).pipe(
    map((response: CandPutStsResults) => updateCandStsReq(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'Candidate Update',
      errorMessage: error.message,
    })),
  )),
);
export const getCandidatesListEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getCandidatesListReq)),
  mergeMap((action) => from(campusXcelService.candidatesListService(action.payload)).pipe(
    map((response: ListCandidatesResults) => updateCandidatesListReq(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'List Pending Candidates Data',
      errorMessage: error.message,
    })),
  )),
);
export const registerEmailMNTREpic: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(registerEmailMNTRReq)),
  mergeMap((action) => from(campusXcelService.registerEmailMNTRSService(action.payload)).pipe(
    map((response: RegisterEmailMNTRSResults) => updateRegisterEmailMNTRReq(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'Register Email Mentors',
      errorMessage: error.message,
    })),
  )),
);
export const registerPrsnlMNTREpic: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(registerPrsnlMNTRReq)),
  mergeMap((action) => from(campusXcelService.registerPrsnlMNTRSService(action.payload)).pipe(
    map((response: RegisterPrsnlMNTRSResults) => updateRegisterPrsnlMNTRReq(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'Register Personal Mentors',
      errorMessage: error.message,
    })),
  )),
);
export const registerAbtMeMNTREpic: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(registerAbtMeMNTRReq)),
  mergeMap((action) => from(campusXcelService.registerAbtMeMNTRSService(action.payload)).pipe(
    map((response: RegisterAbtMeMNTRSResults) => updateRegisterAbtMeMNTRReq(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'Register About Me Mentors',
      errorMessage: error.message,
    })),
  )),
);
export const registerEmployMNTREpic: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(registerEmployMNTRReq)),
  mergeMap((action) => from(campusXcelService.registerEmployMNTRSService(action.payload)).pipe(
    map((response: RegisterEmployMNTRSResults) => updateRegisterEmployMNTRReq(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'Register Employment Details Mentors',
      errorMessage: error.message,
    })),
  )),
);
export const registerXcelianEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(registerXCLNReq)),
  mergeMap((action) => from(campusXcelService.registerXCLNService(action.payload)).pipe(
    map((response: RegisterXCLNResults) => updateRegisterXCLNPostReq(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'Register Xcelian',
      errorMessage: error.message,
    })),
  )),
);

export const clientAddEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { campusXcelService },
) => action$.pipe(
  filter(isActionOf(addClientReq)),
  mergeMap((action) => from(campusXcelService.addClientService(action.payload)).pipe(
    map((response: ClientAddResults) => updateAddClientReq(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'Add Client',
      errorMessage: error.message,
    })),
  )),
);

export const getMentorEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getMentorStatusListReq)),
  mergeMap((action) => from(campusXcelService.mentorListService(action.payload)).pipe(
    map((response: ListMentorResults) => updateMentorStatusListReq(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'List Mentor Data',
      errorMessage: error.message,
    })),
  )),
);

export const updateMentorAccessEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getMentorAccess)),
  mergeMap((action) => from(campusXcelService.mentorAccessService(action.payload)).pipe(
    map((response: MentorAccessUpdateResults) => updateMentorAccess(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'Mentor Access Update',
      errorMessage: error.message,
    })),
  )),
);

export const updateS3CxMetaEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(updateS3CxMetaReq)),
  mergeMap((action) => from(campusXcelService.updateS3CxMetaService(action.payload)).pipe(
    map((response: UpdateS3CxMetaResults) => updateUpdateS3CxMetaReq(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'Update S3 Meta Data',
      errorMessage: error.message,
    })),
  )),
);
export const getS3CxMetaEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getS3CxMetaReq)),
  mergeMap((action) => from(campusXcelService.getS3CxMetaService(action.payload)).pipe(
    map((response: GetS3CxMetaResults) => getUpdateS3CxMetaReq(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'Get S3 Meta Data',
      errorMessage: error.message,
    })),
  )),
);

export const getClientUserListEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getClientUserListReq)),
  mergeMap((action) => from(campusXcelService.clientUserListService(action.payload)).pipe(
    map((response: ListClientUserResults) => updateClientUserListReq(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'Client User Meta',
      errorMessage: error.message,
    })),
  )),
);
export const clientUserAddEpic: Epic<RootAction, RootAction, RootState, Services> = (
  action$,
  state$,
  { campusXcelService },
) => action$.pipe(
  filter(isActionOf(addClientUserReq)),
  mergeMap((action) => from(campusXcelService.addClientUserService(action.payload)).pipe(
    map((response: ClientUserAddResults) => updateAddClientUserReq(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'Add Client User',
      errorMessage: error.message,
    })),
  )),
);
export const delClientEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(delClientReq)),
  mergeMap((action) => from(campusXcelService.delClientService(action.payload)).pipe(
    map((response : DELClientResults) => updateDelClientReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Delete Client', errorMessage: error.message })),
  )),
);

export const getResumeVideoEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getResumeVideo)),
  mergeMap((action) => from(campusXcelService.getResumeVideoService(action.payload)).pipe(
    map((response: ResumeVideoResults) => updateResumeVideo(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'Get Video Data',
      errorMessage: error.message,
    })),
  )),
);
export const updateVideoEpic: Epic<
  RootAction,
  RootAction,
  RootState,
  Services
> = (action$, state$, { campusXcelService }) => action$.pipe(
  filter(isActionOf(getVideo)),
  mergeMap((action) => from(campusXcelService.videoService(action.payload)).pipe(
    map((response: VideoResults) => updateVideo(response)),
    catchError(async (error: Error) => apiError({
      failed: true,
      backend: 'Update Video',
      errorMessage: error.message,
    })),
  )),
);
export const campusXcelEpics = combineEpics(
  contactUsPOSTEpic,
  getClientListEpic,
  registerCandidateEpic,
  generateTokenEpic,
  getProfileEpic,
  getForgotPasswordEpic,
  getFileURLEpic,
  userProfilePutEpic,
  uploadS3FilesEpic,
  getS3FilesEpic,
  passKeyEpic,
  getResumeProfileEpic,
  getResumeObjEpic,
  updateObjectiveEpic,
  updateOrgProjEpic,
  jobsByIdEpic,
  listSVDPJobsEpic,
  listAPLPJobsEpic,
  listPJobsEpic,
  listJobsEpic,
  updateJobStatusEpic,
  listOrgProjEpic,
  deleteOrgProjEpic,
  getResumePreviewEpic,
  // userProfileVideoEpic,
  // getVideURLEpic,
  updateHobbiesEpic,
  getHobbiesEpic,
  getCertEpic,
  updateCertEpic,
  updatePostPublishEpic,
  delPostPublishEpic,
  getPostListEpic,
  addPostPublishDBEpic,
  addCommentEpic,
  addLikeEpic,
  addFollowEpic,
  getCommentListEpic,
  getUserLikesListEpic,
  batchEpic,
  getBatchListEpic,
  candUpdtEpic,
  getCandidatesListEpic,
  registerEmailMNTREpic,
  registerPrsnlMNTREpic,
  registerAbtMeMNTREpic,
  registerEmployMNTREpic,
  registerXcelianEpic,
  clientAddEpic,
  getMentorEpic,
  updateMentorAccessEpic,
  updateS3CxMetaEpic,
  getS3CxMetaEpic,
  clientUserAddEpic,
  getClientUserListEpic,
  delClientEpic,
  getResumeVideoEpic,
  updateVideoEpic,
);
