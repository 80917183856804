import { ActionType, getType, Reducer } from 'typesafe-actions';
import {
  clearAddClientReq,
  clearAddClientUserReq,
  clearBatchReq,
  clearCandidatesListReq,
  clearCandStsReq,
  clearCert,
  clearCommentListReq,
  clearCommentReq,
  clearContactUsPostReq,
  clearDelClientReq,
  clearFollowReq,
  clearGetClientReq,
  clearGetClientUserReq,
  clearGetS3CxMetaReq,
  clearGetS3FilesReq,
  clearHobbies,
  clearJobPositonById,
  clearJobStatusReq,
  clearLikeReq,
  clearLoginToken,
  clearMentorAccess,
  clearMentorStatusListReq,
  clearObjective,
  clearOrgProject,
  clearPassKeyReq,
  clearPostDBReq,
  clearPostListReq,
  clearPostPublish,
  clearProfileReq,
  clearRegisterAbtMeMNTRReq,
  clearRegisterCMPSPostReq,
  clearRegisterEmailMNTRReq,
  clearRegisterEmployMNTRReq,
  clearRegisterPrsnlMNTRReq,
  clearRegisterXCLNPostReq,
  clearResultCert,
  clearResultHobbies,
  clearResumeDataObj,
  clearResumeProfile,
  clearResumeVideo,
  clearUpdateS3CxMetaReq,
  clearUploadS3FilesReq,
  clearUserLikesListReq,
  clearUserProfilePutReq,
  clearVideo,
  getUpdateS3CxMetaReq,
  updateAddClientReq,
  updateAddClientUserReq,
  updateAllJobs,
  updateAllPJobs,
  updateAPLPJobs,
  updateBatchListReq,
  updateBatchReq,
  updateCandidatesListReq,
  updateCandStsReq,
  updateCert,
  updateClientListReq,
  updateClientUserListReq,
  updateCommentListReq,
  updateCommentReq,
  updateContactUsPostReq,
  updateData,
  updateDelClientReq,
  updateDelOrgProjReq,
  updateDelPostPublish,
  updateFileURLReq,
  updateFollowReq,
  updateForgotPassword,
  updateGetS3FilesReq,
  updateHobbies,
  updateJobPositonById,
  updateJobStatusReq,
  updateLikeReq,
  updateListOrgProj,
  updateMentorAccess,
  updateMentorStatusListReq,
  updateObjective,
  updateOrgProject,
  updatePassKeyReq,
  updatePostDBReq,
  updatePostListReq,
  updatePostPublish,
  updatePreviewResume,
  updateProfile,
  updateRegisterAbtMeMNTRReq,
  updateRegisterCMPSPostReq,
  updateRegisterEmailMNTRReq,
  updateRegisterEmployMNTRReq,
  updateRegisterPrsnlMNTRReq,
  updateRegisterXCLNPostReq,
  updateResultCert,
  updateResultHobbies,
  updateResumeDataObj,
  updateResumeProfile,
  updateResumeVideo,
  updateSVDPJobs,
  updateToken,
  updateUpdateS3CxMetaReq,
  updateUploadS3FilesReq,
  updateUserLikesListReq,
  updateUserProfilePutReq,
  updateVideo,
} from './actions';
import * as actions from './actions';
import { CampusXcelAppState } from './action.types';

export const initialState: CampusXcelAppState = {
  data: {
    show: '',
  },
  contactUs: {
    message: '',
    errorMessage: '',
    error: false,
  },
  registerUser: {
    message: '',
    data: '',
    error: false,
  },
  getClientList: {
    data: {
      tCnt: 0,
      fCnt: 0,
      clntData: [],
      lastEvalKey: {
        ClntTp: '',
        ClntId: '',
      },
    },
    isError: false,
    message: '',
  },
  genToken: {
    token: '',
    message: '',
    isError: false,
  },
  profileData: {
    isError: false,
    message: '',
    profileData: {
      fNme: '',
      lNme: '',
      ph: '',
      eId: '',
      avtr: '',
      gnd: '',
      dlCd: '',
      UserCd: '',
      UserTp: '',
      isPKReset: false,
      lgnSts: '',
      isAdm: false,
      cmpsTp: '',
      cmpsCd: '',
      cmpsNme: '',
      cmpsCrs: {
        crs: '',
        strm: '',
        sDte: '',
        eDte: '',
      },
    },
  },
  forgotPassKeyData: {
    forgotPasswordData: '',
    message: '',
    isError: false,
  },
  updateFileURL: {
    url: '',
    isError: false,
    message: '',
  },
  resumeProfileData: {
    perData: {
      currLoc: '',
      cEId: '',
      diffAbl: false,
      cnty: '',
      diffAblRsn: '',
      addr: '',
      pin: '',
      cty: '',
      st: '',
      langKnwn: [],
      isPrefPAN: false,
      dOB: '',
      dlCd: '',
      cPh: '',
      lNme: '',
      fNme: '',
      prefLoc: [],
    },
    isError: false,
    message: '',
  },
  updateUserProfile: {
    message: '',
    errorMessage: '',
    error: false,
  },
  updatePassKey: {
    message: '',
    error: false,
  },
  uploadS3Files: {
    fileName: '',
  },
  updateS3CxMeta: {
    success: false,
    message: '',
  },
  getS3CxMeta: {
    success: false,
    data: [],
    message: '',
  },
  getS3Files: {
    data: [],
    identifierTp: '',
  },
  getObjectiveResume: {
    obj: '',
    message: '',
    isError: false,
  },
  updateObjectiveResume: {
    message: '',
    error: false,
  },
  getVideoResume: {
    video: '',
    message: '',
    isError: false,
  },
  updateVideoResume: {
    message: '',
    error: false,
  },
  updateOrgProj: {
    message: '',
    error: false,
    errorMessage: '',
  },
  listOrgProj: {
    orgProj: [],
    isError: false,
    message: '',
  },
  listJobs: {
    tCnt: 0,
    fCnt: 0,
    jobsResult: [],
    isError: false,
    jobMessage: '',
  },
  listALLJobs: {
    tCnt: 0,
    fCnt: 0,
    jobsResult: [],
    isError: false,
    jobMessage: '',
  },
  listAPLJobs: {
    tCnt: 0,
    fCnt: 0,
    jobsResult: [],
    isError: false,
    jobMessage: '',
  },
  listSVDJobs: {
    tCnt: 0,
    fCnt: 0,
    jobsResult: [],
    isError: false,
    jobMessage: '',
  },
  jobPositionById: {
    isError: false,
    message: '',
    jobData: {
      JobId: '',
      sDte: '',
      eduTp: [],
      isAbld: false,
      isPub: false,
      mjrSkls: [
        {
          nme: '',
          yr: 0,
        },
      ],
      JobCd: '',
      pref: '',
      nme: '',
      logoUrl: '',
      jbTp: [],
      isFml: false,
      eDte: '',
      minSal: '',
      minExp: 0,
      maxExp: 0,
      maxSal: '',
      dep: '',
      hrTp: '',
      isEdu: false,
      useLogo: false,
      isHirClg: false,
      loc: [],
      orgNme: {
        nme: '',
        loc: [],
        web: '',
        abt: '',
      },
      rr: [],
      abtProj: [],
      mnrSkls: [],
    },
  },
  updateJobStatus: {
    message: '',
    error: false,
    errorMessage: '',
  },
  deleteOrgProj: {
    message: '',
    error: false,
    errorMessage: '',
  },
  previewResume: {
    allData: {
      currLoc: '',
      cEId: '',
      btCd: '',
      addr: '',
      diffAbl: false,
      cnty: '',
      langKnwn: [],
      updtOn: '',
      orgProj: [],
      CandCd: '',
      diffAblRsn: '',
      isPrefPAN: false,
      obj: '',
      CandId: '',
      dOB: '',
      dlCd: '',
      cPh: '',
      cPhTp: '',
      lNme: '',
      fNme: '',
      prefLoc: [],
      edu: {
        acad: '',
        strm: '',
        crsTp: '',
        sDte: '',
        eDte: '',
      },
    },
    isError: false,
    message: '',
  },
  // updateProfileVideo: {
  //   message: '',
  //   error: false,
  // },
  // updateVideoURL: {
  //   url: '',
  //   isError: false,
  //   message: '',
  // },
  updateHobbies: {
    message: '',
    error: false,
  },
  getHobbies: {
    hobb: [],
    message: '',
    isError: false,
  },
  updateCert: {
    message: '',
    error: false,
  },
  getCert: {
    cert: [],
    message: '',
    isError: false,
  },
  createPostData: {
    fileName: '',
  },
  getPostList: {
    tCnt: 0,
    fCnt: 0,
    postData: [],
    isError: false,
    message: '',
  },
  deletePostDataS3: {
    fileName: '',
  },
  addPostDBData: {
    message: '',
    error: false,
  },
  addFollowData: {
    message: '',
    error: false,
  },
  addCommentData: {
    message: '',
    error: false,
  },
  addLikeData: {
    message: '',
    error: false,
  },
  getCommentList: {
    data: [],
    isError: false,
    message: '',
  },
  getUserLikesList: {
    tCnt: 0,
    fCnt: 0,
    likeUserData: [],
    isError: false,
    message: '',
  },
  updateBatch: {
    message: '',
    error: false,
    data: '',
  },
  getBatchList: {
    data: {
      tCnt: 0,
      fCnt: 0,
      batchData: [
        {
          sDte: '',
          eDte: '',
          acad: '',
          bNme: '',
          crsTp: '',
          BatchCd: '',
          strm: '',
          sNme: '',
        },
      ],
      lastEvalKey: {
        AcadCd: '',
        BatchCd: '',
      },
    },
    isError: false,
    message: '',
  },
  updateCandStatus: {
    message: '',
    error: false,
    data: '',
  },
  getCandList: {
    data: {
      tCnt: 0,
      fCnt: 0,
      candListData: [],
      lastEvalKey: {
        UserTp: '',
        UserCd: '',
      },
    },
    isError: false,
    message: '',
  },
  registerEmailMentor: {
    message: '',
    id: 0,
    error: false,
    errorMessage: '',
  },
  registerPrsnlMentor: {
    message: '',
    id: 0,
    error: false,
    errorMessage: '',
  },
  registerAbtMeMentor: {
    message: '',
    id: 0,
    error: false,
    errorMessage: '',
  },
  registerEmployMentor: {
    message: '',
    id: 0,
    error: false,
    errorMessage: '',
    data: '',
  },
  registerXcelian: {
    message: '',
    data: '',
    error: false,
  },
  addClient: {
    message: '',
    error: false,
    data: '',
  },
  getMentorList: {
    tCnt: 0,
    fCnt: 0,
    mntrData: [],
    isError: false,
    message: '',
  },
  updateMentorAccess: {
    message: '',
    error: false,
    data: '',
  },
  addClientUser: {
    message: '',
    error: false,
    data: '',
  },
  getClientUserList: {
    data: {
      tCnt: 0,
      fCnt: 0,
      usrData: [],
      lastEvalKey: {
        UserTp: '',
        UserCd: '',
      },
    },
    isError: false,
    message: '',
  },
  delClient: {
    message: '',
    error: false,
  },
};
export type AllAction = ActionType<typeof actions>;
const campusXcelReducer: Reducer<CampusXcelAppState, AllAction> = (
  state: CampusXcelAppState = initialState,
  action: AllAction,
): CampusXcelAppState => {
  switch (action.type) {
    case getType(updateData):
      return {
        ...state,
        data: {
          show: action.payload,
        },
      };
    case getType(updateContactUsPostReq):
      return {
        ...state,
        contactUs: {
          message: action.payload.message,
          errorMessage: action.payload.errorMessage,
          error: action.payload.error,
        },
      };
    case getType(clearContactUsPostReq):
      return {
        ...state,
        contactUs: initialState.contactUs,
      };
    case getType(updateClientListReq):
      return {
        ...state,
        getClientList: {
          isError: action.payload.isError,
          data: action.payload.data,
          message: action.payload.message,
        },
      };
    case getType(clearGetClientReq):
      return {
        ...state,
        getClientList: initialState.getClientList,
      };
    case getType(updateClientUserListReq):
      return {
        ...state,
        getClientUserList: {
          isError: action.payload.isError,
          data: action.payload.data,
          message: action.payload.message,
        },
      };
    case getType(clearGetClientUserReq):
      return {
        ...state,
        getClientUserList: initialState.getClientUserList,
      };
    case getType(updateAddClientUserReq):
      return {
        ...state,
        addClientUser: {
          message: action.payload.message,
          error: action.payload.error,
          data: action.payload.data,
        },
      };
    case getType(clearAddClientUserReq):
      return {
        ...state,
        addClientUser: initialState.addClientUser,
      };

    case getType(updateRegisterCMPSPostReq):
      return {
        ...state,
        registerUser: {
          message: action.payload.message,
          data: action.payload.data,
          error: action.payload.error,
        },
      };
    case getType(clearRegisterCMPSPostReq):
      return {
        ...state,
        registerUser: initialState.registerUser,
      };
    case getType(updateToken):
      return {
        ...state,
        genToken: {
          token: action.payload.token,
          isError: action.payload.isError,
          message: action.payload.message,
        },
      };
    case getType(clearLoginToken):
      return {
        ...state,
        genToken: initialState.genToken,
      };
    case getType(updateProfile):
      return {
        ...state,
        profileData: {
          isError: action.payload.isError,
          message: action.payload.message,
          profileData: {
            fNme: action.payload.profileData.fNme,
            lNme: action.payload.profileData.lNme,
            ph: action.payload.profileData.ph,
            eId: action.payload.profileData.eId,
            UserCd: action.payload.profileData.UserCd,
            UserTp: action.payload.profileData.UserTp,
            avtr: action.payload.profileData.avtr,
            gnd: action.payload.profileData.gnd,
            dlCd: action.payload.profileData.dlCd,
            isPKReset: action.payload.profileData.isPKReset,
            lgnSts: action.payload.profileData.lgnSts,
            isAdm: action.payload.profileData.isAdm,
            cmpsTp: action.payload.profileData.cmpsTp,
            cmpsCd: action.payload.profileData.cmpsCd,
            cmpsNme: action.payload.profileData.cmpsNme,
            cmpsCrs: action.payload.profileData.cmpsCrs,
          },
        },
      };
    case getType(clearProfileReq):
      return {
        ...state,
        profileData: initialState.profileData,
      };
    case getType(updateForgotPassword):
      return {
        ...state,
        forgotPassKeyData: {
          forgotPasswordData: action.payload.forgotPasswordData,
          message: action.payload.message,
          isError: action.payload.isError,
        },
      };
    case getType(updateFileURLReq):
      return {
        ...state,
        updateFileURL: {
          url: action.payload.url,
          isError: action.payload.isError,
          message: action.payload.message,
        },
      };
    case getType(updateResumeProfile):
      return {
        ...state,
        resumeProfileData: {
          perData: action.payload.perData,
          isError: action.payload.isError,
          message: action.payload.message,
        },
      };
    case getType(clearResumeProfile):
      return {
        ...state,
        resumeProfileData: initialState.resumeProfileData,
      };
    case getType(updateUserProfilePutReq):
      return {
        ...state,
        updateUserProfile: {
          message: action.payload.message,
          errorMessage: action.payload.errorMessage,
          error: action.payload.error,
        },
      };
    case getType(clearUserProfilePutReq):
      return {
        ...state,
        updateUserProfile: initialState.updateUserProfile,
      };
    case getType(updatePassKeyReq):
      return {
        ...state,
        updatePassKey: {
          message: action.payload.message,
          error: action.payload.error,
        },
      };
    case getType(clearPassKeyReq):
      return {
        ...state,
        updateFileURL: initialState.updateFileURL,
      };
    case getType(updateUploadS3FilesReq):
      return {
        ...state,
        uploadS3Files: {
          fileName: action.payload.fileName,
        },
      };
    case getType(clearUploadS3FilesReq):
      return {
        ...state,
        uploadS3Files: initialState.uploadS3Files,
      };
    case getType(updateUpdateS3CxMetaReq):
      return {
        ...state,
        updateS3CxMeta: {
          message: action.payload.message,
          success: action.payload.success,
        },
      };
    case getType(clearUpdateS3CxMetaReq):
      return {
        ...state,
        updateS3CxMeta: initialState.updateS3CxMeta,
      };
    case getType(getUpdateS3CxMetaReq):
      return {
        ...state,
        getS3CxMeta: {
          data: action.payload.data,
          success: action.payload.success,
          message: action.payload.message,
        },
      };
    case getType(clearGetS3CxMetaReq):
      return {
        ...state,
        getS3CxMeta: initialState.getS3CxMeta,
      };
    case getType(updateGetS3FilesReq):
      return {
        ...state,
        getS3Files: {
          data: action.payload.data,
          identifierTp: action.payload.identifierTp,
        },
      };
    case getType(clearGetS3FilesReq):
      return {
        ...state,
        data: initialState.data,
      };
    // case getType(updateUploadVideoReq):
    //   return {
    //     ...state,
    //     updateProfileVideo: {
    //       message: action.payload.message,
    //       error: action.payload.error,
    //     },
    //   };
    // case getType(clearUploadVideoReq):
    //   return {
    //     ...state,
    //     updateProfileVideo: initialState.updateProfileVideo,
    //   };
    case getType(updateObjective):
      return {
        ...state,
        updateObjectiveResume: {
          message: action.payload.message,
          error: action.payload.error,
        },
      };
    case getType(clearObjective):
      return {
        ...state,
        updateObjectiveResume: initialState.updateObjectiveResume,
      };
    case getType(updateResumeDataObj):
      return {
        ...state,
        getObjectiveResume: {
          obj: action.payload.obj,
          isError: action.payload.isError,
          message: action.payload.message,
        },
      };
    case getType(clearResumeDataObj):
      return {
        ...state,
        getObjectiveResume: initialState.getObjectiveResume,
      };
    case getType(updateResumeVideo):
      return {
        ...state,
        getVideoResume: {
          video: action.payload.video,
          isError: action.payload.isError,
          message: action.payload.message,
        },
      };
    case getType(clearResumeVideo):
      return {
        ...state,
        getVideoResume: initialState.getVideoResume,
      };
    case getType(updateVideo):
      return {
        ...state,
        updateVideoResume: {
          message: action.payload.message,
          error: action.payload.error,
        },
      };
    case getType(clearVideo):
      return {
        ...state,
        updateVideoResume: initialState.updateVideoResume,
      };
    case getType(updateAllJobs):
      return {
        ...state,
        listJobs: {
          tCnt: action.payload.tCnt,
          fCnt: action.payload.fCnt,
          jobsResult: action.payload.jobsResult,
          isError: action.payload.isError,
          jobMessage: action.payload.jobMessage,
        },
      };
    case getType(updateAllPJobs):
      return {
        ...state,
        listALLJobs: {
          tCnt: action.payload.tCnt,
          fCnt: action.payload.fCnt,
          jobsResult: action.payload.jobsResult,
          isError: action.payload.isError,
          jobMessage: 'personal all job executed',
        },
      };
    case getType(updateAPLPJobs):
      return {
        ...state,
        listAPLJobs: {
          tCnt: action.payload.tCnt,
          fCnt: action.payload.fCnt,
          jobsResult: action.payload.jobsResult,
          isError: action.payload.isError,
          jobMessage: 'personal apl job executed',
        },
      };
    case getType(updateSVDPJobs):
      return {
        ...state,
        listSVDJobs: {
          tCnt: action.payload.tCnt,
          fCnt: action.payload.fCnt,
          jobsResult: action.payload.jobsResult,
          isError: action.payload.isError,
          jobMessage: 'personal svd job executed',
        },
      };
    case getType(updateJobPositonById):
      return {
        ...state,
        jobPositionById: {
          jobData: action.payload.jobData,
          isError: action.payload.isError,
          message: action.payload.message,
        },
      };
    case getType(clearJobPositonById):
      return {
        ...state,
        jobPositionById: {
          jobData: initialState.jobPositionById.jobData,
          isError: initialState.jobPositionById.isError,
          message: initialState.jobPositionById.message,
        },
      };
    case getType(updateOrgProject):
      return {
        ...state,
        updateOrgProj: {
          message: action.payload.message,
          errorMessage: action.payload.errorMessage,
          error: action.payload.error,
        },
      };
    case getType(clearOrgProject):
      return {
        ...state,
        updateOrgProj: initialState.updateOrgProj,
      };
    case getType(updateJobStatusReq):
      return {
        ...state,
        updateJobStatus: {
          message: action.payload.message,
          errorMessage: action.payload.errorMessage,
          error: action.payload.error,
        },
      };
    case getType(clearJobStatusReq):
      return {
        ...state,
        updateJobStatus: initialState.updateJobStatus,
      };
    case getType(updateListOrgProj):
      return {
        ...state,
        listOrgProj: {
          isError: action.payload.isError,
          message: action.payload.message,
          orgProj: action.payload.orgProj,
        },
      };
    case getType(updateDelOrgProjReq):
      return {
        ...state,
        deleteOrgProj: {
          message: action.payload.message,
          errorMessage: action.payload.errorMessage,
          error: action.payload.error,
        },
      };
    case getType(updatePreviewResume):
      return {
        ...state,
        previewResume: {
          allData: action.payload.allData,
          isError: action.payload.isError,
          message: action.payload.message,
        },
      };
    // case getType(updateVideoURLReq):
    //   return {
    //     ...state,
    //     updateVideoURL: {
    //       url: action.payload.url,
    //       isError: action.payload.isError,
    //       message: action.payload.message,
    //     },
    //   };
    // case getType(clearVideoURLReq):
    //   return {
    //     ...state,
    //     updateVideoURL: initialState.updateVideoURL,
    //   };
    case getType(updateHobbies):
      return {
        ...state,
        updateHobbies: {
          message: action.payload.message,
          error: action.payload.error,
        },
      };
    case getType(clearHobbies):
      return {
        ...state,
        updateHobbies: initialState.updateHobbies,
      };
    case getType(updateResultHobbies):
      return {
        ...state,
        getHobbies: {
          hobb: action.payload.hobb,
          isError: action.payload.isError,
          message: action.payload.message,
        },
      };
    case getType(clearResultHobbies):
      return {
        ...state,
        getHobbies: initialState.getHobbies,
      };
    case getType(updateCert):
      return {
        ...state,
        updateCert: {
          message: action.payload.message,
          error: action.payload.error,
        },
      };
    case getType(clearCert):
      return {
        ...state,
        updateCert: initialState.updateCert,
      };
    case getType(updateResultCert):
      return {
        ...state,
        getCert: {
          cert: action.payload.cert,
          isError: action.payload.isError,
          message: action.payload.message,
        },
      };
    case getType(clearResultCert):
      return {
        ...state,
        getCert: initialState.getCert,
      };
    case getType(updatePostPublish):
      return {
        ...state,
        createPostData: {
          fileName: action.payload.fileName,
        },
      };
    case getType(clearPostPublish):
      return {
        ...state,
        createPostData: initialState.createPostData,
      };
    case getType(updatePostListReq):
      return {
        ...state,
        getPostList: {
          isError: action.payload.isError,
          tCnt: action.payload.tCnt,
          fCnt: action.payload.fCnt,
          postData: action.payload.postData,
          message: action.payload.message,
        },
      };
    case getType(clearPostListReq):
      return {
        ...state,
        getPostList: initialState.getPostList,
      };
    case getType(updateDelPostPublish):
      return {
        ...state,
        deletePostDataS3: {
          fileName: action.payload.fileName,
        },
      };
    case getType(updatePostDBReq):
      return {
        ...state,
        addPostDBData: {
          error: action.payload.error,
          message: action.payload.message,
        },
      };
    case getType(clearPostDBReq):
      return {
        ...state,
        addPostDBData: initialState.addPostDBData,
      };
    case getType(updateLikeReq):
      return {
        ...state,
        addLikeData: {
          error: action.payload.error,
          message: action.payload.message,
        },
      };
    case getType(clearLikeReq):
      return {
        ...state,
        addLikeData: initialState.addLikeData,
      };
    case getType(updateCommentReq):
      return {
        ...state,
        addCommentData: {
          error: action.payload.error,
          message: action.payload.message,
        },
      };
    case getType(clearCommentReq):
      return {
        ...state,
        addCommentData: initialState.addCommentData,
      };
    case getType(updateFollowReq):
      return {
        ...state,
        addFollowData: {
          error: action.payload.error,
          message: action.payload.message,
        },
      };
    case getType(clearFollowReq):
      return {
        ...state,
        addFollowData: initialState.addFollowData,
      };
    case getType(updateCommentListReq):
      return {
        ...state,
        getCommentList: {
          isError: action.payload.isError,
          data: action.payload.data,
          message: action.payload.message,
        },
      };
    case getType(clearCommentListReq):
      return {
        ...state,
        getCommentList: initialState.getCommentList,
      };
    case getType(updateUserLikesListReq):
      return {
        ...state,
        getUserLikesList: {
          isError: action.payload.isError,
          tCnt: action.payload.tCnt,
          fCnt: action.payload.fCnt,
          likeUserData: action.payload.likeUserData,
          message: action.payload.message,
        },
      };
    case getType(clearUserLikesListReq):
      return {
        ...state,
        getUserLikesList: initialState.getUserLikesList,
      };
    case getType(updateBatchReq):
      return {
        ...state,
        updateBatch: {
          message: action.payload.message,
          error: action.payload.error,
          data: action.payload.data,
        },
      };
    case getType(clearBatchReq):
      return {
        ...state,
        updateBatch: initialState.updateBatch,
      };
    case getType(updateBatchListReq):
      return {
        ...state,
        getBatchList: {
          isError: action.payload.isError,
          message: action.payload.message,
          data: action.payload.data,
        },
      };
    case getType(updateCandStsReq):
      return {
        ...state,
        updateCandStatus: {
          message: action.payload.message,
          error: action.payload.error,
          data: action.payload.data,
        },
      };
    case getType(clearCandStsReq):
      return {
        ...state,
        updateCandStatus: initialState.updateCandStatus,
      };
    case getType(updateCandidatesListReq):
      return {
        ...state,
        getCandList: {
          isError: action.payload.isError,
          message: action.payload.message,
          data: action.payload.data,
        },
      };
    case getType(clearCandidatesListReq):
      return {
        ...state,
        getCandList: initialState.getCandList,
      };
    case getType(updateRegisterEmailMNTRReq):
      return {
        ...state,
        registerEmailMentor: {
          message: action.payload.message,
          id: action.payload.id,
          error: action.payload.error,
          errorMessage: action.payload.errorMessage,
        },
      };
    case getType(clearRegisterEmailMNTRReq):
      return {
        ...state,
        registerEmailMentor: initialState.registerEmailMentor,
      };
    case getType(updateRegisterPrsnlMNTRReq):
      return {
        ...state,
        registerPrsnlMentor: {
          message: action.payload.message,
          id: action.payload.id,
          error: action.payload.error,
          errorMessage: action.payload.errorMessage,
        },
      };
    case getType(clearRegisterPrsnlMNTRReq):
      return {
        ...state,
        registerPrsnlMentor: initialState.registerPrsnlMentor,
      };
    case getType(updateRegisterAbtMeMNTRReq):
      return {
        ...state,
        registerAbtMeMentor: {
          message: action.payload.message,
          id: action.payload.id,
          error: action.payload.error,
          errorMessage: action.payload.errorMessage,
        },
      };
    case getType(clearRegisterAbtMeMNTRReq):
      return {
        ...state,
        registerAbtMeMentor: initialState.registerAbtMeMentor,
      };
    case getType(updateRegisterEmployMNTRReq):
      return {
        ...state,
        registerEmployMentor: {
          message: action.payload.message,
          id: action.payload.id,
          error: action.payload.error,
          errorMessage: action.payload.errorMessage,
          data: action.payload.data,
        },
      };
    case getType(clearRegisterEmployMNTRReq):
      return {
        ...state,
        registerEmployMentor: initialState.registerEmployMentor,
      };
    case getType(updateRegisterXCLNPostReq):
      return {
        ...state,
        registerXcelian: {
          message: action.payload.message,
          data: action.payload.data,
          error: action.payload.error,
        },
      };
    case getType(clearRegisterXCLNPostReq):
      return {
        ...state,
        registerXcelian: initialState.registerXcelian,
      };
    case getType(updateAddClientReq):
      return {
        ...state,
        addClient: {
          message: action.payload.message,
          error: action.payload.error,
          data: action.payload.data,
        },
      };
    case getType(clearAddClientReq):
      return {
        ...state,
        addClient: initialState.addClient,
      };
    case getType(updateMentorStatusListReq):
      return {
        ...state,
        getMentorList: {
          isError: action.payload.isError,
          tCnt: action.payload.tCnt,
          fCnt: action.payload.fCnt,
          mntrData: action.payload.mntrData,
          message: action.payload.message,
        },
      };
    case getType(clearMentorStatusListReq):
      return {
        ...state,
        getMentorList: {
          isError: false,
          tCnt: 0,
          fCnt: 0,
          mntrData: [],
          message: '',
        },
      };
    case getType(updateMentorAccess):
      return {
        ...state,
        updateMentorAccess: {
          message: action.payload.message,
          error: action.payload.error,
          data: action.payload.data,
        },
      };
    case getType(clearMentorAccess):
      return {
        ...state,
        updateMentorAccess: initialState.updateMentorAccess,
      };
    case getType(updateDelClientReq):
      return {
        ...state,
        delClient: {
          error: action.payload.error,
          message: action.payload.message,
        },
      };
    case getType(clearDelClientReq):
      return {
        ...state,
        delClient: initialState.delClient,
      };
    default:
      return state;
  }
};
export default campusXcelReducer;
