import React, { ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';
import PublicCXComponent from '../../scenes/public/cx.component';
import { CampusUserPrivateComponent } from '../../scenes/exclusive/campus-user.component';
import { CampusAdminPrivateComponent } from '../../scenes/exclusive/campus-admin.component';
import { CXUnauthorized } from '../../scenes/unauthorized/unauthorized.component';
import { SuperAdminPrivateComponent } from '../../scenes/exclusive/super-admin.component';
import { LoginSAdmin } from '../public/login/sadmin/login';

const AppBase: React.FC = (): ReactElement => (
  <Routes>
    <Route path="/*" element={<PublicCXComponent />} />
    <Route path="/campus/exclusive/*" element={<CampusUserPrivateComponent />} />
    <Route path="/cx/admin/*" element={<CampusAdminPrivateComponent />} />
    <Route path="/unauthorized" element={<CXUnauthorized />} />
    <Route path="/sadmin/login" element={<LoginSAdmin />} />
    <Route path="/wds/sadmin/*" element={<SuperAdminPrivateComponent />} />
    {/* <Route path="/training" element={<PublicCXTrainingComponent />} />
    <Route path="/register/xcelian" element={<RegisterXcelianComponent />} />
    <Route path="/xcelian/login" element={<LoginXcelianComponent />} />
    <Route path="/register/campus" element={<RegisterCampusComponent />} />
    <Route path="/login/campus" element={<LoginCampusComponent />} />
    <Route path="/jobs" element={<PublicCXJobsComponent />} /> */}
  </Routes>
);

export default AppBase;
