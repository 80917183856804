export enum ApiLIMIT{
    LIMIT = 30
}
export enum UserType {
  // NEW Start
  CMPS='CMPS',
  CXADM='CXADM',
  CXAPI='CXAPI',
  RCRT='RCRT',
  // NEW End
    CORP='CORP',
    XCLN='XCLN',
    MNTR='MNTR',
    COMMN='COMMN',
    PUBLIC='PUBLIC',
    CX='CX'
}
export enum APIHeader{
  // NEW API Params Start
    USER_LOGIN = 'user_login',
    REQ_GENERATE_OTP = 'generate_otp',
    REQ_VERIFY_OTP = 'verify_otp',
    REQ_USER_PROFILE_INFO = 'user_profile_info',
    REQ_FORGOT_PASSWORD = 'forgot_password',
    REQ_ADD_CONTACT_FORM = 'add_contact_form',
    REQ_ONBOARD_CLIENT = 'onboard_client',
    REQ_ALL_CLIENT = 'get_all_client',
    REQ_ONBOARD_CLIENT_USER = 'onboard_client_user',
    REQ_GET_CLIENT_USER = 'get_client_user',
    REQ_DELETE_CLIENT = 'delete_client',
    REQ_ADD_CMPS_CANDIDATE = 'add_campus_candidate',
    REQ_ALL_CAMPUS_CAND = 'get_all_campus_candidates',
    REQ_UPDATE_CANDIDATE_STATUS = 'update_candidate_status',
    REQ_GET_CMPS_CANDIDATE_ID = 'get_cmps_candidate_id',
    REQ_UPDATE_OBJECTIVE = 'update_objective',
    REQ_UPDATE_VIDEO = 'update_video',
    REQ_UPDATE_HOBBIES = 'update_hobbies',
    REQ_UPDATE_CERT = 'update_cert',
  // NEW API Params Start

    // Admin View
    // REQ_GET_ALL_ACAD_META_ADM = 'get_all_acad_meta_adm',
    // REQ_AGET_ALL_UNIV = 'get_all_univ_meta',
    // REQ_ADD_CMPS_USR = 'add_acad_user',
    // REQ_GET_ALL_MENTORS = 'get_all_mentors',
    // REQ_UPDATE_MENTORS_STATUS = 'update_mentor_status',
  // Non Admin View
    REQ_ADD_XCLN = 'register_xcelian',
    // REQ_ADD_MENTOR_EMAIL_VERIFY = 'add_cx_mentors_email_verify',
    // REQ_ADD_MENTOR_PRSNL_INFO = 'add_cx_mentors_persnl_info',
    // REQ_ADD_MENTOR_ABT_ME = 'add_cx_mentors_abt_me',
    // REQ_ADD_MENTOR_PROF_EXP = 'add_cx_mentors_prof_exp',
    // REQ_FORM_DATA = 'form_data',
    // REQ_IMG_DATA = 'image_data',
    // REQ_GET_ALL_ACAD_META = 'get_all_acad_meta',
    // REQ_GET_ALL_CNTY_META = 'get_all_cnty_meta',
    // REQ_FILE_URL = 'get_presigned_url',
    // REQ_GET_ALL_CITY_META ='get_all_city_s3',
    REQ_GET_CANDIDATE_ID = 'get_candidate_id',
    REQ_CANDIDATE_ABOUT_ME = 'update_candidate_about_me',
    REQ_UPDATE_PASSWORD = 'update_password',
    REQ_UPLOAD_FILES = 'upload_files',
    REQ_GET_ALL_ORG_META = 'get_all_org_meta',
    REQ_GET_ALL_SKILL_META = 'get_all_skill_meta',
    REQ_GET_ALL_INDUS_TP_META = 'get_all_indus_tp_meta',
    REQ_GET_ALL_CHILD_INDUS_TP_META = 'get_all_child_indus_tp_meta',
    REQ_CANDIDATE_ORG_PROJECT = 'update_candidate_org_projects',
    REQ_DELETE_ORG_PROJECT = 'delete_org_project',
    REQ_DELETE_CERT = 'delete_candidate_cert',
    REQ_CITY_BY_PIN='get_city_pin',
    REQ_GET_ALL_LANG_META='get_all_lang_s3',
    REQ_GET_ALL_POST='get_all_posts',
    REQ_ADD_POST_DB = 'add_post',
    REQ_ADD_LIKE = 'add_like',
    REQ_ADD_FOLLOWER = 'add_follower',
    REQ_ADD_COMMENTS = 'add_comments',
    REQ_GET_ALL_COMMENTS = 'get_all_comments',
    REQ_GET_ALL_USER_LIKES = 'get_all_liked_users',
    REQ_GET_ALL_STRM_META='get_all_strm_meta',
    REQ_ADD_BATCH = 'add_acad_batch',
    REQ_GET_ALL_ACAD_BATCH = 'get_all_acad_batch',
    REQ_GET_ALL_PENDING_CAND = 'get_all_pending_candidates',
    REQ_UPDATE_PENDING_CAND = 'update_pending_candidate',
}
export enum APIParam{
    DIALING_META = 'dialing_code_meta',
    DIFF_ABLED_META = 'diff_abled_meta',
    EXT_ORG_META = 'ext_org_meta',
    JOB_TITLE_META = 'job_title_meta',
    SKILLS_META = 'skills_meta',
    COLLEGE_META = 'college_meta',
    COURSE_META = 'course_meta',
    CITY_META = 'city_meta',
    AVATAR_IMAGE = 'AVATAR',
    VIDEO = 'VIDEO'
}

export const DiffAbledTypeInfo = [
  { label: 'Not Applicable', value: 'NONE' },
  { label: 'Blindness', value: 'BLD' },
  { label: 'Low-vision', value: 'LVS' },
  { label: 'Leprosy Cured Persons', value: 'LCP' },
  { label: 'Hearing Impairment (deaf and hard of hearing)', value: 'HIM' },
  { label: 'Locomotor Disability', value: 'LDT' },
  { label: 'Dwarfism', value: 'DWR' },
  { label: 'Intellectual Disability', value: 'IDT' },
  { label: 'Mental Illness', value: 'MIS' },
  { label: 'Autism Spectrum Disorder', value: 'ASD' },
  { label: 'Cerebral Palsy', value: 'CPE' },
  { label: 'Muscular Dystrophy', value: 'MDY' },
  { label: 'Chronic Neurological Conditions', value: 'CNC' },
  { label: 'Specific Learning Disabilities', value: 'SLD' },
  { label: 'Multiple Sclerosis', value: 'MSS' },
  { label: 'Speech and Language Disability', value: 'SDL' },
  { label: 'Thalassemia', value: 'THL' },
  { label: 'Hemophilia', value: 'HMA' },
  { label: 'Sickle Cell Disease', value: 'SCD' },
  { label: 'Multiple Disabilities Including Deaf Blindness', value: 'MDD' },
  { label: 'Acid Attack Victim', value: 'AAV' },
  { label: 'Parkinson s Disease', value: 'PSD' },
  { label: 'Unknown', value: 'UNK' },
];

// export const CountryInfo = [
//   { label: 'India', value: 'IN' },
//   { label: 'United States of America', value: 'USA' },
//   { label: 'United Kingdom', value: 'UK' },
// ];

export const CmntTypeInfo = [
  { label: 'Everyone', value: 'all' },
  // { label: 'My Group', value: 'group' },
  { label: 'None (None can comment)', value: 'none' },
];

export const ListCourseType = [
  { label: 'Diploma', value: 'DIPLOMA' },
  { label: 'Doctorate', value: 'PHD' },
  { label: 'Masters', value: 'MASTERS' },
  { label: 'Post Graduation(PG)', value: 'PG' },
  { label: 'Under Graduate(UG)', value: 'UG' },
];

export const ListEmploymentStatus = [
  { label: 'Full-Time Employee', value: 'FTE' },
  { label: 'Part-Time Employee', value: 'PTE' },
  { label: 'Freelancer', value: 'FRE' },
  { label: 'Self-Employed', value: 'SFE' },
  // { label: 'Temporary Employee', value: 'TME' },
  // { label: 'Intern', value: 'INT' },
  { label: 'Volunteer', value: 'VNT' },
];

export const ListExpYrs = [
  { label: '0-3 Years', value: 0 },
  { label: '3-5 Year', value: 1 },
  { label: '5-8 Year', value: 2 },
  { label: '8-12 Year', value: 3 },
  { label: '12-15 Year', value: 4 },
  { label: '15-18 Year', value: 5 },
  { label: '18-22 Year', value: 6 },
  { label: '22-25 Year', value: 7 },
  { label: '25-30 Year', value: 8 },
  { label: '30-35 Year', value: 9 },
  { label: '35-40 Year', value: 10 },
  { label: '40+ Year', value: 11 },
];

export const FilterLocItems = [
  { label: 'Mumbai', value: 'MUM' },
  { label: 'Navi Mumbai', value: 'NMB' },
  { label: 'Delhi/NCR', value: 'DEL' },
  { label: 'Pune', value: 'PUN' },
  { label: 'Banglore', value: 'BAN' },
  { label: 'Kolkata', value: 'KOL' },
  { label: 'Hyderabad', value: 'HYD' },
  { label: 'Chennai', value: 'CHE' },
  { label: 'Gurugram', value: 'GUR' },
  { label: 'Noida', value: 'NOI' },
];

export const FilterIndusItems = [
  { label: 'FMCG', value: 'FMCG' },
  { label: 'IT & Consulting', value: 'ITC' },
  { label: 'Retail', value: 'RET' },
  { label: 'Software Product', value: 'SPROD' },
  { label: 'Food Processing', value: 'FDPR' },
  { label: 'Travel & Tourism', value: 'TAT' },
  { label: 'Internet', value: 'INT' },
];

export const FilterDeptItems = [
  { label: 'Finance Accounting', value: 'FA' },
  { label: 'Sales & Business', value: 'SBD' },
  { label: 'Marketing Communication', value: 'MCN' },
  { label: 'Procurement Supply Chain', value: 'PSC' },
  { label: 'Human Resources', value: 'HRS' },
  { label: 'Prod, Manufacturing & Engineering', value: 'PME' },
];

export const ListCityTier = [
  { label: 'Tier-1', value: 1 },
  { label: 'Tier-2', value: 2 },
  { label: 'Tier-3', value: 3 },
];
export const ListLoginAccess = [
  { label: 'Approve Access', value: 'APPR' },
  { label: 'Pending Approval', value: 'PADM' },
  { label: 'Block User', value: 'NACC' },
];
