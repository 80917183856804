export const API_ERROR = 'API_ERROR';
export const SHOW_DATA = 'SHOW_DATA';
export const UPDATE_DATA = 'UPDATE_DATA';
export const CONTACT_US_DATA = 'CONTACT_US_DATA';
export const UPDATE_CONTACT_US_DATA = 'UPDATE_CONTACT_US_DATA';
export const CLEAR_CONTACT_US_DATA = 'CLEAR_CONTACT_US_DATA';
export const REGISTER_CMPS_DATA = 'REGISTER_CMPS_DATA';
export const UPDATE_REGISTER_CMPS_DATA = 'UPDATE_REGISTER_CMPS_DATA';
export const CLEAR_REGISTER_CMPS_DATA = 'CLEAR_REGISTER_CMPS_DATA';
export const GET_LOGIN_TOKEN = 'GET_LOGIN_TOKEN';
export const UPDATE_LOGIN_TOKEN = 'UPDATE_LOGIN_TOKEN';
export const CLEAR_LOGIN_TOKEN = 'CLEAR_LOGIN_TOKEN';
export const GET_PROFILE = 'GET_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const GET_FORGOT_PASSWORD = 'GET_FORGOT_PASSWORD';
export const UPDATE_FORGOT_PASSWORD = 'UPDATE_FORGOT_PASSWORD';
export const GET_FILE_URL = 'GET_FILE_URL';
export const UPDATE_GET_FILE_URL = 'UPDATE_GET_FILE_URL';
export const GET_CITY_LIST = 'GET_CITY_LIST';
export const UPDATE_CITY_LIST = 'UPDATE_CITY_LIST';
export const CLEAR_CITY_LIST = 'CLEAR_CITY_LIST';
export const USER_PROFILE_PUT_DATA = 'USER_PROFILE_PUT_DATA';
export const UPDATE_USER_PROFILE_PUT_DATA = 'UPDATE_USER_PROFILE_PUT_DATA';
export const CLEAR_USER_PROFILE_PUT_DATA = 'CLEAR_USER_PROFILE_PUT_DATA';
export const PASS_KEY_DATA = 'PASS_KEY_DATA';
export const UPDATE_PASS_KEY_DATA = 'UPDATE_PASS_KEY_DATA';
export const CLEAR_PASS_KEY_DATA = 'CLEAR_PASS_KEY_DATA';
export const UPLOAD_S3_FILES_DATA = 'UPLOAD_S3_FILES_DATA';
export const UPDATE_UPLOAD_S3_FILES_DATA = 'UPDATE_UPLOAD_S3_FILES_DATA';
export const CLEAR_UPLOAD_S3_FILES_DATA = 'CLEAR_UPLOAD_S3_FILES_DATA';
export const GET_S3_FILES_DATA = 'GET_S3_FILES_DATA';
export const UPDATE_GET_S3_FILES_DATA = 'UPDATE_GET_S3_FILES_DATA';
export const CLEAR_GET_S3_FILES_DATA = 'CLEAR_GET_S3_FILES_DATA';
export const GET_ORG_LIST = 'GET_ORG_LIST';
export const UPDATE_GET_ORG_LIST = 'UPDATE_GET_ORG_LIST';
export const CLEAR_ORG_LIST = 'CLEAR_ORG_LIST';
export const GET_SKILL_LIST = 'GET_SKILL_LIST';
export const UPDATE_GET_SKILL_LIST = 'UPDATE_GET_SKILL_LIST';
export const CLEAR_SKILL_LIST = 'CLEAR_SKILL_LIST';
export const GET_RESUME_PROFILE = 'GET_RESUME_PROFILE';
export const UPDATE_RESUME_PROFILE = 'UPDATE_RESUME_PROFILE';
export const CLEAR_RESUME_PROFILE = 'CLEAR_RESUME_PROFILE';
export const GET_RESUME_OBJ = 'GET_RESUME_OBJ';
export const UPDATE_RESUME_OBJ = 'UPDATE_RESUME_OBJ';
export const CLEAR_RESUME_OBJ = 'CLEAR_RESUME_OBJ';
export const GET_OBJECTIVE = 'GET_OBJECTIVE';
export const UPDATE_OBJECTIVE = 'UPDATE_OBJECTIVE';
export const CLEAR_OBJECTIVE = 'CLEAR_OBJECTIVE';
export const GET_ORG_PROJ = 'GET_ORG_PROJ';
export const UPDATE_ORG_PROJ = 'UPDATE_ORG_PROJ';
export const CLEAR_ORG_ORG_PROJ = 'CLEAR_ORG_ORG_PROJ';
export const GET_RESUME_VIDEO = 'GET_RESUME_VIDEO';
export const UPDATE_RESUME_VIDEO = 'UPDATE_RESUME_VIDEO';
export const CLEAR_RESUME_VIDEO = 'CLEAR_RESUME_VIDEO';
export const GET_VIDEO = 'GET_VIDEO';
export const UPDATE_VIDEO = 'UPDATE_VIDEO';
export const CLEAR_VIDEO = 'CLEAR_VIDEO';
export const GET_LIST_ORG_PROJ = 'GET_LIST_ORG_PROJ';
export const UPDATE_LIST_ORG_PROJ = 'UPDATE_LIST_ORG_PROJ';
export const GET_ALL_JOBS = 'GET_ALL_JOBS';
export const UPDATE_ALL_JOBS = 'UPDATE_ALL_JOBS';
export const GET_JOB_POSITION_BY_ID = 'GET_JOB_POSITION_BY_ID';
export const UPDATE_JOB_POSITION_BY_ID = 'UPDATE_JOB_POSITION_BY_ID';
export const CLEAR_JOB_POSITION_BY_ID = 'CLEAR_JOB_POSITION_BY_ID';
export const GET_ALL_P_JOBS = 'GET_ALL_P_JOBS';
export const GET_APL_JOBS = 'GET_APL_JOBS';
export const GET_SVD_JOBS = 'GET_SVD_JOBS';
export const UPDATE_ALL_P_JOBS = 'UPDATE_ALL_P_JOBS';
export const UPDATE_APL_JOBS = 'UPDATE_APL_JOBS';
export const UPDATE_SVD_JOBS = 'UPDATE_SVD_JOBS';
export const GET_JOB_STATUS = 'GET_JOB_STATUS';
export const UPDATE_JOB_STATUS_DATA = 'UPDATE_JOB_STATUS_DATA';
export const CLEAR_JOB_STATUS_DATA = 'CLEAR_JOB_STATUS_DATA';
export const DELETE_ORG_PROJ = 'DELETE_ORG_PROJ';
export const UPDATE_DELETE_ORG_PROJ = 'UPDATE_DELETE_ORG_PROJ';
export const GET_PREVIEW_RESUME = 'GET_PREVIEW_RESUME';
export const UPDATE_PREVIEW_RESUME = 'UPDATE_PREVIEW_RESUME';
export const UPLOAD_VIDEO_DATA = 'UPLOAD_VIDEO_DATA';
export const UPDATE_UPLOAD_VIDEO_DATA = 'UPDATE_UPLOAD_VIDEO_DATA';
export const CLEAR_UPLOAD_VIDEO_DATA = 'CLEAR_UPLOAD_VIDEO_DATA';
export const GET_VIDEO_URL = 'GET_VIDEO_URL';
export const UPDATE_GET_VIDEO_URL = 'UPDATE_GET_VIDEO_URL';
export const CLEAR_GET_VIDEO_URL = 'CLEAR_GET_VIDEO_URL';
export const GET_HOBBIES = 'GET_HOBBIES';
export const UPDATE_HOBBIES = 'UPDATE_HOBBIES';
export const CLEAR_HOBBIES = 'CLEAR_HOBBIES';
export const GET_RESULT_HOBBIES = 'GET_RESULT_HOBBIES';
export const UPDATE_RESULT_HOBBIES = 'UPDATE_RESULT_HOBBIES';
export const CLEAR_RESULT_HOBBIES = 'CLEAR_RESULT_HOBBIES';
export const GET_CERT = 'GET_CERT';
export const UPDATE_CERT = 'UPDATE_CERT';
export const CLEAR_CERT = 'CLEAR_CERT';
export const GET_RESULT_CERT = 'GET_RESULT_CERT';
export const UPDATE_RESULT_CERT = 'UPDATE_RESULT_CERT';
export const CLEAR_RESULT_CERT = 'CLEAR_RESULT_CERT';
export const GET_METAPIN = 'GET_METAPIN';
export const UPDATE_METAPIN = 'UPDATE_METAPIN';
export const CLEAR_METAPIN = 'CLEAR_METAPIN';
export const GET_LANG_LIST = 'GET_LANG_LIST';
export const UPDATE_LANG_LIST = 'UPDATE_LANG_LIST';
export const GET_POST_PUBLISH = 'GET_POST_PUBLISH';
export const UPDATE_POST_PUBLISH = 'UPDATE_POST_PUBLISH';
export const DEL_POST_PUBLISH = 'DEL_POST_PUBLISH';
export const UPDATE_DEL_POST_PUBLISH = 'UPDATE_DEL_POST_PUBLISH';
export const CLEAR_ORG_POST_PUBLISH = 'CLEAR_ORG_POST_PUBLISH';
export const GET_POST_LIST = 'GET_POST_LIST';
export const UPDATE_GET_POST_LIST = 'UPDATE_GET_POST_LIST';
export const CLEAR_POST = 'CLEAR_POST';
export const POST_DB_DATA = 'POST_DB_DATA';
export const UPDATE_POST_DB_DATA = 'UPDATE_POST_DB_DATA';
export const CLEAR_POST_DB_DATA = 'CLEAR_POST_DB_DATA';
export const LIKE_DATA = 'LIKE_DATA';
export const UPDATE_LIKE_DATA = 'UPDATE_LIKE_DATA';
export const CLEAR_LIKE_DATA = 'CLEAR_LIKE_DATA';
export const COMMENT_DATA = 'COMMENT_DATA';
export const UPDATE_COMMENT_DATA = 'UPDATE_COMMENT_DATA';
export const CLEAR_COMMENT_DATA = 'CLEAR_COMMENT_DATA';
export const FOLLOW_DATA = 'FOLLOW_DATA';
export const UPDATE_FOLLOW_DATA = 'UPDATE_FOLLOW_DATA';
export const CLEAR_FOLLOW_DATA = 'CLEAR_FOLLOW_DATA';
export const GET_COMMENT_LIST = 'GET_COMMENT_LIST';
export const UPDATE_GET_COMMENT_LIST = 'UPDATE_GET_COMMENT_LIST';
export const CLEAR_COMMENT = 'CLEAR_COMMENT';
export const GET_USER_LIKES_LIST = 'GET_USER_LIKES_LIST';
export const UPDATE_GET_USER_LIKES_LIST = 'UPDATE_GET_USER_LIKES_LIST';
export const CLEAR_USER_LIKES = 'CLEAR_USER_LIKES';
export const GET_STRM_LIST = 'GET_STRM_LIST';
export const UPDATE_STRM_LIST = 'UPDATE_STRM_LIST';
export const BATCH_DATA = 'BATCH_DATA';
export const UPDATE_BATCH_DATA = 'UPDATE_BATCH_DATA';
export const CLEAR_BATCH_DATA = 'CLEAR_BATCH_DATA';
export const GET_BATCH_LIST = 'GET_BATCH_LIST';
export const UPDATE_BATCH_LIST = 'UPDATE_BATCH_LIST';
export const CAND_STS_DATA = 'CAND_STS_DATA';
export const UPDATE_CAND_STS_DATA = 'UPDATE_CAND_STS_DATA';
export const CLEAR_CAND_STS_DATA = 'CLEAR_CAND_STS_DATA';
export const GET_CANDIDATES_LIST = 'GET_CANDIDATES_LIST';
export const UPDATE_CANDIDATES_LIST = 'UPDATE_CANDIDATES_LIST';
export const CLEAR_CAND_LIST_DATA = 'CLEAR_CAND_LIST_DATA';
export const REGISTER_EMAIL_MNTR_DATA = 'REGISTER_EMAIL_MNTR_DATA';
export const UPDATE_REGISTER_EMAIL_MNTR_DATA = 'UPDATE_REGISTER_EMAIL_MNTR_DATA';
export const CLEAR_REGISTER_EMAIL_MNTR_DATA = 'CLEAR_REGISTER_EMAIL_MNTR_DATA';
export const REGISTER_PRSNL_MNTR_DATA = 'REGISTER_PRSNL_MNTR_DATA';
export const UPDATE_REGISTER_PRSNL_MNTR_DATA = 'UPDATE_REGISTER_PRSNL_MNTR_DATA';
export const CLEAR_REGISTER_PRSNL_MNTR_DATA = 'CLEAR_REGISTER_PRSNL_MNTR_DATA';
export const REGISTER_ABTME_MNTR_DATA = 'REGISTER_ABTME_MNTR_DATA';
export const UPDATE_REGISTER_ABTME_MNTR_DATA = 'UPDATE_REGISTER_ABTME_MNTR_DATA';
export const CLEAR_REGISTER_ABTME_MNTR_DATA = 'CLEAR_REGISTER_ABTME_MNTR_DATA';
export const REGISTER_EMPLOY_MNTR_DATA = 'REGISTER_EMPLOY_MNTR_DATA';
export const UPDATE_REGISTER_EMPLOY_MNTR_DATA = 'UPDATE_REGISTER_EMPLOY_MNTR_DATA';
export const CLEAR_REGISTER_EMPLOY_MNTR_DATA = 'CLEAR_REGISTER_EMPLOY_MNTR_DATA';
export const GET_INDUSTP_LIST = 'GET_INDUSTP_LIST';
export const UPDATE_GET_INDUSTP_LIST = 'UPDATE_GET_INDUSTP_LIST';
export const CLEAR_INDUSTP_LIST = 'CLEAR_INDUSTP_LIST';
export const GET_CHILD_INDUSTP_LIST = 'GET_CHILD_INDUSTP_LIST';
export const UPDATE_CHILD_GET_INDUSTP_LIST = 'UPDATE_CHILD_GET_INDUSTP_LIST';
export const CLEAR_CHILD_INDUSTP_LIST = 'CLEAR_CHILD_INDUSTP_LIST';
export const REGISTER_XCLN_DATA = 'REGISTER_XCLN_DATA';
export const UPDATE_REGISTER_XCLN_DATA = 'UPDATE_REGISTER_XCLN_DATA';
export const CLEAR_REGISTER_XCLN_DATA = 'CLEAR_REGISTER_XCLN_DATA';
export const GET_CNTY_LIST = 'GET_CNTY_LIST';
export const UPDATE_CNTY_LIST = 'UPDATE_CNTY_LIST';
export const ADD_CLIENT_DATA = 'ADD_CLIENT_DATA';
export const UPDATE_ADD_CLIENT_DATA = 'UPDATE_ADD_CLIENT_DATA';
export const CLEAR_ADD_CLIENT_DATA = 'CLEAR_ADD_CLIENT_DATA';
export const GET_CLIENT_LIST = 'GET_CLIENT_LIST';
export const UPDATE_GET_CLIENT_LIST = 'UPDATE_GET_CLIENT_LIST';
export const CLEAR_GET_CLIENT_DATA = 'CLEAR_GET_CLIENT_DATA';
export const ADD_CLIENT_USER_DATA = 'ADD_CLIENT_USER_DATA';
export const UPDATE_ADD_CLIENT_USER_DATA = 'UPDATE_ADD_CLIENT_USER_DATA';
export const CLEAR_ADD_CLIENT_USER_DATA = 'CLEAR_ADD_CLIENT_USER_DATA';
export const GET_CLIENT_USER_LIST = 'GET_CLIENT_USER_LIST';
export const UPDATE_GET_CLIENT_USER_LIST = 'UPDATE_GET_CLIENT_USER_LIST';
export const CLEAR_GET_CLIENT_USER_DATA = 'CLEAR_GET_CLIENT_USER_DATA';
export const GET_UNIV_LIST = 'GET_UNIV_LIST';
export const UPDATE_UNIV_LIST = 'UPDATE_UNIV_LIST';
export const CLEAR_UNIV_LIST_DATA = 'CLEAR_UNIV_LIST_DATA';
export const GET_MNTR_STS_LIST = 'GET_MNTR_STS_LIST';
export const UPDATE_MNTR_STS_LIST = 'UPDATE_MNTR_STS_LIST';
export const CLEAR_MNTR_STS_LIST_DATA = 'CLEAR_MNTR_STS_LIST_DATA';
export const GET_MENTOR_ACCESS = 'GET_MENTOR_ACCESS';
export const UPDATE_MENTOR_ACCESS = 'UPDATE_MENTOR_ACCESS';
export const CLEAR_MENTOR_ACCESS = 'CLEAR_MENTOR_ACCESS';
export const UPDATE_S3_META_DATA = 'UPDATE_S3_META_DATA';
export const UPDATE_UPDATE_S3_META_DATA = 'UPDATE_UPDATE_S3_META_DATA';
export const CLEAR_UPDATE_S3_META_DATA = 'CLEAR_UPDATE_S3_META_DATA';
export const GET_S3_META_DATA = 'GET_S3_META_DATA';
export const GET_UPDATE_S3_META_DATA = 'GET_UPDATE_S3_META_DATA';
export const CLEAR_GET_S3_META_DATA = 'CLEAR_GET_S3_META_DATA';
export const DEL_CLIENT = 'DEL_CLIENT';
export const UPDATE_DEL_CLIENT = 'UPDATE_DEL_CLIENT';
export const CLEAR_DEL_CLIENT = 'CLEAR_DEL_CLIENT';
