import React from 'react';
import {
  faBars, faClose,
} from '@fortawesome/free-solid-svg-icons';
// , faShop
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import './nav.scss';
import {
  Button,
  CardImg,
  Col,
  Row,
} from 'reactstrap';

export const Navbar: React.FC = () => {
  // const history = useNavigate();
  // const location = useLocation();
  const [click, setClick] = React.useState(false);
  const [changeIcon, setChangeIcon] = React.useState(false);
  // const [showButtons, setShowButtons] = React.useState(false);
  // const path = location.pathname.split('/');
  // const [linkDet, setLinkDet] = useState('');

  // const handleScroll = () => {
  //   if (window.scrollY > 250) {
  //     setShowButtons(true);
  //   } else {
  //     setShowButtons(false);
  //   }
  // };

  // React.useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  const handleClick = () => {
    setChangeIcon(!changeIcon);
    setClick(!click);
  };

  // React.useEffect(() => {
  //   setLinkDet(path[1]);
  // }, [path[1]]);

  // const loginPage = () => {
  //   history('/xcelian/login');
  //   window.scrollTo(0, 0);
  // };

  return (
    <div className="navbar">
      <div className="align-start">
        <NavLink to="/" className="nav-logo">
          <CardImg
            style={{
              height: '45px', width: 'auto',
            }}
            src="/campusXcel.png"
            alt="campusXcelLogo"
          />
        </NavLink>
        <div className="ml-3 pt-2 hide-mobile-icons">
          <Button
            color="default"
            href="jobs"
            // target="_blank"
          >
            <span className="nav-text-header">Jobs</span>
          </Button>
          <Button
            color="default"
            href="staffing"
            // target="_blank"
          >
            <span className="nav-text-header">Recruiting</span>
          </Button>
          <Button
            color="default"
            href="campus"
            // target="_blank"
          >
            <span className="nav-text-header">Campus</span>
          </Button>
          <Button
            color="default"
            // href="skilling"
            // target="_blank"
          >
            <span className="nav-text-header">Upskill</span>
          </Button>
          <Button
            color="default"
            // href="mentors"
            // target="_blank"
          >
            <span className="nav-text-header">Mentors</span>
          </Button>
          <Button
            color="default"
            // href="schemes"
            // target="_blank"
          >
            <span className="nav-text-header">Schemes</span>
          </Button>
        </div>
      </div>
      <ul className={click ? 'nav-menu active pt-2 my-2' : 'nav-menu pt-2 my-2'}>
        {/* <li className="nav-item margin-mob-lr3">
          <NavLink
            className="nav-links"
            to="/training"
            title="Training"
          // onClick={() => history('#about')}
          >
            <FontAwesomeIcon icon={faUsersGear} className="nav-icon-link" /><span className={linkDet === 'training' ? 'nav-text-header-active' : 'nav-text-header'}>Training</span>
          </NavLink>
        </li>
        <li className="nav-item margin-mob-lr3">
          <NavLink
            className="nav-links"
            to="#"
            title="Jobs"
          // onClick={() => history('#')}
          >
            <FontAwesomeIcon icon={faBriefcase} className="nav-icon-link" /><span className={linkDet === 'jobs' ? 'nav-text-header-active' : 'nav-text-header'}>Jobs</span>
          </NavLink>
        </li>
        <li className="nav-item-login margin-mobile-bottom">
          <Button
            className="campus-button"
            to="/#"
            type="button"
            title="Login to CampusXcel Portal"
            onClick={loginPage}
          >
            <FontAwesomeIcon icon={faUser} className="nav-icon-link-login ml" />
            <span className="mr-2 mx-2">Login</span>
          </Button>
        </li>
         <li className="nav-item-login margin-mobile-bottom mr-2">
          <Button
            className="campus-button-lite mx-3"
            to="/#"
            type="button"
            title="Login to CampusXcel Portal"
            onClick={loginPage}
          >
            <FontAwesomeIcon icon={faUserPlus} className="nav-icon-link-register" />
            <span className="mx-1">Register</span>
          </Button>
        </li>
        <li className="nav-item-login margin-mobile-bottom mr-2 ml-3">
          <NavLink
            className="nav-links"
            to="#"
            title="Jobs"
          // onClick={() => history('#')}
          >
            <span className="nav-text-header">All Login</span>
          </NavLink>
        </li> */}
        <div className="ml-1 pt-2 hide-desktop-icons">
          <div className="my-1">
            <Button
              color="default"
              href="jobs"
              // target="_blank"
            >
              <span className="nav-text-header-mob">Jobs</span>
            </Button>
          </div>
          <div className="my-1">
            <Button
              color="default"
              href="staffing"
              // target="_blank"
            >
              <span className="nav-text-header-mob">Recruiting</span>
            </Button>
          </div>
          <div className="my-1">
            <Button
              color="default"
              href="campus"
              // target="_blank"
            >
              <span className="nav-text-header-mob">Campus</span>
            </Button>
          </div>
          <div className="my-1">
            <Button
              color="default"
              // href="skilling"
              // target="_blank"
            >
              <span className="nav-text-header-mob">Upskill</span>
            </Button>
          </div>
          <div className="my-1">
            <Button
              color="default"
              // href="mentors"
              // target="_blank"
            >
              <span className="nav-text-header-mob">Mentors</span>
            </Button>
          </div>
          <div className="my-1">
            <Button
              color="default"
              // href="schemes"
              // target="_blank"
            >
              <span className="nav-text-header-mob">Schemes</span>
            </Button>
          </div>
          <div className="my-4" />
        </div>
        <Row className="mx-2">
          <Col lg="3">
            <div className="align-start pt-1">
              <Button
                className="align-center padding-zero mx-2"
                color="default"
                href="https://www.linkedin.com/#"
                target="_blank"
              >
                <CardImg
                  alt="..."
                  className="media-networn-icon"
                  src="/images/icons/linkedin.png"
                />
              </Button>
              <Button
                className="align-center padding-zero mx-1"
                color="default"
                href="https://twitter.com/#"
                target="_blank"
              >
                <CardImg
                  alt="..."
                  className="media-networn-icon"
                  src="/images/icons/twitter.png"
                />
              </Button>
              <Button
                className="align-center padding-zero mx-2"
                color="default"
                href="https://www.facebook.com/#"
                target="_blank"
              >
                <CardImg
                  alt="..."
                  className="media-networn-icon"
                  src="/images/icons/facebook.png"
                />
              </Button>
              <Button
                className="align-center padding-zero mx-1"
                color="default"
                href="https://www.instagram.com/#"
                target="_blank"
              >
                <CardImg
                  alt="..."
                  className="media-networn-icon"
                  src="/images/icons/instagram.png"
                />
              </Button>
            </div>
          </Col>
        </Row>
        <div className="my-3" />
      </ul>
      <NavLink
        className="nav-icon mr-1"
        onClick={() => { handleClick(); }}
        to="#"
      >
        {changeIcon ? <FontAwesomeIcon icon={faClose} className="nav-close-btn" /> : <FontAwesomeIcon icon={faBars} className="nav-open-btn" />}
      </NavLink>
    </div>
  );
};

export default Navbar;
