import {
  batch, Fade, Animator, Move,
} from 'react-scroll-motion';
import {
  CardImg,
  Col, Row,
} from 'reactstrap';
import './home.scss';

export const IntroCampusXcel: React.FC = () => {
  const FadeUp = batch(Fade(), Move());
  return (
    <Animator animation={batch(FadeUp)} className="pt-5">
      <div className="pt-3 bg-intro">
        <Row className="align-center pt-4 mx-3">
          <Col lg="5" xs="12">
            <div className="margin-mob-sides">
              <div className="text-center-aligned">
                <span className="text-intro-header">
                  Career Launchpad <br />Elevate Your Future
                </span>
              </div>
              <div className="pt-4 text-center-aligned">
                <span className="text-intro">
                  Unlock your potential with tailored job opportunities, expert mentoring, and skill-building for a career that soars
                </span>
              </div>
            </div>
          </Col>
          <Col lg="5" xs="12">
            <div className="align-center pt-5">
              <CardImg
                alt="..."
                src="/images/home/Campus-all-in-one.png"
                className="image-intro"
              />
            </div>
          </Col>
        </Row>
        <div className="pt-3">
          <Row className="home-key-highlight-box pt-3">
            <Col lg="2" xs="6">
              <div className="align-center">
                <CardImg
                  alt="..."
                  className="image-intro-highlights"
                  src="/images/icons/jobs.png"
                />
              </div>
              <div className="text-center-aligned">
                <span className="text-about">Jobs</span>
              </div>
              <div className="text-center-aligned mx-2 margin-icons-bottom">
                <span className="text-sub-about">Private & Government Opportunities</span>
              </div>
            </Col>
            <Col lg="2" xs="6">
              <div className="align-center">
                <CardImg
                  alt="..."
                  className="image-intro-highlights"
                  src="/images/icons/ats.png"
                />
              </div>
              <div className="text-center-aligned">
                <span className="text-about">Talent Acquirers</span>
              </div>
              <div className="text-center-aligned mx-2 margin-icons-bottom">
                <span className="text-sub-about">Innovative Profile Management System</span>
              </div>
            </Col>
            <Col lg="2" xs="6">
              <div className="align-center">
                <CardImg
                  alt="..."
                  className="image-intro-highlights"
                  src="/images/icons/campus.png"
                />
              </div>
              <div className="text-center-aligned">
                <span className="text-about">Campus</span>
              </div>
              <div className="text-center-aligned mx-2 margin-icons-bottom">
                <span className="text-sub-about">Graduate Hire</span>
              </div>
            </Col>
            <Col lg="2" xs="6">
              <div className="align-center">
                <CardImg
                  alt="..."
                  className="image-intro-highlights"
                  src="/images/icons/skills.png"
                />
              </div>
              <div className="text-center-aligned">
                <span className="text-about">Upskilling</span>
              </div>
              <div className="text-center-aligned mx-5 margin-icons-bottom">
                <span className="text-sub-about">Acquire Job-Ready Skills</span>
              </div>
            </Col>
            <Col lg="2" xs="6">
              <div className="align-center">
                <CardImg
                  alt="..."
                  className="image-intro-highlights"
                  src="/images/icons/mentor.png"
                />
              </div>
              <div className="text-center-aligned">
                <span className="text-about">Mentoring</span>
              </div>
              <div className="text-center-aligned mx-3 margin-icons-bottom">
                <span className="text-sub-about">Gain Insights from Professionals</span>
              </div>
            </Col>
            <Col lg="2" xs="6">
              <div className="align-center">
                <CardImg
                  alt="..."
                  className="image-intro-highlights"
                  src="/images/icons/schemes.png"
                />
              </div>
              <div className="text-center-aligned">
                <span className="text-about">Schemes</span>
              </div>
              <div className="text-center-aligned mx-5 margin-icons-bottom">
                <span className="text-sub-about">Key Government Initiatives</span>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Animator>
  );
};

export default IntroCampusXcel;
