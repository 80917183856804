import React, { useState } from 'react';
import { RootState } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Col, Form, Row,
} from 'reactstrap';
import {
  addClientReq,
  clearAddClientReq,
} from '../../../../../store/campusXcel/actions';
import { successAlert, errorAlert } from '../../../../../utils/alert';
import {
  APIHeader, UserType,
} from '../../../../../utils/constants';
import LoaderData from '../../../../../utils/loader';
import { getTokenFromLocalStorage } from '../../../../../utils/service/localstorage-service';
import { ClientAddDataInput } from '../../../../../services/sadmin/add-clients/add-new-clients.types';
import AddCampus from '../campus/add-campus-form';
import { TabProps } from '../onboard.type';

export const AddClient: React.FC<TabProps> = ({ ordTp, tabTp, clientTp }) => {
  const dispatch = useDispatch();
  const tokenData = getTokenFromLocalStorage();
  const [loaderClient, setLoaderClient] = useState(false);
  const [showSuccessAlert, setSuccessShowAlert] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const clientResponse = useSelector((state: RootState) => state.campusXcel.addClient);
  const [client, setClient] = useState<ClientAddDataInput>({
    cTp: clientTp,
    nme: '',
    cntEId: '',
    dlCd1: '+91',
    cntPh1: '',
    citySt: '',
    dlCd2: '+91',
    cntPh2: '',
    univ: '',
    txId: '',
    web: '',
    oNme: '',
  });

  const handleSubmitClient = (e: any) => {
    e.preventDefault();
    setLoaderClient(true);
    dispatch(addClientReq({
      inputBody: client,
      requestType: APIHeader.REQ_ONBOARD_CLIENT,
      uTp: UserType.CXADM,
      token: tokenData,
    }));
  };

  React.useEffect(() => {
    if (clientResponse.error && clientResponse.message !== '') {
      setLoaderClient(false);
      setAlertMsg(clientResponse.message);
      setErrorShowAlert(true);
      dispatch(clearAddClientReq());
    }
  }, [clientResponse.error, clientResponse.message]);

  React.useEffect(() => {
    if (!clientResponse.error && clientResponse.message !== '') {
      setLoaderClient(false);
      Promise.resolve(dispatch(clearAddClientReq()))
        .then(() => {
          setClient({
            cTp: clientTp,
            nme: '',
            cntEId: '',
            dlCd1: '+91',
            cntPh1: '',
            citySt: '',
            dlCd2: '+91',
            cntPh2: '',
            univ: '',
            txId: '',
            web: '',
            oNme: '',
          });
          setAlertMsg(clientResponse.message);
          setSuccessShowAlert(true);
        });
    }
  }, [clientResponse.error, clientResponse.message]);

  return (
    <div>
      {/* <div className="mb-3 align-center">
        Onboard {clientTp === 'CMPS' ? 'Campus' : null}
      </div> */}
      <Row className="align-start">
        { loaderClient ? <LoaderData /> : null}
        {showSuccessAlert ? (
          successAlert(false, alertMsg, showSuccessAlert, setSuccessShowAlert)
        ) : null}
        {showErrorsAlert ? (
          errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
        ) : null}
        <Col lg="9" xs="12">
          <div className="onboard-chip-box">
            <Form onSubmit={handleSubmitClient}>
              <Row className="align-center">
                <Col xs="12">
                  {clientTp === 'CMPS'
                    ? <AddCampus ordTp={ordTp} clientTp={clientTp} tabTp={tabTp} college={client} setCollege={setClient} /> : null}
                </Col>
                <Col xs="12">
                  <div className="align-center my-3">
                    <Button className="campus-button-all">
                      SAVE
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AddClient;
