import { ProfileResponse, ProfileResults } from './get-profile.service.types';

export const ProfileTransform: (response: ProfileResponse) => ProfileResults = (profileResults) => {
  const { data, is_error, message } = profileResults;
  const result :ProfileResults = {
    profileData: {
      fNme: '',
      lNme: '',
      ph: '',
      isPKReset: false,
      lgnSts: '',
      eId: '',
      avtr: '',
      gnd: '',
      dlCd: '',
      UserCd: '',
      UserTp: '',
      isAdm: false,
      cmpsTp: '',
      cmpsCd: '',
      cmpsNme: '',
      cmpsCrs: {
        crs: '',
        strm: '',
        sDte: '',
        eDte: '',
      },
    },
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.profileData.fNme = data.fNme ?? '';
    result.profileData.lNme = data.lNme ?? '';
    result.profileData.ph = data.ph ?? '';
    result.profileData.eId = data.eId ?? '';
    result.profileData.avtr = data.avtr ?? '';
    result.profileData.gnd = data.gnd ?? 'UNK';
    result.profileData.isPKReset = data.isPKReset ?? false;
    result.profileData.lgnSts = data.lgnSts ?? 'UNK';
    result.profileData.dlCd = data.dlCd ?? '';
    result.profileData.isAdm = data.isAdm ?? false;
    result.profileData.UserCd = data.UserCd ?? '';
    result.profileData.UserTp = data.UserTp ?? '';
    result.profileData.cmpsTp = data.cmpsTp ?? '';
    result.profileData.cmpsCd = data.cmpsCd ?? '';
    result.profileData.cmpsNme = data.cmpsNme ?? '';
    result.profileData.cmpsCrs.crs = data.cmpsCrs.crs ?? '';
    result.profileData.cmpsCrs.strm = data.cmpsCrs.strm ?? '';
    result.profileData.cmpsCrs.sDte = data.cmpsCrs.sDte ?? '';
    result.profileData.cmpsCrs.eDte = data.cmpsCrs.eDte ?? '';
    result.isError = is_error;
    result.message = 'executed';
  }
  return result;
};
