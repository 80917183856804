import { useState } from 'react';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import './editor.scss';

const EditorComponent = () => {
  const [contents, setContents] = useState('');
  const handleEditorEvent = (content: string) => {
    setContents(content);
  };
  return (
    <div>
      <SunEditor
        height="600px"
        width="auto"
        setOptions={{
          buttonList: [
            ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript',
              'fontColor', 'hiliteColor',
              'outdent', 'indent',
              'align', 'horizontalRule', 'list', 'lineHeight',
              'table', 'link', 'font', 'fontSize', 'formatBlock',
              'video', 'image'],
          ],
        }}
        setContents={contents}
        onChange={handleEditorEvent}
      />
    </div>
  );
};
export default EditorComponent;
