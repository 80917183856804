import { StaticConfig } from './config-type';

export const getConfig: () => StaticConfig = () => ({
  USERNAME: process.env.REACT_APP_USERNAME || '',
  PASSWORD: process.env.REACT_APP_PASSWORD || '',
  CAMPUSXCEL_API_ROOT: process.env.REACT_APP_CAMPUSXCEL_API_ROOT || '',
  CAMPUSXCEL_USER_API_ROOT: process.env.REACT_APP_CAMPUSXCEL_USER_API_ROOT || '',
  CAMPUSXCEL_CMPUS_API_ROOT: process.env.REACT_APP_CAMPUSXCEL_CMPUS_API_ROOT || '',
  CAMPUSXCEL_SADMIN_API_ROOT: process.env.REACT_APP_CAMPUSXCEL_SADMIN_API_ROOT || '',
  CAMPUSXCEL_API_VERSION: parseInt(process.env.REACT_APP_CAMPUSXCEL_API_VERSION || '1', 10),
  REGION: process.env.REACT_APP_AWS_REGION || '',
  AWS_ACCESS_KEY: process.env.REACT_APP_AWS_ACCESS_KEY || '',
  AWS_SECRET: process.env.REACT_APP_AWS_SECRET || '',
  AWS_REGION: process.env.REACT_APP_AWS_REGION || '',
  AWS_CXUSER_BUCKET: process.env.REACT_APP_AWS_CXUSER_BUCKET || '',
  AWS_CXADMIN_BUCKET: process.env.REACT_APP_AWS_CXADMIN_BUCKET || '',
});
