import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import {
  Row, Col, Card, CardHeader, CardBody, CardImg, Input, CardFooter, Button,
} from 'reactstrap';
import './avatar.scss';
import { getConfig } from '../../../../utils/config/config';
import { successAlert, errorAlert } from '../../../../utils/alert';
import LoaderData from '../../../../utils/loader';
import { getUserProfileLocalStorage } from '../../../../utils/service/localstorage-service';
import { clearUploadS3FilesReq, uploadS3FilesReq } from '../../../../store/campusXcel/actions';
import { FileDetails } from '../../../../services/common/get-s3-files/get-s3files.types';
import { UploadS3FilesDataInput } from '../../../../services/common/upload-s3-files/upload-s3-files.types';
import { AvtrProps } from './avtr.type';

const { AWS_CXUSER_BUCKET } = getConfig();

export const MyAvatar: React.FC<AvtrProps> = ({ imgUrl, folderName, fileName }) => {
  const dispatch = useDispatch();
  const userInitTp = getUserProfileLocalStorage();
  const [loaderProfile, setLoaderProfile] = useState(false);
  const [showSuccessAlert, setSuccessShowAlert] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [filePreview, setFilePreview] = useState('/images/common/avatar.png');
  const uploadS3FilesAwsResponse = useSelector((state: RootState) => state.campusXcel.uploadS3Files);
  const [avatarImage, setAvatarImage] = React.useState<UploadS3FilesDataInput>({
    fileData: new FormData(),
  });

  React.useEffect(() => {
    if (imgUrl !== '') {
      setFilePreview(imgUrl);
    }
  }, [imgUrl]);

  React.useEffect(() => {
    if (userInitTp && JSON.parse(userInitTp).profileData.avatar) {
      setFilePreview(userInitTp && JSON.parse(userInitTp).profileData.avatar);
    }
  }, [
    userInitTp && JSON.parse(userInitTp).profileData.avatar,
  ]);

  const handleprofilephoto = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (!files || files.length === 0) return;
    const maxSizeInBytes = 5 * 1024 * 1024;
    const formData = new FormData();
    const newProdImgList: FileDetails[] = [];
    Array.from(files).forEach((file) => {
      if (file.size > maxSizeInBytes) {
        setAlertMsg('File size exceeds the limit of 5 MB.');
        setErrorShowAlert(true);
        return;
      }
      // Rename the file to have a .png extension
      const newFile = new File([file], `${file.name.split('.')[0]}.png`, { type: 'image/png' });
      formData.append(newFile.name, newFile);
      const objectUrl = URL.createObjectURL(newFile);
      setFilePreview(objectUrl);
      newProdImgList.push({ url: objectUrl, nme: newFile.name });
    });
    // setEditProdImgList((prevList) => [...prevList, ...newProdImgList]);
    setAvatarImage({ ...avatarImage, fileData: formData });
  };

  const handleUploadEditProduct = () => {
    setLoaderProfile(true);
    Promise.resolve(
      dispatch(uploadS3FilesReq({
        bucketNme: AWS_CXUSER_BUCKET,
        folderName, // 'avatars',
        fileNmeInit: fileName, // `${userTp}-${userId}`,
        formData: avatarImage.fileData,
        requestType: '',
        isEdit: false,
      })),
    );
  };

  React.useEffect(() => {
    if (uploadS3FilesAwsResponse.fileName !== '') {
      setLoaderProfile(false);
      setAlertMsg('Uploaded Successfully');
      dispatch(clearUploadS3FilesReq());
      setSuccessShowAlert(true);
    }
  }, [uploadS3FilesAwsResponse.fileName]);

  return (
    <Row className="pt-3 margin-bottom">
      { loaderProfile ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(false, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <Col lg={{ size: 12, order: 1 }} md={{ size: 12, order: 1 }} xs={{ order: 1 }}>
        <Card className="avatar-outer-card">
          <CardHeader className="form-card-header my-1 align-center">
            Upload New Image
          </CardHeader>
          <CardBody>
            <div className="align-center">
              <CardImg alt="logo" className="img-fluid rounded-circle avatar-user-profile-preview" src={filePreview} />
            </div>
            <div className="upload-box align-center">
              <Input
                type="file"
                accept=".png, .jpg, .jpeg"
                name="fileData"
                className="upload-box-input"
                onChange={(e) => handleprofilephoto(e)}
              />
              <span className="register-file-link">Choose Your Avatar</span>
            </div>
          </CardBody>
          <CardFooter className="form-card-footer">
            <div className="align-center my-2">
              <Button disabled={Array.from(avatarImage.fileData.keys()).length === 0} className="card-iphone-button mx-1" onClick={() => handleUploadEditProduct()}>
                UPLOAD
              </Button>
            </div>
          </CardFooter>
        </Card>
      </Col>
    </Row>
  );
};
export default MyAvatar;
