import { useState } from 'react';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import './article.scss';

const ArticleComponent = () => {
  const [contents, setContents] = useState('');
  const handleEditorEvent = (content: string) => {
    setContents(content);
  };
  return (
    <div>
      <SunEditor
        height="700px"
        width="auto"
        setOptions={{
          buttonList: [
            ['font', 'fontSize', 'bold', 'underline', 'italic', 'link', 'image'],
          ],
        }}
        setContents={contents}
        onChange={handleEditorEvent}
      />
    </div>
  );
};
export default ArticleComponent;
