import { GetCertRequest } from './campus/certifications/get-cert/get-cert.service';
import {
  GetCertService, GetCertOptions, GetCertResponse, GetCertResults,
} from './campus/certifications/get-cert/get-cert.service.types';
import { GetCertTransform } from './campus/certifications/get-cert/get-cert.transform';
import { CertPutRequest } from './campus/certifications/update-cert/update-cert.service';
import {
  CertPutService, CertPutOptions, CertPutResponse, CertPutResults,
} from './campus/certifications/update-cert/update-cert.service.types';
import { CertPutTransform } from './campus/certifications/update-cert/update-cert.transform';
import { BatchAddRequest } from './campus/college-admin/cmps-batches/add-batch/add-batch.service';
import {
  BatchAddService, BatchAddOptions, BatchAddResponse, BatchAddResults,
} from './campus/college-admin/cmps-batches/add-batch/add-batch.service.types';
import { BatchAddTransform } from './campus/college-admin/cmps-batches/add-batch/add-batch.transform';
import { ListBatchRequest } from './campus/college-admin/cmps-batches/list-batch/list-batch.service';
import {
  ListBatchService, ListBatchOptions, ListBatchResponse, ListBatchResults,
} from './campus/college-admin/cmps-batches/list-batch/list-batch.service.types';
import { ListBatchTransform } from './campus/college-admin/cmps-batches/list-batch/list-batch.transform';
import { CommentPutRequest } from './campus/dashboard/comments/add-comment/comment.service';
import {
  CommentService, CommentOptions, CommentResponse, CommentResults,
} from './campus/dashboard/comments/add-comment/comment.service.types';
import { CommentTransform } from './campus/dashboard/comments/add-comment/comment.transform';
import { ListCommentRequest } from './campus/dashboard/comments/get-all-comments/list-comment.service';
import {
  ListCommentService, ListCommentOptions, ListCommentResponse, ListCommentResults,
} from './campus/dashboard/comments/get-all-comments/list-comment.service.types';
import { ListCommentTransform } from './campus/dashboard/comments/get-all-comments/list-comment.transform';
import { FollowPutRequest } from './campus/dashboard/follow/add-follow/follow.service';
import {
  FollowService, FollowOptions, FollowResponse, FollowResults,
} from './campus/dashboard/follow/add-follow/follow.service.types';
import { FollowTransform } from './campus/dashboard/follow/add-follow/follow.transform';
import { LikePutRequest } from './campus/dashboard/likes/add-like/like.service';
import {
  LikeService, LikeOptions, LikeResponse, LikeResults,
} from './campus/dashboard/likes/add-like/like.service.types';
import { LikeTransform } from './campus/dashboard/likes/add-like/like.transform';
import { ListUserLikesRequest } from './campus/dashboard/likes/get-all-likes/list-user-likes.service';
import {
  ListUserLikesService, ListUserLikesOptions, ListUserLikesResponse, ListUserLikesResults,
} from './campus/dashboard/likes/get-all-likes/list-user-likes.service.types';
import { ListUserLikesTransform } from './campus/dashboard/likes/get-all-likes/list-user-likes.transform';
import { PostDBRequest } from './campus/dashboard/post/add-post-db/post-db.service';
import {
  PostDBService, PostDBOptions, PostDBResponse, PostDBResults,
} from './campus/dashboard/post/add-post-db/post-db.service.types';
import { PostDBTransform } from './campus/dashboard/post/add-post-db/post-db.transform';
import { DelPostRequest } from './campus/dashboard/post/del-post-file-s3/del-post.service';
import {
  DelPostService, DelPostOptions, DelPostResponse, DelPostResults,
} from './campus/dashboard/post/del-post-file-s3/del-post.service.types';
import { DelPostTransform } from './campus/dashboard/post/del-post-file-s3/del-post.transform';
import { ListPostRequest } from './campus/dashboard/post/get-all-posts/list-post.service';
import {
  ListPostService, ListPostOptions, ListPostResponse, ListPostResults,
} from './campus/dashboard/post/get-all-posts/list-post.service.types';
import { ListPostTransform } from './campus/dashboard/post/get-all-posts/list-post.transform';
import { GetHobbiesRequest } from './campus/hobbies/get-hobbies/get-hobbies.service';
import {
  GetHobbiesService, GetHobbiesOptions, GetHobbiesResponse, GetHobbiesResults,
} from './campus/hobbies/get-hobbies/get-hobbies.service.types';
import { GetHobbiesTransform } from './campus/hobbies/get-hobbies/get-hobbies.transform';
import { HobbiesPutRequest } from './campus/hobbies/update-hobbies/update-hobbies.service';
import {
  HobbiesPutService, HobbiesPutOptions, HobbiesPutResponse, HobbiesPutResults,
} from './campus/hobbies/update-hobbies/update-hobbies.service.types';
import { HobbiesPutTransform } from './campus/hobbies/update-hobbies/update-hobbies.transform';
import { RegisterCMPSPostRequest } from './campus/register-campus/register.service';
import {
  RegisterCMPSService, RegisterCMPSOptions, RegisterCMPSResponse, RegisterCMPSResults,
} from './campus/register-campus/register.service.types';
import { RegisterCMPSTransform } from './campus/register-campus/register.transform';
import { ResumeProfileRequest } from './campus/resume-data/get-cand-profile/get-profile.service';
import {
  ResumeProfileService, ResumeProfileOptions, ResumeProfileResponse, ResumeProfileResults,
} from './campus/resume-data/get-cand-profile/get-profile.service.types';
import { ResumeProfileTransform } from './campus/resume-data/get-cand-profile/get-profile.transform';
import { ResumeObjRequest } from './campus/resume-data/objective/get-obj/get-obj.service';
import {
  ResumeObjService, ResumeObjOptions, ResumeObjResponse, ResumeObjResults,
} from './campus/resume-data/objective/get-obj/get-obj.service.types';
import { ResumeObjTransform } from './campus/resume-data/objective/get-obj/get-obj.transform';
import { CampusObjectivePutRequest } from './campus/resume-data/objective/update-obj/objective.service';
import {
  ObjectiveService, ObjectiveOptions, ObjectiveResponse, ObjectiveResults,
} from './campus/resume-data/objective/update-obj/objective.service.types';
import { ObjectiveTransform } from './campus/resume-data/objective/update-obj/objective.transform';
import { DelOrgProjPutRequest } from './campus/resume-data/org-project/delete-org-proj/del-org-proj.service';
import {
  DelOrgProjService, DelOrgProjOptions, DelOrgProjResponse, DelOrgProjResults,
} from './campus/resume-data/org-project/delete-org-proj/del-org-proj.service.types';
import { DelOrgProjTransform } from './campus/resume-data/org-project/delete-org-proj/del-org-proj.transform';
import { ListOrgProjRequest } from './campus/resume-data/org-project/get-all-org-obj/list-org-proj.service';
import {
  ListOrgProjService, ListOrgProjOptions, ListOrgProjResponse, ListOrgProjResults,
} from './campus/resume-data/org-project/get-all-org-obj/list-org-proj.service.types';
import { ListOrgProjTransform } from './campus/resume-data/org-project/get-all-org-obj/list-org-proj.transform';
import { CampusOrgProjPutRequest } from './campus/resume-data/org-project/update-org-obj/org-proj.service';
import {
  OrgProjService, OrgProjOptions, OrgProjResponse, OrgProjResults,
} from './campus/resume-data/org-project/update-org-obj/org-proj.service.types';
import { OrgProjTransform } from './campus/resume-data/org-project/update-org-obj/org-proj.transform';
import { ResumePreviewRequest } from './campus/resume-data/preview/get-preview.service';
import {
  ResumePreviewService, ResumePreviewOptions, ResumePreviewResponse, ResumePreviewResults,
} from './campus/resume-data/preview/get-preview.service.types';
import { ResumePreviewTransform } from './campus/resume-data/preview/get-preview.transform';
import { ContactUsPostRequest } from './sadmin/contact-us/contact-us.service';
import {
  ContactUsService, ContactUsOptions, ContactUsResponse, ContactUsResults,
} from './sadmin/contact-us/contact-us.service.types';
import { ContactUsTransform } from './sadmin/contact-us/contact-us.transform';
import { ForgotPasswordRequest } from './common/forgot-password/forgot-password.service';
import {
  ForgotPasswordService, ForgotPasswordOptions, ForgotPasswordResults, ForgotPasswordResponse,
} from './common/forgot-password/forgot-password.service.types';
import { GenTokenRequest } from './common/gen-token/gen-token.service';
import {
  GenTokenService, GenTokenOptions, GenTokenResponse, GenTokenResults,
} from './common/gen-token/gen-token.service.types';
import { GenTokenTransform } from './common/gen-token/gen-token.transform';
import { FileURLRequest } from './common/get-file-url/get-file-url.service';
import {
  FileURLService, FileURLOptions, FileURLResponse, FileURLResults,
} from './common/get-file-url/get-file-url.service.types';
import { FileURLTransform } from './common/get-file-url/get-file-url.transform';
import { ProfileRequest } from './common/get-profile/get-profile.service';
import {
  ProfileService, ProfileOptions, ProfileResponse, ProfileResults,
} from './common/get-profile/get-profile.service.types';
import { ProfileTransform } from './common/get-profile/get-profile.transform';
import { PassKeyPutRequest } from './common/update-passkey/update-passkey.service';
import {
  PassKeyPutService, PassKeyPutOptions, PassKeyPutResponse, PassKeyPutResults,
} from './common/update-passkey/update-passkey.service.types';
import { PassKeyPutTransform } from './common/update-passkey/update-passkey.transform';
import { UserProfilePutRequest } from './common/update-profile/update-profile.service';
import {
  UserProfilePutService, UserProfilePutOptions, UserProfilePutResponse, UserProfilePutResults,
} from './common/update-profile/update-profile.service.types';
import { UserProfilePutTransform } from './common/update-profile/update-profile.transform';
import { createService } from './service';
import { JobPositionByIdRequest } from './staffing/job-board/job-position-by-id/job-position-by-id.service';
import {
  JobPositionByIdService, JobPositionByIdOptions, JobPositionByIdResponse, JobPositionByIdResults,
} from './staffing/job-board/job-position-by-id/job-position-by-id.service.types';
import { JobPositionByIdTransform } from './staffing/job-board/job-position-by-id/job-position-by-id.transform';
import { ListJobsRequest } from './staffing/job-board/list-jobs/list-jobs.service';
import {
  ListJobsService, ListJobsOptions, ListJobsResults, ListJobsResponse,
} from './staffing/job-board/list-jobs/list-jobs.service.types';
import { ListPJobsRequest } from './staffing/job-board/list-personalized-jobs/list-p-jobs.service';
import {
  ListPJobsService, ListPJobsOptions, ListPJobsResponse, ListPJobsResults,
} from './staffing/job-board/list-personalized-jobs/list-p-jobs.service.types';
import { ListPJobsTransform } from './staffing/job-board/list-personalized-jobs/list-p-jobs.transform';
import { JobStatusRequest } from './staffing/job-board/update-job-status/job-status.service';
import {
  JobStatusService, JobStatusOptions, JobStatusResponse, JobStatusResults,
} from './staffing/job-board/update-job-status/job-status.service.types';
import { JobStatusTransform } from './staffing/job-board/update-job-status/job-status.transform';
import { RegisterXCLNPostRequest } from './staffing/xcelian/register-xcelian/register.service';
import {
  RegisterXCLNService, RegisterXCLNOptions, RegisterXCLNResponse, RegisterXCLNResults,
} from './staffing/xcelian/register-xcelian/register.service.types';
import { RegisterXCLNTransform } from './staffing/xcelian/register-xcelian/register.transform';
import { CampusMentorAccessUpdatePutRequest } from './upskilling/mentors/mentor-access-update/mentor-update.service';
import {
  MentorAccessUpdateService, MentorAccessUpdateOptions, MentorAccessUpdateResponse, MentorAccessUpdateResults,
} from './upskilling/mentors/mentor-access-update/mentor-update.service.types';
import { MentorAccessUpdateTransform } from './upskilling/mentors/mentor-access-update/mentor-update.transform';
import { ListMentorRequest } from './upskilling/mentors/mentor-list/list-mentor.service';
import {
  ListMentorService, ListMentorOptions, ListMentorResponse, ListMentorResults,
} from './upskilling/mentors/mentor-list/list-mentor.service.types';
import { ListMentorTransform } from './upskilling/mentors/mentor-list/list-mentor.transform';
import { RegisterAbtMeMNTRSRequest } from './upskilling/register-mentors/About/register.service';
import {
  RegisterAbtMeMNTRSService, RegisterAbtMeMNTRSOptions, RegisterAbtMeMNTRSResponse, RegisterAbtMeMNTRSResults,
} from './upskilling/register-mentors/About/register.service.types';
import { RegisterAbtMeMNTRSTransform } from './upskilling/register-mentors/About/register.transform';
import { RegisterPrsnlMNTRSRequest } from './upskilling/register-mentors/Personal/register.service';
import {
  RegisterPrsnlMNTRSService, RegisterPrsnlMNTRSOptions, RegisterPrsnlMNTRSResponse, RegisterPrsnlMNTRSResults,
} from './upskilling/register-mentors/Personal/register.service.types';
import { RegisterPrsnlMNTRSTransform } from './upskilling/register-mentors/Personal/register.transform';
import { RegisterEmployMNTRSRequest } from './upskilling/register-mentors/Prof-Exp/register.service';
import {
  RegisterEmployMNTRSService, RegisterEmployMNTRSOptions, RegisterEmployMNTRSResponse, RegisterEmployMNTRSResults,
} from './upskilling/register-mentors/Prof-Exp/register.service.types';
import { RegisterEmployMNTRSTransform } from './upskilling/register-mentors/Prof-Exp/register.transform';
import { RegisterEmailMNTRSPostRequest } from './upskilling/register-mentors/ValidateEmail/register.service';
import {
  RegisterEmailMNTRSService, RegisterEmailMNTRSOptions, RegisterEmailMNTRSResponse, RegisterEmailMNTRSResults,
} from './upskilling/register-mentors/ValidateEmail/register.service.types';
import { RegisterEmailMNTRSTransform } from './upskilling/register-mentors/ValidateEmail/register.transform';
import { UploadS3FilesRequest } from './common/upload-s3-files/upload-s3-files.service';
import {
  UploadS3FilesOptions, UploadS3FilesResponse, UploadS3FilesResults, UploadS3FilesService,
} from './common/upload-s3-files/upload-s3-files.service.types';
import { UploadS3FilesTransform } from './common/upload-s3-files/upload-s3-files.transform';
import { ForgotPasswordTransform } from './common/forgot-password/forgot-password.transform';
import { ListJobsTransform } from './staffing/job-board/list-jobs/list-jobs.transform';
import { CreatePostRequest } from './campus/dashboard/post/create-post-college/create-post.service';
import {
  CreatePostService, CreatePostOptions, CreatePostResponse, CreatePostResults,
} from './campus/dashboard/post/create-post-college/create-post.service.types';
import { CreatePostTransform } from './campus/dashboard/post/create-post-college/create-post.transform';
import { GetS3FilesRequest } from './common/get-s3-files/get-s3files.service';
import {
  GetS3FilesService, GetS3FilesOptions, GetS3FilesResponse, GetS3FilesResults,
} from './common/get-s3-files/get-s3files.service.types';
import { GetS3FilesTransform } from './common/get-s3-files/get-s3files.transform';
import { UpdateS3CxMetaRequest } from './aIntelligence/update-cx-meta/update-s3-cx-meta.service';
import {
  UpdateS3CxMetaService, UpdateS3CxMetaOptions, UpdateS3CxMetaResponse, UpdateS3CxMetaResults,
} from './aIntelligence/update-cx-meta/update-s3-cx-meta.service.types';
import { UpdateS3CxMetaTransform } from './aIntelligence/update-cx-meta/update-s3-cx-meta.transform';
import { GetS3CxMetaRequest } from './aIntelligence/get-all-cx-meta/get-s3-cx-meta.service';
import {
  GetS3CxMetaService, GetS3CxMetaOptions, GetS3CxMetaResponse, GetS3CxMetaResults,
} from './aIntelligence/get-all-cx-meta/get-s3-cx-meta.service.types';
import { GetS3CxMetaTransform } from './aIntelligence/get-all-cx-meta/get-s3-cx-meta.transform';
import { ClientAddRequest } from './sadmin/add-clients/add-new-clients.service';
import {
  ClientAddService, ClientAddOptions, ClientAddResponse, ClientAddResults,
} from './sadmin/add-clients/add-new-clients.service.types';
import { ClientAddTransform } from './sadmin/add-clients/add-new-clients.transform';
import { ListClientRequest } from './sadmin/list-client/list-client.service';
import {
  ListClientService, ListClientOptions, ListClientResponse, ListClientResults,
} from './sadmin/list-client/list-client.service.types';
import { ListClientTransform } from './sadmin/list-client/list-client.transform';
import { ClientUserAddRequest } from './sadmin/add-client-user/add-new-client-user.service';
import {
  ClientUserAddService, ClientUserAddOptions, ClientUserAddResponse, ClientUserAddResults,
} from './sadmin/add-client-user/add-new-client-user.service.types';
import { ClientUserAddTransform } from './sadmin/add-client-user/add-new-client-user.transform';
import { ListClientUserRequest } from './sadmin/list-client-user/list-client-user.service';
import {
  ListClientUserService, ListClientUserOptions, ListClientUserResponse, ListClientUserResults,
} from './sadmin/list-client-user/list-client-user.service.types';
import { ListClientUserTransform } from './sadmin/list-client-user/list-client-user.transform';
import { DELClientRequest } from './sadmin/delete-client/del-client.service';
import {
  DELClientService, DELClientOptions, DELClientResponse, DELClientResults,
} from './sadmin/delete-client/del-client.service.types';
import { DELClientTransform } from './sadmin/delete-client/del-client.transform';
import { ListCandidatesRequest } from './campus/college-admin/acad-students/list-candidates/list-candidates.service';
import {
  ListCandidatesService, ListCandidatesOptions, ListCandidatesResponse, ListCandidatesResults,
} from './campus/college-admin/acad-students/list-candidates/list-candidates.service.types';
import { ListCandidatesTransform } from './campus/college-admin/acad-students/list-candidates/list-candidates.transform';
import { CandPutStsRequest } from './campus/college-admin/acad-students/update-candidate-sts/update-cand-sts.service';
import {
  CandPutStsService, CandPutStsOptions, CandPutStsResponse, CandPutStsResults,
} from './campus/college-admin/acad-students/update-candidate-sts/update-cand-sts.service.types';
import { CandPutStsTransform } from './campus/college-admin/acad-students/update-candidate-sts/update-cand-sts.transform';
import { ResumeVideoRequest } from './campus/resume-data/video-profile/get-video-url/get-video.service';
import {
  ResumeVideoService, ResumeVideoOptions, ResumeVideoResponse, ResumeVideoResults,
} from './campus/resume-data/video-profile/get-video-url/get-video.service.types';
import { ResumeVideoTransform } from './campus/resume-data/video-profile/get-video-url/get-video.transform';
import { CampusVideoPutRequest } from './campus/resume-data/video-profile/update-video/video.service';
import {
  VideoService, VideoOptions, VideoResponse, VideoResults,
} from './campus/resume-data/video-profile/update-video/video.service.types';
import { VideoTransform } from './campus/resume-data/video-profile/update-video/video.transform';

const response = 'Data has been loaded successfully!';

export function loadData(): Promise<string> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(response);
      if (response === undefined) {
        reject();
      }
    }, 500);
  });
}
export const contactUsService: ContactUsService = createService<ContactUsOptions, ContactUsResponse, ContactUsResults>(ContactUsPostRequest, ContactUsTransform);
export const registerCMPSService: RegisterCMPSService = createService<RegisterCMPSOptions, RegisterCMPSResponse, RegisterCMPSResults>(RegisterCMPSPostRequest, RegisterCMPSTransform);
export const genTokenService: GenTokenService = createService<GenTokenOptions, GenTokenResponse, GenTokenResults>(GenTokenRequest, GenTokenTransform);
export const forgotPasswordService: ForgotPasswordService = createService<ForgotPasswordOptions, ForgotPasswordResponse, ForgotPasswordResults>(ForgotPasswordRequest, ForgotPasswordTransform);
export const profileService: ProfileService = createService<ProfileOptions, ProfileResponse, ProfileResults>(ProfileRequest, ProfileTransform);
export const fileURLService: FileURLService = createService<FileURLOptions, FileURLResponse, FileURLResults>(FileURLRequest, FileURLTransform);
export const userProfilePutService: UserProfilePutService = createService<UserProfilePutOptions, UserProfilePutResponse, UserProfilePutResults>(UserProfilePutRequest, UserProfilePutTransform);
export const uploadS3FilesService: UploadS3FilesService = createService<UploadS3FilesOptions, UploadS3FilesResponse, UploadS3FilesResults>(UploadS3FilesRequest, UploadS3FilesTransform);
export const getS3FilesService: GetS3FilesService = createService<GetS3FilesOptions, GetS3FilesResponse, GetS3FilesResults>(GetS3FilesRequest, GetS3FilesTransform);
export const passKeyService: PassKeyPutService = createService<PassKeyPutOptions, PassKeyPutResponse, PassKeyPutResults>(PassKeyPutRequest, PassKeyPutTransform);
export const resumeProfileService: ResumeProfileService = createService<ResumeProfileOptions, ResumeProfileResponse, ResumeProfileResults>(ResumeProfileRequest, ResumeProfileTransform);
export const getResumeObjService: ResumeObjService = createService<ResumeObjOptions, ResumeObjResponse, ResumeObjResults>(ResumeObjRequest, ResumeObjTransform);
export const objectiveService: ObjectiveService = createService<ObjectiveOptions, ObjectiveResponse, ObjectiveResults>(CampusObjectivePutRequest, ObjectiveTransform);
export const listOrgProjService: ListOrgProjService = createService<ListOrgProjOptions, ListOrgProjResponse, ListOrgProjResults>(ListOrgProjRequest, ListOrgProjTransform);
export const listPJobsService: ListPJobsService = createService<ListPJobsOptions, ListPJobsResponse, ListPJobsResults>(ListPJobsRequest, ListPJobsTransform);
export const jobStatusService: JobStatusService = createService<JobStatusOptions, JobStatusResponse, JobStatusResults>(JobStatusRequest, JobStatusTransform);
export const listAPLPJobsService: ListPJobsService = createService<ListPJobsOptions, ListPJobsResponse, ListPJobsResults>(ListPJobsRequest, ListPJobsTransform);
export const listSVDPJobsService: ListPJobsService = createService<ListPJobsOptions, ListPJobsResponse, ListPJobsResults>(ListPJobsRequest, ListPJobsTransform);
export const listJobsService: ListJobsService = createService<ListJobsOptions, ListJobsResponse, ListJobsResults>(ListJobsRequest, ListJobsTransform);
export const jobPositionByIdsService: JobPositionByIdService = createService<JobPositionByIdOptions, JobPositionByIdResponse, JobPositionByIdResults>(JobPositionByIdRequest, JobPositionByIdTransform);
export const orgProjService: OrgProjService = createService<OrgProjOptions, OrgProjResponse, OrgProjResults>(CampusOrgProjPutRequest, OrgProjTransform);
export const deleteOrgProjService: DelOrgProjService = createService<DelOrgProjOptions, DelOrgProjResponse, DelOrgProjResults>(DelOrgProjPutRequest, DelOrgProjTransform);
export const getResumePreviewService: ResumePreviewService = createService<ResumePreviewOptions, ResumePreviewResponse, ResumePreviewResults>(ResumePreviewRequest, ResumePreviewTransform);
// export const userProfileVideoService: UploadVideoService = createService<UploadVideoOptions, UploadVideoResponse, UploadVideoResults>(UploadVideoRequest, UploadVideoTransform);
// export const videoURLService: VideoURLService = createService<VideoURLOptions, VideoURLResponse, VideoURLResults>(VideoURLRequest, VideoURLTransform);
export const hobbiesService: HobbiesPutService = createService<HobbiesPutOptions, HobbiesPutResponse, HobbiesPutResults>(HobbiesPutRequest, HobbiesPutTransform);
export const getHobbiesService: GetHobbiesService = createService<GetHobbiesOptions, GetHobbiesResponse, GetHobbiesResults>(GetHobbiesRequest, GetHobbiesTransform);
export const certService: CertPutService = createService<CertPutOptions, CertPutResponse, CertPutResults>(CertPutRequest, CertPutTransform);
export const getCertService: GetCertService = createService<GetCertOptions, GetCertResponse, GetCertResults>(GetCertRequest, GetCertTransform);
export const postPublishService: CreatePostService = createService<CreatePostOptions, CreatePostResponse, CreatePostResults>(CreatePostRequest, CreatePostTransform);
export const postListService: ListPostService = createService<ListPostOptions, ListPostResponse, ListPostResults>(ListPostRequest, ListPostTransform);
export const postDBService: PostDBService = createService<PostDBOptions, PostDBResponse, PostDBResults>(PostDBRequest, PostDBTransform);
export const delPostPublishService: DelPostService = createService<DelPostOptions, DelPostResponse, DelPostResults>(DelPostRequest, DelPostTransform);
export const addFollowService: FollowService = createService<FollowOptions, FollowResponse, FollowResults>(FollowPutRequest, FollowTransform);
export const addCommentService: CommentService = createService<CommentOptions, CommentResponse, CommentResults>(CommentPutRequest, CommentTransform);
export const addLikeService: LikeService = createService<LikeOptions, LikeResponse, LikeResults>(LikePutRequest, LikeTransform);
export const commentListService: ListCommentService = createService<ListCommentOptions, ListCommentResponse, ListCommentResults>(ListCommentRequest, ListCommentTransform);
export const userLikesListService: ListUserLikesService = createService<ListUserLikesOptions, ListUserLikesResponse, ListUserLikesResults>(ListUserLikesRequest, ListUserLikesTransform);
export const batchService: BatchAddService = createService<BatchAddOptions, BatchAddResponse, BatchAddResults>(BatchAddRequest, BatchAddTransform);
export const batchListService: ListBatchService = createService<ListBatchOptions, ListBatchResponse, ListBatchResults>(ListBatchRequest, ListBatchTransform);
export const candUpdtStsService: CandPutStsService = createService<CandPutStsOptions, CandPutStsResponse, CandPutStsResults>(CandPutStsRequest, CandPutStsTransform);
export const candidatesListService: ListCandidatesService = createService<ListCandidatesOptions, ListCandidatesResponse, ListCandidatesResults>(ListCandidatesRequest, ListCandidatesTransform);
export const registerEmailMNTRSService: RegisterEmailMNTRSService = createService<RegisterEmailMNTRSOptions, RegisterEmailMNTRSResponse, RegisterEmailMNTRSResults>(RegisterEmailMNTRSPostRequest, RegisterEmailMNTRSTransform);
export const registerPrsnlMNTRSService: RegisterPrsnlMNTRSService = createService<RegisterPrsnlMNTRSOptions, RegisterPrsnlMNTRSResponse, RegisterPrsnlMNTRSResults>(RegisterPrsnlMNTRSRequest, RegisterPrsnlMNTRSTransform);
export const registerAbtMeMNTRSService: RegisterAbtMeMNTRSService = createService<RegisterAbtMeMNTRSOptions, RegisterAbtMeMNTRSResponse, RegisterAbtMeMNTRSResults>(RegisterAbtMeMNTRSRequest, RegisterAbtMeMNTRSTransform);
export const registerEmployMNTRSService: RegisterEmployMNTRSService = createService<RegisterEmployMNTRSOptions, RegisterEmployMNTRSResponse, RegisterEmployMNTRSResults>(RegisterEmployMNTRSRequest, RegisterEmployMNTRSTransform);
export const registerXCLNService: RegisterXCLNService = createService<RegisterXCLNOptions, RegisterXCLNResponse, RegisterXCLNResults>(RegisterXCLNPostRequest, RegisterXCLNTransform);
export const addClientService: ClientAddService = createService<ClientAddOptions, ClientAddResponse, ClientAddResults>(ClientAddRequest, ClientAddTransform);
export const clientListService: ListClientService = createService<ListClientOptions, ListClientResponse, ListClientResults>(ListClientRequest, ListClientTransform);
export const mentorListService: ListMentorService = createService<ListMentorOptions, ListMentorResponse, ListMentorResults>(ListMentorRequest, ListMentorTransform);
export const mentorAccessService: MentorAccessUpdateService = createService<MentorAccessUpdateOptions, MentorAccessUpdateResponse, MentorAccessUpdateResults>(CampusMentorAccessUpdatePutRequest, MentorAccessUpdateTransform);
export const updateS3CxMetaService: UpdateS3CxMetaService = createService<UpdateS3CxMetaOptions, UpdateS3CxMetaResponse, UpdateS3CxMetaResults>(UpdateS3CxMetaRequest, UpdateS3CxMetaTransform);
export const getS3CxMetaService: GetS3CxMetaService = createService<GetS3CxMetaOptions, GetS3CxMetaResponse, GetS3CxMetaResults>(GetS3CxMetaRequest, GetS3CxMetaTransform);
export const addClientUserService: ClientUserAddService = createService<ClientUserAddOptions, ClientUserAddResponse, ClientUserAddResults>(ClientUserAddRequest, ClientUserAddTransform);
export const clientUserListService: ListClientUserService = createService<ListClientUserOptions, ListClientUserResponse, ListClientUserResults>(ListClientUserRequest, ListClientUserTransform);
export const delClientService: DELClientService = createService<DELClientOptions, DELClientResponse, DELClientResults>(DELClientRequest, DELClientTransform);
export const getResumeVideoService: ResumeVideoService = createService<ResumeVideoOptions, ResumeVideoResponse, ResumeVideoResults>(ResumeVideoRequest, ResumeVideoTransform);
export const videoService: VideoService = createService<VideoOptions, VideoResponse, VideoResults>(CampusVideoPutRequest, VideoTransform);
