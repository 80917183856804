import { createAction } from 'typesafe-actions';
import {
  ADD_CLIENT_DATA,
  ADD_CLIENT_USER_DATA,
  API_ERROR,
  BATCH_DATA,
  CLEAR_ADD_CLIENT_DATA,
  CLEAR_ADD_CLIENT_USER_DATA,
  CLEAR_BATCH_DATA,
  CLEAR_CAND_LIST_DATA,
  CLEAR_CERT,
  CLEAR_COMMENT,
  CLEAR_COMMENT_DATA,
  CLEAR_CONTACT_US_DATA,
  CLEAR_DEL_CLIENT,
  CLEAR_FOLLOW_DATA,
  CLEAR_GET_CLIENT_DATA,
  CLEAR_GET_CLIENT_USER_DATA,
  CLEAR_GET_S3_FILES_DATA,
  CLEAR_GET_S3_META_DATA,
  CLEAR_GET_VIDEO_URL,
  CLEAR_HOBBIES,
  CLEAR_JOB_POSITION_BY_ID,
  CLEAR_JOB_STATUS_DATA,
  CLEAR_LIKE_DATA,
  CLEAR_LOGIN_TOKEN,
  CLEAR_MENTOR_ACCESS,
  CLEAR_MNTR_STS_LIST_DATA,
  CLEAR_OBJECTIVE,
  CLEAR_ORG_ORG_PROJ,
  CLEAR_ORG_POST_PUBLISH,
  CLEAR_PASS_KEY_DATA,
  CLEAR_POST,
  CLEAR_POST_DB_DATA,
  CLEAR_PROFILE,
  CLEAR_REGISTER_ABTME_MNTR_DATA,
  CLEAR_REGISTER_CMPS_DATA,
  CLEAR_REGISTER_EMAIL_MNTR_DATA,
  CLEAR_REGISTER_EMPLOY_MNTR_DATA,
  CLEAR_REGISTER_PRSNL_MNTR_DATA,
  CLEAR_REGISTER_XCLN_DATA,
  CLEAR_RESULT_CERT,
  CLEAR_RESULT_HOBBIES,
  CLEAR_RESUME_PROFILE,
  CLEAR_UPDATE_S3_META_DATA,
  CLEAR_UPLOAD_S3_FILES_DATA,
  CLEAR_USER_LIKES,
  CLEAR_USER_PROFILE_PUT_DATA,
  COMMENT_DATA,
  CONTACT_US_DATA,
  DELETE_ORG_PROJ,
  DEL_CLIENT,
  DEL_POST_PUBLISH,
  FOLLOW_DATA,
  GET_ALL_JOBS,
  GET_ALL_P_JOBS,
  GET_APL_JOBS,
  GET_BATCH_LIST,
  GET_CERT,
  GET_CLIENT_LIST,
  GET_CLIENT_USER_LIST,
  GET_COMMENT_LIST,
  GET_FILE_URL,
  GET_FORGOT_PASSWORD,
  GET_HOBBIES,
  GET_JOB_POSITION_BY_ID,
  GET_JOB_STATUS,
  GET_LIST_ORG_PROJ,
  GET_LOGIN_TOKEN,
  GET_MENTOR_ACCESS,
  GET_MNTR_STS_LIST,
  GET_OBJECTIVE,
  GET_ORG_PROJ,
  GET_CANDIDATES_LIST,
  GET_POST_LIST,
  GET_POST_PUBLISH,
  GET_PREVIEW_RESUME,
  GET_PROFILE,
  GET_RESULT_CERT,
  GET_RESULT_HOBBIES,
  GET_RESUME_OBJ,
  GET_RESUME_PROFILE,
  GET_S3_FILES_DATA,
  GET_S3_META_DATA,
  GET_SVD_JOBS,
  GET_UPDATE_S3_META_DATA,
  GET_USER_LIKES_LIST,
  LIKE_DATA,
  PASS_KEY_DATA,
  POST_DB_DATA,
  REGISTER_ABTME_MNTR_DATA,
  REGISTER_CMPS_DATA,
  REGISTER_EMAIL_MNTR_DATA,
  REGISTER_EMPLOY_MNTR_DATA,
  REGISTER_PRSNL_MNTR_DATA,
  REGISTER_XCLN_DATA,
  SHOW_DATA,
  UPDATE_ADD_CLIENT_DATA,
  UPDATE_ADD_CLIENT_USER_DATA,
  UPDATE_ALL_JOBS,
  UPDATE_ALL_P_JOBS,
  UPDATE_APL_JOBS,
  UPDATE_BATCH_DATA,
  UPDATE_BATCH_LIST,
  UPDATE_CERT,
  UPDATE_COMMENT_DATA,
  UPDATE_CONTACT_US_DATA,
  UPDATE_DATA,
  UPDATE_DELETE_ORG_PROJ,
  UPDATE_DEL_CLIENT,
  UPDATE_DEL_POST_PUBLISH,
  UPDATE_FOLLOW_DATA,
  UPDATE_FORGOT_PASSWORD,
  UPDATE_GET_CLIENT_LIST,
  UPDATE_GET_CLIENT_USER_LIST,
  UPDATE_GET_COMMENT_LIST,
  UPDATE_GET_FILE_URL,
  UPDATE_GET_POST_LIST,
  UPDATE_GET_S3_FILES_DATA,
  UPDATE_GET_USER_LIKES_LIST,
  UPDATE_HOBBIES,
  UPDATE_JOB_POSITION_BY_ID,
  UPDATE_JOB_STATUS_DATA,
  UPDATE_LIKE_DATA,
  UPDATE_LIST_ORG_PROJ,
  UPDATE_LOGIN_TOKEN,
  UPDATE_MENTOR_ACCESS,
  UPDATE_MNTR_STS_LIST,
  UPDATE_OBJECTIVE,
  UPDATE_ORG_PROJ,
  UPDATE_PASS_KEY_DATA,
  UPDATE_CANDIDATES_LIST,
  UPDATE_POST_DB_DATA,
  UPDATE_POST_PUBLISH,
  UPDATE_PREVIEW_RESUME,
  UPDATE_PROFILE,
  UPDATE_REGISTER_ABTME_MNTR_DATA,
  UPDATE_REGISTER_CMPS_DATA,
  UPDATE_REGISTER_EMAIL_MNTR_DATA,
  UPDATE_REGISTER_EMPLOY_MNTR_DATA,
  UPDATE_REGISTER_PRSNL_MNTR_DATA,
  UPDATE_REGISTER_XCLN_DATA,
  UPDATE_RESULT_CERT,
  UPDATE_RESULT_HOBBIES,
  UPDATE_RESUME_OBJ,
  UPDATE_RESUME_PROFILE,
  UPDATE_S3_META_DATA,
  UPDATE_SVD_JOBS,
  UPDATE_UPDATE_S3_META_DATA,
  UPDATE_UPLOAD_S3_FILES_DATA,
  UPDATE_USER_PROFILE_PUT_DATA,
  UPLOAD_S3_FILES_DATA,
  USER_PROFILE_PUT_DATA,
  CAND_STS_DATA,
  CLEAR_CAND_STS_DATA,
  UPDATE_CAND_STS_DATA,
  CLEAR_RESUME_OBJ,
  CLEAR_RESUME_VIDEO,
  GET_RESUME_VIDEO,
  UPDATE_RESUME_VIDEO,
  CLEAR_VIDEO,
  GET_VIDEO,
  UPDATE_VIDEO,
} from './constants';
import { ErrorAction } from '../../services/types';
import {
  GetCertOptions,
  GetCertResults,
} from '../../services/campus/certifications/get-cert/get-cert.service.types';
import {
  CertPutOptions,
  CertPutResults,
} from '../../services/campus/certifications/update-cert/update-cert.service.types';
import {
  BatchAddOptions,
  BatchAddResults,
} from '../../services/campus/college-admin/cmps-batches/add-batch/add-batch.service.types';
import {
  ListBatchOptions,
  ListBatchResults,
} from '../../services/campus/college-admin/cmps-batches/list-batch/list-batch.service.types';
import {
  CommentOptions,
  CommentResults,
} from '../../services/campus/dashboard/comments/add-comment/comment.service.types';
import {
  ListCommentOptions,
  ListCommentResults,
} from '../../services/campus/dashboard/comments/get-all-comments/list-comment.service.types';
import {
  FollowOptions,
  FollowResults,
} from '../../services/campus/dashboard/follow/add-follow/follow.service.types';
import {
  LikeOptions,
  LikeResults,
} from '../../services/campus/dashboard/likes/add-like/like.service.types';
import {
  ListUserLikesOptions,
  ListUserLikesResults,
} from '../../services/campus/dashboard/likes/get-all-likes/list-user-likes.service.types';
import {
  PostDBOptions,
  PostDBResults,
} from '../../services/campus/dashboard/post/add-post-db/post-db.service.types';
import {
  DelPostOptions,
  DelPostResults,
} from '../../services/campus/dashboard/post/del-post-file-s3/del-post.service.types';
import {
  ListPostOptions,
  ListPostResults,
} from '../../services/campus/dashboard/post/get-all-posts/list-post.service.types';
import {
  GetHobbiesOptions,
  GetHobbiesResults,
} from '../../services/campus/hobbies/get-hobbies/get-hobbies.service.types';
import {
  HobbiesPutOptions,
  HobbiesPutResults,
} from '../../services/campus/hobbies/update-hobbies/update-hobbies.service.types';
import {
  RegisterCMPSOptions,
  RegisterCMPSResults,
} from '../../services/campus/register-campus/register.service.types';
import {
  ResumeProfileOptions,
  ResumeProfileResults,
} from '../../services/campus/resume-data/get-cand-profile/get-profile.service.types';
import {
  ResumeObjOptions,
  ResumeObjResults,
} from '../../services/campus/resume-data/objective/get-obj/get-obj.service.types';
import {
  ObjectiveOptions,
  ObjectiveResults,
} from '../../services/campus/resume-data/objective/update-obj/objective.service.types';
import {
  DelOrgProjOptions,
  DelOrgProjResults,
} from '../../services/campus/resume-data/org-project/delete-org-proj/del-org-proj.service.types';
import {
  ListOrgProjOptions,
  ListOrgProjResults,
} from '../../services/campus/resume-data/org-project/get-all-org-obj/list-org-proj.service.types';
import {
  OrgProjOptions,
  OrgProjResults,
} from '../../services/campus/resume-data/org-project/update-org-obj/org-proj.service.types';
import {
  ResumePreviewOptions,
  ResumePreviewResults,
} from '../../services/campus/resume-data/preview/get-preview.service.types';
import {
  ForgotPasswordOptions,
  ForgotPasswordResults,
} from '../../services/common/forgot-password/forgot-password.service.types';
import {
  GenTokenOptions,
  GenTokenResults,
} from '../../services/common/gen-token/gen-token.service.types';
import {
  FileURLOptions,
  FileURLResults,
} from '../../services/common/get-file-url/get-file-url.service.types';
import {
  ProfileOptions,
  ProfileResults,
} from '../../services/common/get-profile/get-profile.service.types';
import {
  PassKeyPutOptions,
  PassKeyPutResults,
} from '../../services/common/update-passkey/update-passkey.service.types';
import {
  UserProfilePutOptions,
  UserProfilePutResults,
} from '../../services/common/update-profile/update-profile.service.types';
import {
  JobPositionByIdOptions,
  JobPositionByIdResults,
} from '../../services/staffing/job-board/job-position-by-id/job-position-by-id.service.types';
import {
  ListJobsOptions,
  ListJobsResults,
} from '../../services/staffing/job-board/list-jobs/list-jobs.service.types';
import {
  ListPJobsOptions,
  ListPJobsResults,
} from '../../services/staffing/job-board/list-personalized-jobs/list-p-jobs.service.types';
import {
  JobStatusOptions,
  JobStatusResults,
} from '../../services/staffing/job-board/update-job-status/job-status.service.types';
import {
  RegisterXCLNOptions,
  RegisterXCLNResults,
} from '../../services/staffing/xcelian/register-xcelian/register.service.types';
import {
  MentorAccessUpdateOptions,
  MentorAccessUpdateResults,
} from '../../services/upskilling/mentors/mentor-access-update/mentor-update.service.types';
import {
  ListMentorOptions,
  ListMentorResults,
} from '../../services/upskilling/mentors/mentor-list/list-mentor.service.types';
import {
  RegisterAbtMeMNTRSOptions,
  RegisterAbtMeMNTRSResults,
} from '../../services/upskilling/register-mentors/About/register.service.types';
import {
  RegisterPrsnlMNTRSOptions,
  RegisterPrsnlMNTRSResults,
} from '../../services/upskilling/register-mentors/Personal/register.service.types';
import {
  RegisterEmployMNTRSOptions,
  RegisterEmployMNTRSResults,
} from '../../services/upskilling/register-mentors/Prof-Exp/register.service.types';
import {
  RegisterEmailMNTRSOptions,
  RegisterEmailMNTRSResults,
} from '../../services/upskilling/register-mentors/ValidateEmail/register.service.types';
import {
  UploadS3FilesOptions,
  UploadS3FilesResults,
} from '../../services/common/upload-s3-files/upload-s3-files.service.types';
import {
  CreatePostOptions,
  CreatePostResults,
} from '../../services/campus/dashboard/post/create-post-college/create-post.service.types';
import {
  GetS3FilesOptions,
  GetS3FilesResults,
} from '../../services/common/get-s3-files/get-s3files.service.types';
import {
  UpdateS3CxMetaOptions,
  UpdateS3CxMetaResults,
} from '../../services/aIntelligence/update-cx-meta/update-s3-cx-meta.service.types';
import {
  GetS3CxMetaOptions,
  GetS3CxMetaResults,
} from '../../services/aIntelligence/get-all-cx-meta/get-s3-cx-meta.service.types';
import {
  ClientAddOptions,
  ClientAddResults,
} from '../../services/sadmin/add-clients/add-new-clients.service.types';
import {
  ContactUsOptions,
  ContactUsResults,
} from '../../services/sadmin/contact-us/contact-us.service.types';
import {
  ListClientOptions,
  ListClientResults,
} from '../../services/sadmin/list-client/list-client.service.types';
import { ClientUserAddOptions, ClientUserAddResults } from '../../services/sadmin/add-client-user/add-new-client-user.service.types';
import { ListClientUserOptions, ListClientUserResults } from '../../services/sadmin/list-client-user/list-client-user.service.types';
import { DELClientOptions, DELClientResults } from '../../services/sadmin/delete-client/del-client.service.types';
import { ListCandidatesOptions, ListCandidatesResults } from '../../services/campus/college-admin/acad-students/list-candidates/list-candidates.service.types';
import { CandPutStsOptions, CandPutStsResults } from '../../services/campus/college-admin/acad-students/update-candidate-sts/update-cand-sts.service.types';
import { ResumeVideoOptions, ResumeVideoResults } from '../../services/campus/resume-data/video-profile/get-video-url/get-video.service.types';
import { VideoOptions, VideoResults } from '../../services/campus/resume-data/video-profile/update-video/video.service.types';

export const apiError = createAction(API_ERROR)<ErrorAction>();
export const showData = createAction(SHOW_DATA)<{}>();
export const updateData = createAction(UPDATE_DATA)<string>();
export const contactUsPostReq = createAction(CONTACT_US_DATA)<ContactUsOptions>();
export const updateContactUsPostReq = createAction(
  UPDATE_CONTACT_US_DATA,
)<ContactUsResults>();
export const clearContactUsPostReq = createAction(CLEAR_CONTACT_US_DATA)();
export const registerCMPSReq = createAction(REGISTER_CMPS_DATA)<RegisterCMPSOptions>();
export const updateRegisterCMPSPostReq = createAction(
  UPDATE_REGISTER_CMPS_DATA,
)<RegisterCMPSResults>();
export const clearRegisterCMPSPostReq = createAction(
  CLEAR_REGISTER_CMPS_DATA,
)();
export const getLoginToken = createAction(GET_LOGIN_TOKEN)<GenTokenOptions>();
export const updateToken = createAction(UPDATE_LOGIN_TOKEN)<GenTokenResults>();
export const clearLoginToken = createAction(CLEAR_LOGIN_TOKEN)();
export const getProfile = createAction(GET_PROFILE)<ProfileOptions>();
export const updateProfile = createAction(UPDATE_PROFILE)<ProfileResults>();
export const clearProfileReq = createAction(CLEAR_PROFILE)();
export const getForgotPassword = createAction(GET_FORGOT_PASSWORD)<ForgotPasswordOptions>();
export const updateForgotPassword = createAction(
  UPDATE_FORGOT_PASSWORD,
)<ForgotPasswordResults>();
export const getFileURLReq = createAction(GET_FILE_URL)<FileURLOptions>();
export const updateFileURLReq = createAction(UPDATE_GET_FILE_URL)<FileURLResults>();
export const userProfilePutReq = createAction(
  USER_PROFILE_PUT_DATA,
)<UserProfilePutOptions>();
export const updateUserProfilePutReq = createAction(
  UPDATE_USER_PROFILE_PUT_DATA,
)<UserProfilePutResults>();
export const clearUserProfilePutReq = createAction(
  CLEAR_USER_PROFILE_PUT_DATA,
)();
export const passKeyReq = createAction(PASS_KEY_DATA)<PassKeyPutOptions>();
export const updatePassKeyReq = createAction(UPDATE_PASS_KEY_DATA)<PassKeyPutResults>();
export const clearPassKeyReq = createAction(CLEAR_PASS_KEY_DATA)();
export const uploadS3FilesReq = createAction(UPLOAD_S3_FILES_DATA)<UploadS3FilesOptions>();
export const updateUploadS3FilesReq = createAction(
  UPDATE_UPLOAD_S3_FILES_DATA,
)<UploadS3FilesResults>();
export const clearUploadS3FilesReq = createAction(CLEAR_UPLOAD_S3_FILES_DATA)();
export const getS3FilesReq = createAction(GET_S3_FILES_DATA)<GetS3FilesOptions>();
export const updateGetS3FilesReq = createAction(
  UPDATE_GET_S3_FILES_DATA,
)<GetS3FilesResults>();
export const clearGetS3FilesReq = createAction(CLEAR_GET_S3_FILES_DATA)();
export const getResumeProfile = createAction(GET_RESUME_PROFILE)<ResumeProfileOptions>();
export const updateResumeProfile = createAction(
  UPDATE_RESUME_PROFILE,
)<ResumeProfileResults>();
export const clearResumeProfile = createAction(CLEAR_RESUME_PROFILE)();
export const getResumeDataObj = createAction(GET_RESUME_OBJ)<ResumeObjOptions>();
export const updateResumeDataObj = createAction(UPDATE_RESUME_OBJ)<ResumeObjResults>();
export const clearResumeDataObj = createAction(CLEAR_RESUME_OBJ)();
export const getObjective = createAction(GET_OBJECTIVE)<ObjectiveOptions>();
export const updateObjective = createAction(UPDATE_OBJECTIVE)<ObjectiveResults>();
export const clearObjective = createAction(CLEAR_OBJECTIVE)();
export const getResumeVideo = createAction(GET_RESUME_VIDEO)<ResumeVideoOptions>();
export const updateResumeVideo = createAction(UPDATE_RESUME_VIDEO)<ResumeVideoResults>();
export const clearResumeVideo = createAction(CLEAR_RESUME_VIDEO)();
export const getVideo = createAction(GET_VIDEO)<VideoOptions>();
export const updateVideo = createAction(UPDATE_VIDEO)<VideoResults>();
export const clearVideo = createAction(CLEAR_VIDEO)();
export const getOrgProject = createAction(GET_ORG_PROJ)<OrgProjOptions>();
export const updateOrgProject = createAction(UPDATE_ORG_PROJ)<OrgProjResults>();
export const clearOrgProject = createAction(CLEAR_ORG_ORG_PROJ)();
export const getListOrgProj = createAction(GET_LIST_ORG_PROJ)<ListOrgProjOptions>();
export const updateListOrgProj = createAction(UPDATE_LIST_ORG_PROJ)<ListOrgProjResults>();
export const getAllJobs = createAction(GET_ALL_JOBS)<ListJobsOptions>();
export const updateAllJobs = createAction(UPDATE_ALL_JOBS)<ListJobsResults>();
export const getJobPositonById = createAction(
  GET_JOB_POSITION_BY_ID,
)<JobPositionByIdOptions>();
export const updateJobPositonById = createAction(
  UPDATE_JOB_POSITION_BY_ID,
)<JobPositionByIdResults>();
export const clearJobPositonById = createAction(CLEAR_JOB_POSITION_BY_ID)();
export const getAllPJobs = createAction(GET_ALL_P_JOBS)<ListPJobsOptions>();
export const getAPLPJobs = createAction(GET_APL_JOBS)<ListPJobsOptions>();
export const getSVDPJobs = createAction(GET_SVD_JOBS)<ListPJobsOptions>();
export const updateAllPJobs = createAction(UPDATE_ALL_P_JOBS)<ListPJobsResults>();
export const updateAPLPJobs = createAction(UPDATE_APL_JOBS)<ListPJobsResults>();
export const updateSVDPJobs = createAction(UPDATE_SVD_JOBS)<ListPJobsResults>();
export const getJobStatus = createAction(GET_JOB_STATUS)<JobStatusOptions>();
export const updateJobStatusReq = createAction(
  UPDATE_JOB_STATUS_DATA,
)<JobStatusResults>();
export const clearJobStatusReq = createAction(CLEAR_JOB_STATUS_DATA)();
export const delOrgProj = createAction(DELETE_ORG_PROJ)<DelOrgProjOptions>();
export const updateDelOrgProjReq = createAction(
  UPDATE_DELETE_ORG_PROJ,
)<DelOrgProjResults>();
export const getPreviewResume = createAction(GET_PREVIEW_RESUME)<ResumePreviewOptions>();
export const updatePreviewResume = createAction(
  UPDATE_PREVIEW_RESUME,
)<ResumePreviewResults>();
// export const uploadVideoReq = createAction(UPLOAD_VIDEO_DATA)<UploadVideoOptions>();
// export const updateUploadVideoReq = createAction(UPDATE_UPLOAD_VIDEO_DATA)<UploadVideoResults>();
// export const clearUploadVideoReq = createAction(CLEAR_UPLOAD_VIDEO_DATA)();
// export const getVideoURLReq = createAction(GET_VIDEO_URL)<VideoURLOptions>();
// export const updateVideoURLReq = createAction(UPDATE_GET_VIDEO_URL)<VideoURLResults>();
export const clearVideoURLReq = createAction(CLEAR_GET_VIDEO_URL)();
export const getHobbies = createAction(GET_HOBBIES)<HobbiesPutOptions>();
export const updateHobbies = createAction(UPDATE_HOBBIES)<HobbiesPutResults>();
export const clearHobbies = createAction(CLEAR_HOBBIES)();
export const getResultHobbies = createAction(GET_RESULT_HOBBIES)<GetHobbiesOptions>();
export const updateResultHobbies = createAction(
  UPDATE_RESULT_HOBBIES,
)<GetHobbiesResults>();
export const clearResultHobbies = createAction(CLEAR_RESULT_HOBBIES)();
export const getCert = createAction(GET_CERT)<CertPutOptions>();
export const updateCert = createAction(UPDATE_CERT)<CertPutResults>();
export const clearCert = createAction(CLEAR_CERT)();
export const getResultCert = createAction(GET_RESULT_CERT)<GetCertOptions>();
export const updateResultCert = createAction(UPDATE_RESULT_CERT)<GetCertResults>();
export const clearResultCert = createAction(CLEAR_RESULT_CERT)();
export const getPostPublish = createAction(GET_POST_PUBLISH)<CreatePostOptions>();
export const updatePostPublish = createAction(UPDATE_POST_PUBLISH)<CreatePostResults>();
export const clearPostPublish = createAction(CLEAR_ORG_POST_PUBLISH)();
export const getPostListReq = createAction(GET_POST_LIST)<ListPostOptions>();
export const updatePostListReq = createAction(UPDATE_GET_POST_LIST)<ListPostResults>();
export const clearPostListReq = createAction(CLEAR_POST)();
export const postDBReq = createAction(POST_DB_DATA)<PostDBOptions>();
export const updatePostDBReq = createAction(UPDATE_POST_DB_DATA)<PostDBResults>();
export const clearPostDBReq = createAction(CLEAR_POST_DB_DATA)();
export const delPostPublish = createAction(DEL_POST_PUBLISH)<DelPostOptions>();
export const updateDelPostPublish = createAction(
  UPDATE_DEL_POST_PUBLISH,
)<DelPostResults>();
export const addLikeReq = createAction(LIKE_DATA)<LikeOptions>();
export const updateLikeReq = createAction(UPDATE_LIKE_DATA)<LikeResults>();
export const clearLikeReq = createAction(CLEAR_LIKE_DATA)();
export const addCommentReq = createAction(COMMENT_DATA)<CommentOptions>();
export const updateCommentReq = createAction(UPDATE_COMMENT_DATA)<CommentResults>();
export const clearCommentReq = createAction(CLEAR_COMMENT_DATA)();
export const addFollowReq = createAction(FOLLOW_DATA)<FollowOptions>();
export const updateFollowReq = createAction(UPDATE_FOLLOW_DATA)<FollowResults>();
export const clearFollowReq = createAction(CLEAR_FOLLOW_DATA)();
export const getCommentListReq = createAction(GET_COMMENT_LIST)<ListCommentOptions>();
export const updateCommentListReq = createAction(
  UPDATE_GET_COMMENT_LIST,
)<ListCommentResults>();
export const clearCommentListReq = createAction(CLEAR_COMMENT)();
export const getUserLikesListReq = createAction(GET_USER_LIKES_LIST)<ListUserLikesOptions>();
export const updateUserLikesListReq = createAction(
  UPDATE_GET_USER_LIKES_LIST,
)<ListUserLikesResults>();
export const clearUserLikesListReq = createAction(CLEAR_USER_LIKES)();
export const batchReq = createAction(BATCH_DATA)<BatchAddOptions>();
export const updateBatchReq = createAction(UPDATE_BATCH_DATA)<BatchAddResults>();
export const clearBatchReq = createAction(CLEAR_BATCH_DATA)();
export const getBatchListReq = createAction(GET_BATCH_LIST)<ListBatchOptions>();
export const updateBatchListReq = createAction(UPDATE_BATCH_LIST)<ListBatchResults>();
export const candStsReq = createAction(CAND_STS_DATA)<CandPutStsOptions>();
export const updateCandStsReq = createAction(UPDATE_CAND_STS_DATA)<CandPutStsResults>();
export const clearCandStsReq = createAction(CLEAR_CAND_STS_DATA)();
export const getCandidatesListReq = createAction(GET_CANDIDATES_LIST)<ListCandidatesOptions>();
export const updateCandidatesListReq = createAction(UPDATE_CANDIDATES_LIST)<ListCandidatesResults>();
export const clearCandidatesListReq = createAction(CLEAR_CAND_LIST_DATA)();
export const registerEmailMNTRReq = createAction(
  REGISTER_EMAIL_MNTR_DATA,
)<RegisterEmailMNTRSOptions>();
export const updateRegisterEmailMNTRReq = createAction(
  UPDATE_REGISTER_EMAIL_MNTR_DATA,
)<RegisterEmailMNTRSResults>();
export const clearRegisterEmailMNTRReq = createAction(
  CLEAR_REGISTER_EMAIL_MNTR_DATA,
)();
export const registerPrsnlMNTRReq = createAction(
  REGISTER_PRSNL_MNTR_DATA,
)<RegisterPrsnlMNTRSOptions>();
export const updateRegisterPrsnlMNTRReq = createAction(
  UPDATE_REGISTER_PRSNL_MNTR_DATA,
)<RegisterPrsnlMNTRSResults>();
export const clearRegisterPrsnlMNTRReq = createAction(
  CLEAR_REGISTER_PRSNL_MNTR_DATA,
)();
export const registerAbtMeMNTRReq = createAction(
  REGISTER_ABTME_MNTR_DATA,
)<RegisterAbtMeMNTRSOptions>();
export const updateRegisterAbtMeMNTRReq = createAction(
  UPDATE_REGISTER_ABTME_MNTR_DATA,
)<RegisterAbtMeMNTRSResults>();
export const clearRegisterAbtMeMNTRReq = createAction(
  CLEAR_REGISTER_ABTME_MNTR_DATA,
)();
export const registerEmployMNTRReq = createAction(
  REGISTER_EMPLOY_MNTR_DATA,
)<RegisterEmployMNTRSOptions>();
export const updateRegisterEmployMNTRReq = createAction(
  UPDATE_REGISTER_EMPLOY_MNTR_DATA,
)<RegisterEmployMNTRSResults>();
export const clearRegisterEmployMNTRReq = createAction(
  CLEAR_REGISTER_EMPLOY_MNTR_DATA,
)();
export const registerXCLNReq = createAction(REGISTER_XCLN_DATA)<RegisterXCLNOptions>();
export const updateRegisterXCLNPostReq = createAction(
  UPDATE_REGISTER_XCLN_DATA,
)<RegisterXCLNResults>();
export const clearRegisterXCLNPostReq = createAction(
  CLEAR_REGISTER_XCLN_DATA,
)();
export const addClientReq = createAction(ADD_CLIENT_DATA)<ClientAddOptions>();
export const updateAddClientReq = createAction(
  UPDATE_ADD_CLIENT_DATA,
)<ClientAddResults>();
export const clearAddClientReq = createAction(CLEAR_ADD_CLIENT_DATA)();
export const getClientListReq = createAction(GET_CLIENT_LIST)<ListClientOptions>();
export const updateClientListReq = createAction(
  UPDATE_GET_CLIENT_LIST,
)<ListClientResults>();
export const clearGetClientReq = createAction(CLEAR_GET_CLIENT_DATA)();
export const getMentorStatusListReq = createAction(GET_MNTR_STS_LIST)<ListMentorOptions>();
export const updateMentorStatusListReq = createAction(UPDATE_MNTR_STS_LIST)<ListMentorResults>();
export const clearMentorStatusListReq = createAction(
  CLEAR_MNTR_STS_LIST_DATA,
)();
export const getMentorAccess = createAction(GET_MENTOR_ACCESS)<MentorAccessUpdateOptions>();
export const updateMentorAccess = createAction(UPDATE_MENTOR_ACCESS)<MentorAccessUpdateResults>();
export const clearMentorAccess = createAction(CLEAR_MENTOR_ACCESS)();
export const updateS3CxMetaReq = createAction(UPDATE_S3_META_DATA)<UpdateS3CxMetaOptions>();
export const updateUpdateS3CxMetaReq = createAction(
  UPDATE_UPDATE_S3_META_DATA,
)<UpdateS3CxMetaResults>();
export const clearUpdateS3CxMetaReq = createAction(CLEAR_UPDATE_S3_META_DATA)();
export const getS3CxMetaReq = createAction(GET_S3_META_DATA)<GetS3CxMetaOptions>();
export const getUpdateS3CxMetaReq = createAction(
  GET_UPDATE_S3_META_DATA,
)<GetS3CxMetaResults>();
export const clearGetS3CxMetaReq = createAction(CLEAR_GET_S3_META_DATA)();

export const addClientUserReq = createAction(ADD_CLIENT_USER_DATA)<ClientUserAddOptions>();
export const updateAddClientUserReq = createAction(
  UPDATE_ADD_CLIENT_USER_DATA,
)<ClientUserAddResults>();
export const clearAddClientUserReq = createAction(CLEAR_ADD_CLIENT_USER_DATA)();
export const getClientUserListReq = createAction(GET_CLIENT_USER_LIST)<ListClientUserOptions>();
export const updateClientUserListReq = createAction(
  UPDATE_GET_CLIENT_USER_LIST,
)<ListClientUserResults>();
export const clearGetClientUserReq = createAction(CLEAR_GET_CLIENT_USER_DATA)();
export const delClientReq = createAction(DEL_CLIENT)<DELClientOptions>();
export const updateDelClientReq = createAction(UPDATE_DEL_CLIENT)<DELClientResults>();
export const clearDelClientReq = createAction(CLEAR_DEL_CLIENT)();
