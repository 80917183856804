import {
  faShareFromSquare,
} from '@fortawesome/free-regular-svg-icons';
import {
  faMagnifyingGlass,
  faPersonDress, faUsersGear, faWheelchair,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardImg,
  Col, Input, Row, UncontrolledTooltip,
} from 'reactstrap';
import { useNavigate } from 'react-router';
import './jobs.scss';
import Offcanvas from '../../../utils/OffCanvas/OffcanvasComponent';
import { JobPublicDetails } from './job-details';

export const JobList: React.FC = () => {
  const history = useNavigate();
  const [imageURL] = useState('');
  const [jobDetailsOpen, setJobDetailsOpen] = React.useState<Record<string, boolean>>({});
  const [isTruncated, setIsTruncated] = useState(true);
  // const offcanvasRef = React.useRef<HTMLDivElement>(null);
  const maxLength = 130;
  const textData = 'The project management triangle seems to be a big theme in my coaching practice with overwhelmed clients. The project management triangle is a basic but sometimes forgotten framework for leaders struggling with multiple priorities and limited resources. Reminder...The triangle covers three constraints in any project: resources, time, and quality. In a project, one or two of these are fixed. That means that the other one or two have to be flexible.  Otherwise, the project is destined to run into problems. I am working with lots of clients who are forgetting that at least one of these three pieces of the triangle HAS TO flex. If quality and budget are fixed, then the project might have to take longer. If the deadline is fixed, then either quality expectations will have to be lowered or additional resources will have to be provided';
  const toggleTruncate = () => {
    setIsTruncated(!isTruncated);
  };
  const displayText = isTruncated ? `${textData.slice(0, maxLength)} ...` : `${textData} -`;

  const toggleDescJobDetails = (target: string) => {
    setJobDetailsOpen((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
  };
  const loginPage = () => {
    history('/xcelian/login');
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <div className="input-wrapper">
        <Input
          type="text"
          placeholder="Search for Jobs"
        />
        <Button type="button">
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        </Button>
      </div>
      <div className="pt-3">
        <Card className="card-joblist-lite my-1">
          <CardHeader className="form-card-header">
            <Row>
              <Col lg="1" md="3" xs="2">
                <div className="align-center pt-1">
                  {imageURL === ''
                    ? (
                      <img
                        alt="..."
                        height="40px"
                        width="40px"
                        src="/images/common/projects.png"
                      />
                    )
                    : (
                      <img
                        alt="..."
                        height="20px"
                        width="20px"
                        src={imageURL}
                      />
                    ) }
                </div>
              </Col>
              <Col lg="8" md="5" xs="6">
                <div className="pt-1">
                  <span className="dashboard-post-text">Internship Program</span>
                </div>
                <div>
                  <span className="dashboard-sub-post-text">Barclays, Pune Maharashtra</span>
                </div>
              </Col>
              <Col lg="3" md="4" xs="4">
                <div className="align-end">
                  <Button className="button-icon" onClick={loginPage}>
                    <span className="jobs-apply-text">Apply
                      <FontAwesomeIcon className="mx-1" icon={faShareFromSquare} />
                    </span>
                  </Button>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <div className="align-center mx-2">
              <Row>
                <Col xs="12">
                  <span className="dashboard-thread-text">{displayText}</span>
                  {isTruncated && (
                  <Button onClick={toggleTruncate} className="dashboard-button-more">
                    show more
                  </Button>
                  )}
                  {!isTruncated && (
                  <Button onClick={toggleTruncate} className="dashboard-button-less">
                    show less
                  </Button>
                  )}
                </Col>
                <Col xs="12">
                  <Row className="pt-2">
                    <Col lg="3" md="4" xs="6">
                      <div className="pt-2">
                        <Card className="chip-box">
                          10 Vacancies
                        </Card>
                      </div>
                    </Col>
                    <Col lg="3" md="4" xs="6">
                      <div className="pt-2">
                        <Card className="chip-box">
                          Internship
                        </Card>
                      </div>
                    </Col>
                    <Col lg="3" md="4" xs="6">
                      <div className="pt-2">
                        <Card className="chip-box">
                          Hybrid
                        </Card>
                      </div>
                    </Col>
                    <Col lg="3" md="4" xs="6">
                      <div className="align-end pt-3">
                        <FontAwesomeIcon id="fmlId" icon={faPersonDress} className="dasboard-icons mx-2" />
                        <FontAwesomeIcon id="diffAbled" icon={faWheelchair} className="dasboard-icons mx-2" />
                        <UncontrolledTooltip delay={0} target="diffAbled">
                          Only Diffrently Abled
                        </UncontrolledTooltip>
                        <UncontrolledTooltip delay={0} target="fmlId">
                          Only for female candidate
                        </UncontrolledTooltip>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </CardBody>
          <CardFooter className="form-card-footer">
            <Row>
              <Col lg="2" md="2" xs="4">
                <div className="align-start mx-1 pt-1">
                  <CardImg
                    alt="..."
                    className="logo-new-job"
                    src="/images/common/new.png"
                  />
                </div>
              </Col>
              <Col lg="6" xs="8">
                <div className="margin-buttons-jobs align-end pt-1">
                  <span className="input-checkbox-text-grey">
                    <FontAwesomeIcon className="text-lite-grey mx-1" icon={faUsersGear} />
                    100 Applicants
                  </span>
                </div>
              </Col>
              <Col lg="4" xs="7">
                <div className="align-end margin-buttons-jobs mr mb-1">
                  <Button className="campus-jobs-button" onClick={() => toggleDescJobDetails('jobDetails-0')}>
                    DETAILS
                  </Button>
                </div>
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </div>
      {/* <div ref={offcanvasRef}>
        <Offcanvas
          direction="end"
          isOpen={jobDetailsOpen['jobDetails-0']}
          toggle={() => toggleDescJobDetails('jobDetails-0')}
          innerRef={offcanvasRef}
        >
          <OffcanvasHeader toggle={() => toggleDescJobDetails('jobDetails-0')} className="margin-0 mx-1" />
          <OffcanvasBody>
            <strong>
              This is the Offcanvas body.
            </strong>
          </OffcanvasBody>
        </Offcanvas>
      </div> */}
      <div>
        <Offcanvas
          isOpen={jobDetailsOpen['jobDetails-0']}
          toggle={() => toggleDescJobDetails('jobDetails-0')}
        >
          <span className="text-lite">Job Details</span>
          <JobPublicDetails />
        </Offcanvas>
      </div>
    </div>
  );
};

export default JobList;
