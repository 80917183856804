import { getPublicJSON } from '../../../../utils';
import { getConfig } from '../../../../utils/config/config';
import { JobPositionByIdOptions, JobPositionByIdResponse } from './job-position-by-id.service.types';

const { CAMPUSXCEL_API_ROOT } = getConfig();

export const JobPositionByIdRequest = ({
  uTp, requestType, jobCd, jobId,
}: JobPositionByIdOptions): Promise<JobPositionByIdResponse> => {
  if (uTp === 'PUBLIC') {
    return getPublicJSON(`${CAMPUSXCEL_API_ROOT}?uTp=${uTp}&jobId=${jobId}&jobCd=${jobCd}`, requestType);
  }
  return getPublicJSON(`${CAMPUSXCEL_API_ROOT}?uTp=${uTp}`, requestType);
};
