import {
  // faAddressCard,
  // faArrowLeft,
  // faBoxesStacked,
  // faCalendar,
  faChevronCircleLeft,
  faChevronCircleRight,
  // faFile,
} from '@fortawesome/free-solid-svg-icons';
// faSuitcase
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SidebarHeader,
} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { CardImg } from 'reactstrap';
import {
  clearKeysFromLocalStorage,
  getUserProfileLocalStorage,
} from '../../../../utils/service/localstorage-service';
import './side-drawer.scss';
import { SideDrawerProps } from './side-drawer.type';

export const SideSuperAdminDrawer: React.FC<SideDrawerProps> = ({
  menuCollapse, menuIconClick, notifDesc, menuCloseIconClick,
  firstName, lastName, avatarSrc,
}) => {
  const location = useLocation();
  const userProfile = getUserProfileLocalStorage();
  const path = location.pathname.split('/');
  const [menuItems, setMenuItems] = useState({
    dashboard: !!path[2].match('dashboard'),
    profile: !!path[2].match('profile'),
    calendar: !!path[2].match('calendar'),
    onboarding: !!path[2].match('onboarding'),
    avatar: !!path[2].match('avatar'),
    passkey: !!path[2].match('passkey'),
    jobBoard: !!path[2].match('jobBoard'),
  });
  const [defaultState, setDefaultState] = useState(true);
  React.useEffect(() => {
    if (notifDesc === 'avatar') {
      setMenuItems({
        dashboard: false,
        profile: false,
        calendar: false,
        onboarding: false,
        jobBoard: false,
        avatar: false,
        passkey: false,
      });
    }
    if (notifDesc === 'passkey') {
      setMenuItems({
        dashboard: false,
        profile: false,
        calendar: false,
        onboarding: false,
        jobBoard: false,
        avatar: false,
        passkey: false,
      });
    }
  }, [notifDesc]);

  React.useEffect(() => {
    if (defaultState) {
      if (path[3] === '') {
        setMenuItems({
          dashboard: true,
          profile: false,
          calendar: false,
          onboarding: false,
          jobBoard: false,
          avatar: false,
          passkey: false,
        });
      }
      if (path[3] === 'dashboard') {
        setMenuItems({
          dashboard: true,
          profile: false,
          calendar: false,
          onboarding: false,
          jobBoard: false,
          avatar: false,
          passkey: false,
        });
      }
      if (path[3] === 'jobs') {
        setMenuItems({
          dashboard: false,
          profile: false,
          calendar: false,
          onboarding: false,
          jobBoard: true,
          avatar: false,
          passkey: false,
        });
      }
      if (path[3] === 'calendar') {
        setMenuItems({
          dashboard: false,
          profile: false,
          calendar: true,
          onboarding: false,
          jobBoard: false,
          avatar: false,
          passkey: false,
        });
      }
      if (path[3] === 'onboarding') {
        setMenuItems({
          dashboard: false,
          profile: false,
          calendar: false,
          onboarding: true,
          jobBoard: false,
          avatar: false,
          passkey: false,
        });
      }
      if (path[3] === 'profile') {
        setMenuItems({
          dashboard: false,
          profile: true,
          calendar: false,
          onboarding: false,
          jobBoard: false,
          avatar: false,
          passkey: false,
        });
      }
      setDefaultState(true);
    }
  }, [defaultState, path[3]]);

  const handleOnClickMenuItem = (item: string) => {
    if (item === 'dashboard') {
      menuCloseIconClick();
      setMenuItems({
        dashboard: true,
        profile: false,
        calendar: false,
        onboarding: false,
        jobBoard: false,
        avatar: false,
        passkey: false,
      });
      // menuIconClick();
    } else if (item === 'jobBoard') {
      menuCloseIconClick();
      setMenuItems({
        dashboard: false,
        profile: false,
        calendar: false,
        onboarding: false,
        jobBoard: true,
        avatar: false,
        passkey: false,
      });
    } else if (item === 'profile') {
      menuCloseIconClick();
      setMenuItems({
        dashboard: false,
        profile: true,
        calendar: false,
        onboarding: false,
        jobBoard: false,
        avatar: false,
        passkey: false,
      });
    } else if (item === 'calendar') {
      menuCloseIconClick();
      setMenuItems({
        dashboard: false,
        profile: false,
        calendar: true,
        onboarding: false,
        jobBoard: false,
        avatar: false,
        passkey: false,
      });
    } else if (item === 'onboarding') {
      menuCloseIconClick();
      setMenuItems({
        dashboard: false,
        profile: false,
        calendar: false,
        onboarding: true,
        jobBoard: false,
        avatar: false,
        passkey: false,
      });
    }
  };

  return (
    <div>
      <ProSidebar collapsed={!menuCollapse}>
        <SidebarHeader className="sadmin-pro-sidebar-header">
          <div className="align-center">
            {!menuCollapse
              ? (
                <div className="my-2">
                  <img
                    alt="..."
                    className="cx-avatar-user-small"
                    src={avatarSrc[0].url !== '' ? avatarSrc[0].url : '/images/common/avatar.png'}
                  />
                </div>
              ) : (
                <div className="pt-2">
                  <div className="align-center">
                    <img
                      alt="..."
                      className="cx-avatar-user-large img-fluid rounded-circle"
                      src={avatarSrc[0].url !== '' ? avatarSrc[0].url : '/images/common/avatar.png'}
                    />
                  </div>
                  <div className="align-center">
                    <h6 className="cx-text-avatar-designation">SUPER-ADMIN</h6>
                  </div>
                  <div className="align-center">
                    <h6 className="cx-text-avatar-name">{firstName}{' '}{lastName}</h6>
                  </div>
                  <div>
                    <h6 className="cx-text-avatar-designation">{userProfile && JSON.parse(userProfile).profileData.eId}</h6>
                  </div>
                </div>
              )}
          </div>
          <div className="cx-side-border" />
          {menuCollapse ? <FontAwesomeIcon className="closemenu-cx" onClick={menuIconClick} icon={faChevronCircleLeft} /> : <FontAwesomeIcon className="closemenu-cx" onClick={menuIconClick} icon={faChevronCircleRight} />}
        </SidebarHeader>
        <SidebarContent className="sadmin-pro-sidebar-content">
          <Menu iconShape="square">
            <MenuItem active={menuItems.dashboard} className="menu-item-cx" icon={<CardImg className="menu-img-cx" src="/images/icons/dashboard.png" alt="dash" />} onClick={() => handleOnClickMenuItem('dashboard')}>
              <Link to="dashboard"><span className="cx-link-txt">Dashboard</span></Link>
            </MenuItem>
            {/* <MenuItem active={menuItems.jobBoard} className="menu-item-cx" icon={<FontAwesomeIcon className="menu-icon-cx" icon={faSuitcase} />} onClick={() => handleOnClickMenuItem('jobBoard')}>
                    <Link to="jobs"><span className="cx-link-txt">Job Board</span></Link>
                  </MenuItem> */}
            <MenuItem active={menuItems.onboarding} className="menu-item-cx" icon={<CardImg className="menu-img-cx" src="/images/icons/onboard.png" alt="onboarding" />} onClick={() => handleOnClickMenuItem('onboarding')}>
              <Link to="onboarding"><span className="cx-link-txt">Onboarding</span></Link>
            </MenuItem>
            <MenuItem active={menuItems.calendar} className="menu-item-cx" icon={<CardImg className="menu-img-cx" src="/images/icons/schedule.png" alt="sche" />} onClick={() => handleOnClickMenuItem('calendar')}>
              <Link to="calendar"><span className="cx-link-txt">Calendar</span></Link>
            </MenuItem>
            <MenuItem active={menuItems.profile} className="menu-item-cx" icon={<CardImg className="menu-img-cx" src="/images/icons/my-profile.png" alt="prof" />} onClick={() => handleOnClickMenuItem('profile')}>
              <Link to="profile"><span className="cx-link-txt">My Profile</span></Link>
            </MenuItem>
          </Menu>
          <Menu iconShape="square" onClick={() => { clearKeysFromLocalStorage(); clearKeysFromLocalStorage(); window.location.href = '/sadmin/login'; }}>
            <MenuItem className="menu-item-cx pt-1" icon={<CardImg className="menu-img-cx" src="/images/icons/logout.png" alt="dash" />}>
              <Link to="dashboard"><span className="cx-link-txt">Logout</span></Link>
            </MenuItem>
          </Menu>
        </SidebarContent>
      </ProSidebar>
    </div>
  );
};

export default SideSuperAdminDrawer;
