import {
  faAngleLeft,
  faAngleRight,
  faArrowLeft,
  faFilter,
  faMagnifyingGlass,
  faPlus,
  faRefresh,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import {
  Row, Col, Button, Input,
  Card,
  Label,
  CardImg,
} from 'reactstrap';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { AddBatch } from './add-batch.component';
import { getBatchListReq } from '../../../../../store/campusXcel/actions';
import { errorAlert } from '../../../../../utils/alert';
import { UserType, APIHeader, ListCourseType } from '../../../../../utils/constants';
import { getTokenFromLocalStorage, getUserProfileLocalStorage } from '../../../../../utils/service/localstorage-service';
import { AllBatchResult, EvalKey } from '../../../../../services/campus/college-admin/cmps-batches/list-batch/list-batch.types';
import { AllBatchesList } from './list-batches';
import { BatchAddDataInput } from '../../../../../services/campus/college-admin/cmps-batches/add-batch/add-batch.types';
import Offcanvas from '../../../../../utils/OffCanvas/OffcanvasComponent';

export const BatchesDetails: React.FC = () => {
  const [isListBatch, setIsListBatch] = useState(true);
  const dispatch = useDispatch();
  const location = useLocation();
  const userProfile = getUserProfileLocalStorage();
  const tokenData = getTokenFromLocalStorage();
  const [loadingMore, setLoadingMore] = React.useState(false);
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [isClickClose, setIsClickClose] = useState(false);
  const [alertMsg, setAlertMsg] = React.useState('');
  const [cmpsId, setCmpsId] = React.useState('');
  const [cmpsNme, setCmpsNme] = React.useState('');
  const [searchText, setSearchText] = React.useState('');
  const [evalKeyText, setEvalKeyText] = React.useState('');
  const [evalTpText, setEvalTpText] = React.useState('');
  const [isSearchQuery, setIsSearchQuery] = React.useState('');
  const [recordsPerPage] = React.useState<number>(10);
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [totalRecords, setTotalRecords] = React.useState<number>(0);
  const [pagedData, setPagedData] = React.useState<Record<number, any[]>>({});
  const [currentBatchPage, setCurrentBatchPage] = React.useState(0);
  const itemsBatchPerPage = 6; // Number of items per page
  const totalBatchPages = Math.ceil(ListCourseType.length / itemsBatchPerPage);
  const paginatedIndusItems = ListCourseType.slice(currentBatchPage * itemsBatchPerPage, (currentBatchPage + 1) * itemsBatchPerPage);
  // const [resetBatchData, setResetBatchData] = React.useState(false);
  const batchDataResponse = useSelector((state: RootState) => state.campusXcel.getBatchList);
  const path = location.pathname.split('/');
  // const [menuItems, setMenuItems] = useState({
  //   batches: !!path[2].match('batches'),
  // });
  const [, setDeleteRecord] = React.useState<EvalKey>({
    AcadCd: 'string',
    BatchCd: '',
  });
  const [, setEditBatchRecord] = React.useState<BatchAddDataInput>({
    // AcadCd: '', Need to update the Edit queue
    // BatchCd: '',
    cmpsCd: '',
    cmpsNme: '',
    bNme: '',
    sDte: '',
    eDte: '',
    strm: '',
    crs: '',
  });

  const [, setBatchListData] = React.useState<AllBatchResult>({
    tCnt: 0,
    fCnt: 0,
    batchData: [],
    lastEvalKey: {
      AcadCd: '',
      BatchCd: '',
    },
  });

  React.useEffect(() => {
    if (userProfile && JSON.parse(userProfile).profileData.cmpsCd !== '') {
      setCmpsId(userProfile && JSON.parse(userProfile).profileData.cmpsCd);
      setCmpsNme(userProfile && JSON.parse(userProfile).profileData.cmpsNme);
    }
  }, [userProfile && JSON.parse(userProfile).profileData.cmpsCd]);

  React.useEffect(() => {
    // setResetBatchData((prevState) => !prevState);
    if (path[3].match('batches') && cmpsId !== '') {
      setLoadingMore(true);
      dispatch(getBatchListReq({
        requestType: APIHeader.REQ_GET_ALL_ACAD_BATCH,
        uTp: UserType.CMPS,
        limit: recordsPerPage,
        evalKey: '',
        evalTp: '',
        isFilter: 'NO',
        isSearch: 'NO',
        token: tokenData,
        cmpsCd: cmpsId,
      }));
    }
  }, [!!path[3].match('batches'), cmpsId]);

  const fetchMoreData = () => {
    setLoadingMore(true);
    if (isSearchQuery !== '') {
      dispatch(getBatchListReq({
        requestType: APIHeader.REQ_GET_ALL_ACAD_BATCH,
        uTp: UserType.CMPS,
        limit: recordsPerPage,
        evalKey: evalKeyText,
        evalTp: evalTpText,
        isFilter: 'NO',
        searchBy: searchText,
        isSearch: 'YES',
        token: tokenData,
        cmpsCd: cmpsId,
      }));
    } else {
      setLoadingMore(true);
      dispatch(
        getBatchListReq({
          requestType: APIHeader.REQ_GET_ALL_ACAD_BATCH,
          uTp: UserType.CMPS,
          evalKey: evalKeyText,
          evalTp: evalTpText,
          isFilter: 'NO',
          isSearch: 'NO',
          token: tokenData,
          cmpsCd: cmpsId,
        }),
      );
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    if (!pagedData[page]) {
      fetchMoreData();
    }
  };

  const handleSearchChange = (e:any) => {
    setSearchText(e.target.value);
  };
  const handleRefreshClient = () => {
    setCurrentPage(0);
    setTotalRecords(0);
    setPagedData([]);
    setLoadingMore(true);
    dispatch(getBatchListReq({
      requestType: APIHeader.REQ_GET_ALL_ACAD_BATCH,
      uTp: UserType.CMPS,
      limit: recordsPerPage,
      evalKey: '',
      evalTp: '',
      isFilter: 'NO',
      isSearch: 'NO',
      token: tokenData,
      cmpsCd: cmpsId,
    }));
  };

  const handleSearchProjects = () => {
    // setLoaderClient(true);
    setCurrentPage(0);
    setTotalRecords(0);
    setPagedData([]);
    setLoadingMore(true);
    setIsSearchQuery(searchText);
    dispatch(getBatchListReq({
      requestType: APIHeader.REQ_GET_ALL_ACAD_BATCH,
      uTp: UserType.CMPS,
      limit: recordsPerPage,
      evalKey: '',
      evalTp: '',
      isFilter: 'NO',
      searchBy: searchText,
      isSearch: 'YES',
      token: tokenData,
      cmpsCd: cmpsId,
    }));
  };

  React.useEffect(() => {
    if (batchDataResponse.isError && batchDataResponse.message !== '') {
      // setLoaderClient(false);
      setLoadingMore(false);
      setAlertMsg(batchDataResponse.message);
      setErrorShowAlert(true);
    }
  }, [batchDataResponse.isError, batchDataResponse.message]);

  React.useEffect(() => {
    if (!batchDataResponse.isError && batchDataResponse.message === 'executed') {
      setLoadingMore(false);
      if (searchText !== '') {
        setPagedData({ [currentPage]: batchDataResponse.data.batchData });
      } else if (
        batchDataResponse.data
        && Object.keys(batchDataResponse.data).length > 0
        && Array.isArray(batchDataResponse.data.batchData)
      ) {
        const newPageData = batchDataResponse.data.batchData;
        setPagedData((prev) => ({
          ...prev,
          [currentPage]: newPageData,
        }));
        setBatchListData({
          tCnt: batchDataResponse.data.tCnt,
          fCnt: batchDataResponse.data.fCnt,
          batchData: batchDataResponse.data.batchData,
          lastEvalKey: batchDataResponse.data.lastEvalKey,
        });
        if (batchDataResponse.data.lastEvalKey !== null) {
          setEvalKeyText(batchDataResponse.data.lastEvalKey.BatchCd);
          setEvalTpText(batchDataResponse.data.lastEvalKey.AcadCd);
        }
        setTotalRecords(batchDataResponse.data.tCnt);
      }
    }
  }, [batchDataResponse.isError, batchDataResponse.message, batchDataResponse.data.batchData]);

  const handleBatchNext = () => {
    if (currentBatchPage < totalBatchPages - 1) {
      setCurrentBatchPage(currentBatchPage + 1);
    }
  };

  const handleBatchPrevious = () => {
    if (currentBatchPage > 0) {
      setCurrentBatchPage(currentBatchPage - 1);
    }
  };

  const toggleFilter = () => {
    setIsClickClose(!isClickClose);
  };

  return (
    <div className="ml margin-mob-top">
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <div className="allign-start ml">
        <h2 className="text-line"><CardImg className="cx-main-header-img-card" src="/images/icons/batches.png" alt="batches" /> Manage Batches</h2>
      </div>
      <div>
        <Row className="align-center pt-4">
          <Col xs="12" lg="12">
            <Row>
              <Col lg="3" xs="12">
                <div className="ml">
                  {isListBatch
                    ? (
                      <div className="align-start">
                        <Button className="button-icon" onClick={toggleFilter}>
                          <FontAwesomeIcon icon={faFilter} className="mr" />
                          Filter
                        </Button>
                        <Offcanvas
                          isOpen={isClickClose}
                          toggle={() => setIsClickClose(!isClickClose)}
                        >
                          <div className="ml-1">
                            All Filter
                          </div>
                          <Card className="card-jobs-lite">
                            <div className="pt-1">
                              <div className="ml-1">
                                <span className="xcelian-filter-sub-header-text">Campus Stream</span>
                              </div>
                              <Row className="pt-3 ml">
                                {paginatedIndusItems.map((item, index) => (
                                  <Col xs="12" lg="12" className="checkbox-label ml" key={`${index.toString()}`}>
                                    <Input type="checkbox" id={`option${index}`} className="campus-check-box" />
                                    <Label className="jobs-type-text" htmlFor={`option${index}`}>{item.label}</Label>
                                  </Col>
                                ))}
                                <div className="pagination-buttons-filter-jobs align-end mr-1 my-1">
                                  <Button className="btn-filter-jobs" onClick={handleBatchPrevious} disabled={currentBatchPage === 0}>
                                    <FontAwesomeIcon className="text-subheader-white mx-2" icon={faAngleLeft} />
                                  </Button>
                                  <Button className="btn-filter-jobs ml" onClick={handleBatchNext} disabled={currentBatchPage === totalBatchPages - 1}>
                                    <FontAwesomeIcon className="text-subheader-white mx-2" icon={faAngleRight} />
                                  </Button>
                                </div>
                              </Row>
                            </div>
                          </Card>
                        </Offcanvas>
                      </div>
                    ) : null}
                </div>
              </Col>
              <Col lg="6" xs="12">
                <div className="ml">
                  {isListBatch
                    ? (
                      <div className="align-center">
                        <div className="input-wrapper">
                          <Input
                            type="text"
                            placeholder="Search..."
                            value={searchText}
                            onChange={handleSearchChange}
                          />
                          <Button type="button" onClick={handleSearchProjects}>
                            <FontAwesomeIcon icon={faMagnifyingGlass} />
                          </Button>
                        </div>
                        <Button className="onboard-refresh-button" onClick={handleRefreshClient}>
                          <FontAwesomeIcon icon={faRefresh} />
                        </Button>
                      </div>
                    ) : null}
                </div>
              </Col>
              <Col lg="3" xs="12">
                <div className="align-end mr-1">
                  {!isListBatch
                    ? (
                      <Button className="button-icon" id="listProjId" onClick={() => setIsListBatch(true)}>
                        <FontAwesomeIcon className="quest-menu-icon mx-2" icon={faArrowLeft} />
                        Go Back
                      </Button>
                    ) : (
                      <Button className="button-icon" id="addNewId" onClick={() => setIsListBatch(false)}>
                        Add New <FontAwesomeIcon icon={faPlus} className="text-lite-grey" />
                      </Button>
                    )}
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs="12" lg="12">
            {!isListBatch
              ? (
                <div className="pt-1">
                  <AddBatch campusId={cmpsId} cmpusNme={cmpsNme} />
                </div>
              )
              : (
                <div className="pt-1">
                  {loadingMore ? (
                    <div className="loader-container-none align-center pt-5">
                      <img src="/images/spinner.png" alt="Loading" className="spinner-img loader-image-size" />
                      <span className="mx-2 text-lite">Data is loading... <br />Please wait....</span>
                    </div>
                  ) : (
                    <div className="mx-2">
                      {!!path[3].match('batches') && pagedData[currentPage]
                        ? (
                          <AllBatchesList
                            pagedData={pagedData[currentPage]}
                            currentPage={currentPage}
                            handlePageChange={handlePageChange}
                            recordsPerPage={recordsPerPage}
                            totalRecords={totalRecords}
                            setEditRecord={setEditBatchRecord}
                            setDeleteRecord={setDeleteRecord}
                          />
                        ) : null}
                    </div>
                  )}
                </div>
              )}
          </Col>
        </Row>
      </div>
      <div className="pt-5" />
    </div>
  );
};
