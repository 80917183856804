import React, { useState } from 'react';
import { RootState } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Select from 'react-select';
import {
  Button, Card, CardBody, CardFooter, CardHeader, Col, Form, FormGroup, Input, Label, Row,
} from 'reactstrap';
import { BatchAddDataInput } from '../../../../../services/campus/college-admin/cmps-batches/add-batch/add-batch.types';
import {
  batchReq, clearBatchReq, updateS3CxMetaReq, clearUpdateS3CxMetaReq,
  getS3CxMetaReq,
} from '../../../../../store/campusXcel/actions';
import { successAlert, errorAlert } from '../../../../../utils/alert';
import {
  UserType, APIHeader, ListCourseType,
} from '../../../../../utils/constants';
import LoaderData from '../../../../../utils/loader';
import { getTokenFromLocalStorage } from '../../../../../utils/service/localstorage-service';
import { MetaInput } from '../../../../../services/aIntelligence/update-cx-meta/update-s3-cx-meta.types';
import { getConfig } from '../../../../../utils/config/config';
import { AddBatchDetProps } from './batch.type';
import { MetaRecord } from '../../../../../services/aIntelligence/get-all-cx-meta/get-s3-cx-meta.types';

const { AWS_CXADMIN_BUCKET } = getConfig();

export const AddBatch: React.FC<AddBatchDetProps> = ({ campusId, cmpusNme }) => {
  const dispatch = useDispatch();
  const tokenData = getTokenFromLocalStorage();
  const [loaderStrm, setLoaderStrm] = useState(false);
  const [loaderBatch, setLoaderBatch] = useState(false);
  const [showSuccessAlert, setSuccessShowAlert] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [isOStrm, setisOStrm] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [strmListData, setStrmListData] = useState<MetaRecord[]>([]);
  const getStreamResponse = useSelector((state: RootState) => state.campusXcel.getS3CxMeta);
  const addCampusStreamResponse = useSelector((state: RootState) => state.campusXcel.updateS3CxMeta);
  const batchResponse = useSelector((state: RootState) => state.campusXcel.updateBatch);
  const [newStream, setNewStream] = useState<MetaInput>({
    nme: '',
    cmpsId: campusId,
  });
  const [batch, setBatch] = useState<BatchAddDataInput>({
    cmpsCd: campusId,
    cmpsNme: cmpusNme,
    bNme: '',
    sDte: '',
    eDte: '',
    strm: '',
    crs: '',
  });

  React.useEffect(() => {
    setLoaderStrm(true);
    dispatch(getS3CxMetaReq({
      bucketName: AWS_CXADMIN_BUCKET,
      fileName: 'campus-stream.json',
      folderName: 'cx-meta-data',
      search: '',
      maxResults: 30,
      nextToken: '',
      requestType: '',
    }));
  }, []);

  React.useEffect(() => {
    if (getStreamResponse.success && getStreamResponse.data.length > 0) {
      setLoaderStrm(false);
      setStrmListData((prevData) => {
        const existingNames = new Set(prevData.map((record) => record.nme));
        const uniqueNewData = getStreamResponse.data.filter((record) => !existingNames.has(record.nme));
        return [...prevData, ...uniqueNewData];
      });
    }
  }, [getStreamResponse.success, getStreamResponse.data]);

  const handleChangeNewStream = (event: any) => {
    setNewStream({ ...newStream, [event.target.name]: event.target.value });
  };

  const handleUpdateStreamMetaData = (e: any) => {
    e.preventDefault();
    setLoaderStrm(true);
    dispatch(updateS3CxMetaReq({
      bucketNme: AWS_CXADMIN_BUCKET,
      fileName: 'campus-stream.json',
      folderName: 'cx-meta-data',
      inputBody: newStream,
      requestType: '',
    }));
  };
  React.useEffect(() => {
    if (addCampusStreamResponse.message !== '') {
      setLoaderStrm(false);
      if (addCampusStreamResponse.success) {
        setAlertMsg(addCampusStreamResponse.message);
        setSuccessShowAlert(true);
        setisOStrm(false);
        setNewStream({
          nme: '',
          cmpsId: campusId,
        });
      } else {
        setAlertMsg(addCampusStreamResponse.message);
        setErrorShowAlert(true);
      }
      dispatch(clearUpdateS3CxMetaReq());
    }
  }, [addCampusStreamResponse.message]);

  const handleBatchData = (e:any) => {
    setBatch({ ...batch, [e.target.name]: e.target.value });
  };
  const handleDateBatch = (e:any) => {
    const dateData = moment(e.target.value).format('YYYY-MM-DDThh:mm:ss');
    setBatch({
      ...batch,
      [e.target.name]: dateData,
    });
  };
  const handleSelectStrmData = (e:any) => {
    setBatch({
      ...batch, cmpsCd: e.value, strm: e.label,
    });
  };
  const strmNameoptions = strmListData.map((strm) => ({
    label: strm.nme,
    value: strm.cmpsId,
  }));
  const selectedStrmNameOptions = strmNameoptions.find(
    (option) => option.value === batch.strm,
  );
  const handleInputStrmData = (e:any) => {
    if (e.length > 2) {
      setLoaderStrm(true);
      dispatch(getS3CxMetaReq({
        bucketName: AWS_CXADMIN_BUCKET,
        fileName: 'campus-stream.json',
        folderName: 'cx-meta-data',
        search: e,
        maxResults: 20,
        nextToken: '',
        requestType: '',
      }));
    }
  };
  const courseTpoptions = ListCourseType.map((course) => ({
    label: course.label,
    value: course.value,
  }));

  const selectedCourseTpOptions = courseTpoptions.find(
    (option) => option.value === batch.crs,
  );

  const handleSelectCourseTpData = (e:any) => {
    if (e) {
      setBatch({ ...batch, crs: e.value });
    } else {
      setBatch({ ...batch, crs: '' });
    }
  };
  const handleSubmitBatch = (e: any) => {
    e.preventDefault();
    if (batch.crs === '' || (batch.strm === '')) {
      setAlertMsg('Either Course or Stream is Empty');
      setErrorShowAlert(true);
    } else {
      setLoaderBatch(true);
      dispatch(batchReq({
        inputBody: batch,
        userReqFor: UserType.CMPS,
        requestType: APIHeader.REQ_ADD_BATCH,
        uTp: UserType.CMPS,
        token: tokenData,
      }));
    }
  };

  React.useEffect(() => {
    if (batchResponse.error && batchResponse.message !== '') {
      setLoaderBatch(false);
      setAlertMsg(batchResponse.message);
      setErrorShowAlert(true);
    }
  }, [batchResponse.error, batchResponse.message]);

  React.useEffect(() => {
    if (!batchResponse.error && batchResponse.message === 'executed') {
      setLoaderBatch(false);
      Promise.resolve(setAlertMsg(batchResponse.data))
        .then(() => {
          setBatch({
            cmpsCd: '',
            cmpsNme: '',
            bNme: '',
            sDte: '',
            eDte: '',
            strm: '',
            crs: '',
          });
          setSuccessShowAlert(true);
        }).then(() => {
          dispatch(clearBatchReq());
        });
    }
  }, [batchResponse.error, batchResponse.message]);

  const handleOStreamSwitch = () => {
    setisOStrm(!isOStrm);
    setBatch({
      ...batch, strm: '',
    });
  };
  return (
    <Row className="align-center">
      { loaderBatch ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(false, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <Col lg="8" xs="12">
        <Form onSubmit={handleSubmitBatch}>
          <Card className="cx-contact-card">
            <CardHeader className="form-card-header">
              <div className="my-3 align-center">
                Create New Batch
              </div>
            </CardHeader>
            <CardBody>
              <Row>
                <Col lg="12" md="10" xs="12">
                  <div className="text-right-login-campus-register pt-3 mx-1">
                    <h6 className="text-lite ml-1 align-start">Batch Name<span className="mandate-star">*</span></h6>
                    <Input
                      placeholder="Enter Batch Name"
                      type="text"
                      name="bNme"
                      required
                      value={batch.bNme}
                      onChange={handleBatchData}
                      className="campus-input"
                    />
                  </div>
                </Col>
                <Col lg="6" md="10" xs="12">
                  <div className="text-right-login-campus-register pt-5 mx-1">
                    <h6 className="text-lite ml-1 align-start">Start Date<span className="mandate-star">*</span></h6>
                    <Input
                      type="date"
                      name="sDte"
                      required
                      value={batch.sDte.toString().split('T')[0]}
                      // max={new Date().toISOString().split('T')[0]}
                      onChange={handleDateBatch}
                      className="campus-input"
                    />
                  </div>
                </Col>
                <Col lg="6" md="10" xs="12">
                  <div className="text-right-login-campus-register pt-5 mx-1">
                    <h6 className="text-lite ml-1 align-start">End Date<span className="mandate-star">*</span></h6>
                    <Input
                      type="date"
                      name="eDte"
                      required
                      value={batch.eDte.toString().split('T')[0]}
                      // max={new Date().toISOString().split('T')[0]}
                      onChange={handleDateBatch}
                      className="campus-input"
                    />
                  </div>
                </Col>
                <Col xs="12">
                  <div className="text-right-login-campus-register pt-5 mx-1">
                    <h6 className="text-lite ml-1 align-start">Stream Name<span className="mandate-star">*</span></h6>
                    <Select
                      value={batch.strm === '' ? null : selectedStrmNameOptions}
                      options={strmNameoptions}
                      isDisabled={isOStrm}
                      onInputChange={handleInputStrmData}
                      onChange={handleSelectStrmData}
                      styles={{
                        control: (base: any) => ({
                          ...base,
                          '&:hover': { borderColor: '#8B0000' },
                          border: '1px solid #DDE0E4',
                          minHeight: '50px',
                          borderRadius: '50px',
                          padding: 1,
                          textAlign: 'left',
                          fontSize: '15px',
                          fontWeight: 400,
                          color: '#575656',
                          boxShadow: 'none',
                          backgroundColor: '#fff',
                          '&:focus': {
                            borderColor: '#E1EDF8',
                            boxShadow: 'none',
                            color: '#575656',
                            background: '#fff',
                          },
                        }),
                        option: (provided: any, state: { isSelected: any; }) => ({
                          ...provided,
                          color: state.isSelected ? '#383838' : '#212121',
                          padding: 15,
                          textAlign: 'left',
                          background: state.isSelected ? '#FAFCFB' : '#fff',
                          '&:hover': {
                            background: '#FAFCFB',
                          },
                        }),
                      }}
                      noOptionsMessage={() => 'No Stream Found'}
                      isLoading={loaderStrm}
                      placeholder="Enter Stream Name"
                    />
                  </div>
                  <div className="pt-2 ml-2 align-start">
                    <FormGroup>
                      <Input
                        type="checkbox"
                        role="switch"
                        name="isCrnt"
                        className="campus-check-box cursor-pointer"
                        // disabled={batch.strm !== ''}
                        checked={isOStrm}
                        onChange={handleOStreamSwitch}
                      />
                      <Label check className="text-lite">Unable to find one. Click to add new </Label>
                    </FormGroup>
                  </div>
                  {isOStrm
                    ? (
                      <div className="bg-o-stream mx-1">
                        <h6 className="text-lite ml-1 pt-2">Add New Stream Name</h6>
                        <div className="text-right-login-campus-register pt-3 mx-3">
                          <Input
                            placeholder="Enter Stream Name"
                            type="text"
                            name="nme"
                            required
                            value={newStream.nme}
                            onChange={handleChangeNewStream}
                            className="campus-input"
                          />
                        </div>
                        <div className="align-center pt-3 my-2">
                          <Button className="campus-button-lite mx-1" onClick={handleUpdateStreamMetaData}>
                            SAVE
                          </Button>
                        </div>
                      </div>
                    ) : null }
                </Col>
                <Col xs="12">
                  <div className="text-right-login-campus-register pt-4 mx-1">
                    <h6 className="text-lite ml-1 align-start">Course Type<span className="mandate-star">*</span></h6>
                    <Select
                      value={batch.crs === '' ? null : selectedCourseTpOptions}
                      options={courseTpoptions}
                      isClearable
                      onChange={handleSelectCourseTpData}
                      styles={{
                        menu: (base) => ({
                          ...base,
                          position: 'relative',
                        }),
                        control: (base: any) => ({
                          ...base,
                          '&:hover': { borderColor: '#F6B26B' },
                          border: '1px solid #DDE0E4',
                          minHeight: '50px',
                          borderRadius: '50px',
                          padding: 1,
                          fontSize: '15px',
                          fontWeight: 400,
                          color: '#575656',
                          boxShadow: 'none',
                          textAlign: 'left',
                          backgroundColor: '#fff',
                          '&:focus': {
                            borderColor: '#E1EDF8',
                            boxShadow: 'none',
                            color: '#575656',
                            background: '#fff',
                          },
                        }),
                        option: (provided: any, state: { isSelected: any; }) => ({
                          ...provided,
                          color: state.isSelected ? '#383838' : '#212121',
                          padding: 15,
                          textAlign: 'left',
                          background: state.isSelected ? '#FAFCFB' : '#fff',
                          '&:hover': {
                            background: '#FAFCFB',
                          },
                        }),
                      }}
                      noOptionsMessage={() => 'No Course Type Found'}
                      placeholder="Enter Course Type"
                    />
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter className="form-card-footer">
              <div className="align-center my-2 pt-2">
                <Button className="campus-button mx-4">
                  Save
                </Button>
              </div>
            </CardFooter>
          </Card>
        </Form>
      </Col>
    </Row>
  );
};

export default AddBatch;
